export default {
  en: 'English',
  zh: '中文',
  home: {
    NewPortal: 'New Portal',
    SecureIBPortal: 'Secure IB Portal',
    DASHBOARD: 'DASHBOARD',
    MARKETING_TOOLS: 'MARKETING TOOLS',
    IB_REPORT: 'IB REPORT',
    REBATE_REPORT: 'REBATE REPORT',
    IB_ACCOUNTS: 'IB ACCOUNTS',
    PAYMENT_HISTORY: 'TRANSACTION HISTORY',
    IB_FAQ: 'IB FAQ',
    RebateAccountNumber: 'Rebate account number',
    totalBalance: 'total balance',
    totalCommission: 'total commission',
    APPLY_FOR_A_REBATE: 'APPLY',
    availableBalance: 'available balance',
    WITHDRAW_REBATE: 'WITHDRAW',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFER',
    MonthtoDate: 'Month to Date',
    UPDATE: 'UPDATE',
    REBATE: 'REBATE',
    TOTAL_VOLUME: 'TOTAL VOLUME',
    NET_FUNDING: 'NET FUNDING',
    DEPOSITS: 'DEPOSITS',
    WITHDRAW: 'WITHDRAW',
    OPEND_ACCOUNT: 'OPENED ACCOUNT',
    PERFORMANCE: 'PERFORMANCE',
    OpendAccount: 'Opened Account',
    select_ib: 'Please select IB account',
    MOST_TRADED_INSTRUMENTS: 'MOST TRADED INSTRUMENTS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'TOP PERFORMING ACCOUNTS FOR THIS MONTH',
    RECENTLY_OPENED_ACCOUNTS: 'RECENTLY OPENED ACCOUNTS',
    ACCOUNT_MANAGER: 'CUSTOMER SUPPORT',
    Account: 'Account',
    Name: 'Name',
    Volume: 'Volume',
    Rebate: 'Rebate',
    Balance: 'Balance',
    ContactClient: 'Contact Client',
    YouCannotAskForCommission: "You can't ask for a commission",
    applicationFailedPleaseTryAgain: 'The application failed, please try again later',
    applyRebateSuccessTip:
      'Congratulations, we have received your rebate request. | {amount} will be transferred to the rebate Account {account} shortly. | Once processed, you will be able to withdraw or transfer your funds.',
    HOVER: 'The previous day’s rebates are calculated and processed between 09:00 - 11:00 AEST daily.',
  },
  liveChat: {
    header: 'Live Chat',
  },
  contactUs: {
    click: 'Click',
    assist: ' for assistance now',
    header: 'CONTACT US',
    followUs: 'FOLLOW US',
    tip: `{platform}'s professional multilingual customer support team is ready to assist you and help to provide you an incomparable trading experience.`,
  },
  ibAccounts: {
    header: 'IB ACCOUNTS',
    ibAccount: 'IB Account',
    subIbs: 'Sub IB Account',
    tradinglist: 'Trading List',
    openlist: 'Open List',
    closedlist: 'Closed List',
    tradinglistColumn: {
      ticketid: 'TicketID',
      opentime: 'Open Time',
      type: 'Type',
      volume: 'Volume',
      item: 'Item',
      openprice: 'Open Price',
      closeprice: 'Close Price',
      closetime: 'Close Time',
      commission: 'Commission',
      profit: 'Profit/Loss',
      buy: 'Buy',
      sell: 'Sell',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Select Credit Card',
      selectBA: 'Select Bank Account',
      anotherCC: 'Enter credit card manually',
      anotherBA: 'Add bank account',
      remember: 'Remember my account',
      verificationCode:
        'Verification code has been sent to {email}. Please contact {supportEmail} if you need further assistance.',
    },
    alert:
      'Due to AML laws, the method you use to withdraw must be the same as the method used for your deposit. If the withdrawal amount is less than 100 units of your account base currency, you will be charged a 20 unit for handling fee by the bank.',
    NetellerChargeAlert:
      'Neteller withdrawals are limited to $10,000 per transaction, and a 2% fee (capped at $30) applies.',
    FasaPayChargeAlert: 'FasaPay charges 0.5% transaction fee for every withdrawal.',
    SkrillChargeAlert: 'Skrill charges 1% transaction fee for every withdrawal.',
    cryptoAlert:
      'Please note that we are unable to deposit or withdraw via BUSDT, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund.',
    usdtNote:
      'The current gas fee of ERC20 is significantly higher than usual and as a result there may be a lengthy delay in the processing of your transaction. Alternatively, you are able to withdraw funds via TRC20 by providing the coresponding wallet address, or you can elect to pay the gas fee to speed up your ERC20 withdrawal.',
    REBATE_WITHDRAW_REQUEST: 'REBATE WITHDRAW REQUEST',
    PleaseSelectDesiredWithdrawMethod: 'Please select desired withdraw method',
    BankName: 'Bank Name',
    BankAccountsName: 'Bank Accounts Name',
    FasaPayAccountName: 'FasaPay Account Name',
    AustralianBankName: 'Australian Bank Name',
    BankAccountNumber: 'Bank Account Number',
    FasaPayAccountNumber: 'FasaPay Account Number',
    BankAccountBeneficiary: 'Bank Account Beneficiary',
    BSB: 'BSB',
    skrillEmail: 'Skrill Email',
    SticpayEmail: 'SticPay Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    PerfectMoneyAccount: 'Perfect Money Account',
    BitwalletEmail: 'Bitwallet Email',
    BitcoinEmail: 'Bitcoin Wallet Address',
    USDTEmail: 'Tether Wallet Address',
    Chain: 'Chain',
    BankBranch: 'Bank Branch',
    Province: 'Province',
    City: 'City',
    BankAddress: 'Bank Address',
    bankCity: 'Bank City',
    bankProvince: 'Bank Province',
    AccountNumberIBAN: 'Account Number/IBAN',
    BankBeneficiaryName: 'Bank Beneficiary Name',
    BankAccountBeneficiaryName: 'Bank Account Beneficiary Name',
    AccountHoldersAddress: "Account Holder's Address",
    SelectWithdrawCard: 'Select Your Withdraw Card',
    Swift: 'Swift',
    ifscCode: 'IFSC Code',
    SwiftAud: 'Swift(Non AUD Accounts)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: 'Important Notes',
    YourPaymentGreaterThanAvailableBalance: 'Your payment is greater than the available balance',
    CannotUseMoreThanTwoDecimalValues: 'You cannot use Numbers and negative Numbers with more than two decimal places',
    YourPaymentIsBeingProcessed: 'Your withdraw application has been submitted',
    Itcannotbe0: "It can't be 0",
    noCardWarn: 'You don’t have any available card to make withdrawal, please link your card in Withdraw Details first',
    amtLarger: 'Amount cannot be null and must be greater than or equal to {minLimit} {currency}',
    blackList: `Unfortunately you are currently unable to submit a withdrawal request. Please contact {supportEmail} for more information`,
    status: {
      withdrawalSubmitted: 'Submitted',
      withdrawalCanceled: 'Cancelled',
      withdrawalAuditing: 'Processing',
      withdrawalRejected: 'Rejected',
      withdrawalAccepted: 'Processing',
      withdrawalPayFailed: 'Failed',
      withdrawalSuccess: 'Processed',
      withdrawalFailed: 'Failed',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Failed',
      transferSubmitted: 'Processing',
      transferSuccess: 'Processed',
      transferRejected: 'Rejected',
      transferFailed: 'Failed',
      transferProcessing: 'Processing',
    },
    formErrorMsg: {
      bankName: 'Bank Name is required',
      bsb: 'BSB is required',
      beneName: 'Bank Beneficiary Name is required',
      accNum: 'Bank Account Number is required',
      swift: 'Swift is required',
      bankAddress: 'Bank address is required',
      holderAddress: 'Holder address is required',
      amt: 'Amount is required',
      firstSixDigits: 'Please Enter First 6 digits',
      lastFourDigits: 'Please Enter Last 4 digits',
      branchName: 'Branch name is required',
      email: 'Email is required',
      accName: 'Account name is required',
      province: 'Bank province is required',
      city: 'Bank City is required',
      bankCard: 'Please select a card',
      emailFormat: 'Please input correct email address',
      amtCAD: 'Min ${minLimit} Max $9999.99 {currency}',
      amt0: 'Amount cannot be 0',
      ifscCode: 'IFSC Code is required',
      bitcoin: 'Bitcoin Wallet Address is required',
      bitcoinFormat: 'Incorrect Bitcoin wallet address format',
      addressLength: 'Bitcoin wallet address must be between 26 and 42 characters',
      usdt: 'USDT Wallet Address is required',
      walletFormatIncorrect: 'Incorrect {type} wallet address format',
      chain: 'Chain is required',
    },
    transfer: {
      info:
        '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      label: {
        upload: 'Please upload a copy of your bank statement (current to the last 3 months)',
      },
      formValidation: {
        upload: 'Please upload a bank statement',
      },
    },
    transitNumber: 'Transit Number',
    institutionNumber: 'Institution Number',
  },
  rebate: {
    status: {
      processed: 'Processed',
      processing: 'Processing',
      rejected: 'Rejected',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'REBATE TRANSFER REQUEST',
    transferAmountIsGreaterThanAvailableBalance: 'Your transfer amount is greater than the available balance',
    yourRebateTransferHasBeenProcessed: 'Your rebate transfer has been submitted',
    PleaseEnterTransferInformation: 'Please enter accurate transfer information',
    tradingAccount: 'Trading Account',
    rebateAccount: 'Rebate Account',
    account: 'Account',
    blackList:
      'This account has been restricted from making a transfer between accounts, please contact {supportEmail}',
  },
  common: {
    liveChat: { desc: 'Need Help ? Live Chat' },
    lang: {
      english: 'English',
      chinese: 'Chinese',
      french: 'French',
      thai: 'Thai',
      german: 'German',
      spanish: 'Spanish',
      malay: 'Malay',
      viet: 'Vietnamese',
      indo: 'Indonesian',
      arabic: 'Arabic',
    },
    button: {
      confirm: 'CONFIRM',
      cancel: 'CANCEL',
      sendCode: 'Send Code',
      resendCode: 'Resend Code',
    },
    withdrawChannel: {
      banktransfer: 'Bank Transfer',
      aus: 'Bank Transfer - Australia',
      int: 'Bank Transfer - International',
      chn: 'Bank Transfer - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'FasaPay',
      creditcard: 'Credit Card',
      bpay: 'Bpay',
      creditdebitcard: 'Credit/Debit Card',
      bankwiretransferinternational: 'Bank Wire Transfer (International)',
      banktransferaustralia: 'Bank Transfer (Australia)',
      banktransferinternational: 'Bank Transfer (International)',
      banktransferchina: 'Bank Transfer (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Broker to Broker Transfer',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Internet Banking (Thailand)',
      internetbankingnigeria: 'Internet Banking (Nigeria)',
      internetbankingvietnam: 'Internet Banking (Vietnam)',
      internetbankingmalaysia: 'Internet Banking (Malaysia)',
      internetbankingindonesia: 'Internet Banking (Indonesia)',
      internetbankingkorea: 'Internet Banking (Korea)',
      internetbankingindia: 'Internet Banking (India)',
      internetbankingphilippine: 'Internet Banking (Philippine)',
      internetbankingsouthafrica: 'Internet Banking (South Africa)',
      internetbankinguganda: 'Internet Banking (Uganda)',
      internetbankingrwanda: 'Internet Banking (Rwanda)',
      internetbankingzambia: 'Internet Banking (Zambia)',
      internetbankingcongo: 'Internet Banking (Congo)',
      internetbankingcameroon: 'Internet Banking (Cameroon)',
      internetbankingburundi: 'Internet Banking (Burundi)',
      internetbankingkenya: 'Internet Banking (Kenya)',
      internetbankingghana: 'Internet Banking (Ghana)',
      internetbankingtanzania: 'Internet Banking (Tanzania)',
      internetbankingcanada: 'Interac® eTransfer',
      internetbankingcanadatm: '®Trade-mark of Interac Corp. Used under license',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Vietnam Bank Transfer',
      nigeriabanktransfer: 'Nigeria Bank Transfer',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Bank Wire Transfer (Australia)',
      thailandinstantbankwiretransfer: 'Thailand Instant Bank Wire Transfer',
      malaysiainstantbankwiretransfer: 'Malaysia Instant Bank Transfer',
      banktransferbpaypolipay: 'Bank Transfer/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      nigeriainstantbankwiretransfer: 'Nigerian Instant Bank Wire Transfer',
      vietnaminstantbankwiretransfer: 'Vietnamese Instant Bank Wire Transfer',
      indonesiainstantbankwiretransfer: 'Indonesia Instant Bank Transfer',
      philippinesinstantbankwiretransfer: 'Philippine instant Bank wire transfer',
      southafricainstantbankwiretransfer: 'South Africa instant Bank wire transfer',
      indiainstantbanktransfer: 'India Instant Bank Wire Transfer',
      southkoreabanktransfer: 'South Korea instant Bank wire transfer',
      ugandabanktransfer: 'Uganda Local Bank Transfer',
      rwandabanktransfer: 'Rwanda Local Bank Transfer',
      zambiabanktransfer: 'Zambia Local Bank Transfer',
      congobanktransfer: 'Congo Local Bank Transfer',
      cameroonbanktransfer: 'Cameroon Local Bank Transfer',
      burundibanktransfer: 'Burundi Local Bank Transfer',
      kenyabanktransfer: 'Kenya Local Bank Transfer',
      ghanabanktransfer: 'Ghana Local Bank Transfer',
      tanzaniabanktransfer: 'Tanzania Local Bank Transfer',
      banktransferbpaypolipayaustralia: 'Bank Transfer/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Bank Transfer/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Canadian Local Bank Transfer',
      cryptobitcoin: 'Crypto-Bitcoin',
      cryptotetheromni: 'Crypto-Tether(OMNI)',
      cryptotethererc20: 'Crypto-Tether(ERC20)',
      cryptotethertrc20: 'Crypto-Tether(TRC20)',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      astropay: 'Astropay',
      sticpay: 'SticPay',
      vload: 'Vload',
      perfectmoney: 'Perfect Money',
      transfer: 'Transfer to accounts',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'LOCAL TRANSFER',
    },
    country: { international: 'International', australia: 'Australia' },
    field: {
      country_region: 'Country/Region',
      address: 'Address',
      phone: 'Phone Number',
      nat: 'Nationality',
      country: 'Country of residency',
      save: 'Investment',
      annIncome: 'Annual Income',
      emailAdd: 'Email Address',
      yes: 'Yes',
      no: 'No',
      enterVerificationCode: 'Enter Verification Code',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'SUBMIT',
      FROM: 'FROM',
      UPDATE: 'UPDATE',
      TO: 'TO ',
      DATE: 'DATE',
      pleaseSelect: 'Please Select',
      amount: 'Amount',
      rebateAccount: 'Rebate Account',
      comingSoon: 'coming soon',
      tradingHistory: 'TRADING HISTORY',
      noData: 'No data',
      NAME: 'NAME',
      EMAIL: 'EMAIL',
      ACCTYPE: 'ACCOUNT TYPE',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASE CURRENCY',
      DATERANGE: 'DATE RANGE',
      STATUS: 'STATUS',
      DEST: 'DESTINATION',
      ACCNUM: 'ACCOUNT NUMBER',
      BALANCE: 'BALANCE',
      PROFITLOST: 'PROFIT/LOSS',
      MARGINLVL: 'MARGIN LEVEL',
      ACCEQUITY: 'ACCOUNT EQUITY',
      CREDIT: 'CREDIT',
      NONE: 'None',
      ALL: 'All',
      actualAmonut: 'Actual Amonut',
      currency: 'Currency',
      method: 'Method',
    },
    dateRange: {
      today: 'Today',
      yesterday: 'Yesterday',
      lastSevenDays: 'Last 7 Days',
      lastThirtyDays: 'Last 30 Days',
      weekToDate: 'Week To Date',
      monthToDate: 'Month To Date',
      quarterToDate: 'Quarter To Date',
      previousWeek: 'Previous Week',
      previousMonth: 'Previous Month',
      previousQuarter: 'Previous Quarter',
    },
    products: {
      forex: 'FOREX',
      commodities: 'COMMODITIES',
      indices: 'INDICES',
      crypto: 'CRYPTO',
      sharecfd: 'Share CFD',
      bond: 'Bond',
    },
    formValidation: {
      dynamicReq: '{dynamic} is required',
      verCodeReq: 'Verification code is required',
      alphanumericReq: 'Please enter alphanumeric',
    },
  },
  report: {
    title: {
      rebateReport: 'REBATE REPORT',
    },
    rebate: {
      column: {
        name: 'NAME',
        accountNumber: 'ACCOUNT NUMBER',
        accountType: 'ACCOUNT TYPE',
        volume: 'Volume',
        rebate: 'Rebate',
        totalRebate: 'TOTAL REBATE',
        crypto: 'Crypto',
        sharecfd: 'Share CFD',
        date: 'DATE',
        instrument: 'INSTRUMENT',
      },
      dropdown: {
        allInstruments: 'All Instruments',
        all: 'All',
        instruments: 'Instruments',
      },
      others: {
        rebate: 'REBATE',
        instrumentsTraded: 'INSTRUMENTS TRADED',
        lots: 'Lots',
        totalRebate: 'Total Rebate',
        rebates: 'Rebates',
      },
    },
  },
  ibReport: {
    header: 'IB REPORT',
    netFunding: 'NET FUNDING',
    deposits: 'DEPOSITS',
    withdraw: 'WITHDRAW',
    openedAccs: 'OPENED ACCOUNTS',
    funding: 'FUNDING',
    withdrawals: 'WITHDRAWALS',
    appliedAmount: 'Applied Amount',
    depositAmount: 'Deposit Amount',
    withdrawalAmount: 'Withdrawal Amount',
  },
  lead: {
    header: 'LEADS',
    ibAccount: 'IB Account',
    demoAccounts: 'DEMO ACCOUNTS',
  },
  pendingAccounts: {
    header: 'PENDING ACCOUNTS',
    errorDoc:
      'The following applicants have filled in the online application form but are yet to supply proof of identification (ID) in order to finalise their account.',
    acceptedDocType: 'Accepted forms of ID include:',
    primaryDoc: 'Primary ID',
    passport: 'A copy of your valid international passport (signature page)',
    id: 'A copy of your government issued national identity card',
    dl: 'A copy of your valid driver’s licence',
    address: 'Secondary ID',
    addressList: [
      'Copy of a recent Utility Bill (no older than 6 months)',
      'Copy of a credit card statement (no older than 6 months)',
      'Copy of a bank statement (no older than 6 months)',
    ],
    tip: 'Please encourage applicants to provide a copy of ONE of the accepted primary and secondary ID documents to ',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'TRANSACTION HISTORY',
    rebateHistory: 'REBATES',
    withdrawHistory: 'WITHDRAWALS',
    transferHistory: 'TRANSFERS',
    cancelWithdraw: 'Would you like to Cancel the Withdrawal Request?',
    cancelError:
      'Your withdrawal is being processed at the moment. Therefore, we cannot accept your cancellation request. For more information, you may reach us using the live chat feature.',
  },
  fileUploader: {
    supportedFormat: 'Supported file types: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Maximum upload file size: {maxSize}MB',
    maxFileNum: 'Maximum file number: {maxFileNum}',
    selectFile: 'Select File',
  },
  transferHistory: {
    payStatus: {
      processing: 'Processing',
      paid: 'Paid',
      unpaid: 'Unpaid',
      rejected: 'Rejected',
    },
  },
  referralLinks: {
    header: 'REFERRAL LINKS',
    ibAcc: 'IB Account',
    campLang: 'Campaign Language',
    cpy: 'COPY',
    alreadyCPY: 'COPIED!',
    liveAcc: 'Live Account',
    demoAcc: 'Demo Account',
    homePage: 'Home Page',
    Share: 'Share',
  },
  settings: {
    ibProfile: 'IB PROFILE',
    info: 'INFO',
    reg: 'REGISTERED ON',
  },
  myProfile: {
    changeDetails: 'If you wish to update your existing profile details, do not hesitate to contact {mailTo}',
  },
  unfundedAcc: {
    header: 'UNFUNDED ACCOUNTS',
    allActivationAccs: 'ALL ACTIVATION ACCOUNTS',
    neverFundedAccs: 'NEVER FUNDED',
    prevFundedZero: 'ZERO BALANCE',
  },
  multiib: {
    level: 'LEVEL {level}',
    noib: 'There are no sub-IBs under this account.',
  },
  sessionDialog: {
    title: 'Your session is about to expire!',
    content: 'You will be logged out in 60 seconds. Do you want to stay signed in?',
    keepIn: 'Yes, Keep me signed in',
    signOut: 'No, Sign me out',
  },
  header: {
    bckToCP: 'BACK TO CLIENT PORTAL',
    logout: 'LOG OUT',
  },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'IB REPORT',
    rebateReport: 'REBATE REPORT',
    accManagement: 'ACCOUNTS',
    iblevel: 'MULTI-LEVEL',
    ibAccs: 'ACTIVATED ACCOUNTS',
    leads: 'LEADS',
    pendingAccs: 'PENDING ACCOUNTS',
    unfundedAccs: 'UNFUNDED ACCOUNTS',
    payHistory: 'TRANSACTION HISTORY',
    ibProfile: 'IB PROFILE',
    refLinks: 'REFERRAL LINKS',
    contactUs: 'CONTACT US',
    switchCP: 'SWITCH TO CLIENT PORTAL',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn: `Risk Warning: Contracts for Difference (CFDs) trading carries a high level of risk to your capital and can result in losses, you should only trade with money you can afford to lose. CFDs trading may not be suitable for all investors, please ensure that you fully understand the risks involved and take appropriate measures to manage it. Please read the relevant Risk Disclosure document carefully.`,
  },
  responseMsg: {
    //4xx warning
    410: 'Parameter validation failed',
    411: 'Incorrect password',
    420: 'User does not exist',
    421: 'Password reset denied',
    490: 'Please do not submit duplicate card information',
    //5xx error
    500: 'An error has occured.',
    501: 'Application failed. Please try again later.',
    505: 'File upload failed',
    520: 'Login Failed. Please try again.',
    521: 'A user is already logged in on this device!',
    522: 'Please log in again',
    523: 'Please log in again',
    524: 'Unfortunately your email address was not recognized',
    525: 'Incorrect username or password',
    527: 'User is not an IB',
    528: 'User does not exist',
    529: 'Incorrect username or password',
    540: `Your trading account couldn't be found`,
    541: 'Cannot find rebate account',
    542: 'You are not allowed to open additional account',
    544: 'You are not allowed to apply for an additional account until your Proof of ID verified',
    550: 'Incorrect account number',
    551: 'Your balance is insufficient',
    553: 'You cannot withdraw/transfer due to the credit in your account. Please contact {email} for further help',
    554: 'Requested amount is zero or null',
    562: 'We were unable to process your payment, please try again. If the problem persists, please contact us via live chat, or email {email}',
    564: 'Country does not match the payment channel',
    565: 'Incorrect Bank Code',
    566: 'Payment method not found',
    581: 'You have successfully opted in to the promotion. Please note that you must make a deposit in order to withdraw funds.',
    582: 'Since you have entered the campaign and have open positions, we are unable to process your withdrawal request.',
    584: `You don't have any applicable rebates`,
    590: 'Cannot find transfer-out account',
    591: 'Cannot find transfer-in account',
    593: 'We are unable to process your funds transfer from the account selected due to your account containing credit.',
    594: 'Transfer of funds from this account is not allowed',
    595: 'Since you have entered our campaign, we are unable to process your transfer request',
    647: 'Invalid verification code',
    session_timeout: 'Session timeout, redirecting to login page.',
  },
}
