export default {
  en: 'English',
  zh: '中文',
  home: {
    NewPortal: '새로운 포털 사이트',
    SecureIBPortal: 'IB 포털 사이트',
    DASHBOARD: '대시보드',
    MARKETING_TOOLS: '마케팅 도구',
    IB_REPORT: 'IB 보고서',
    REBATE_REPORT: '리베이트 보고서',
    IB_ACCOUNTS: 'IB 계좌',
    PAYMENT_HISTORY: '거래내역',
    IB_FAQ: 'IB 관련 자주 묻는 질문',
    RebateAccountNumber: '리베이트 계좌번호',
    totalBalance: '총 금액',
    totalCommission: '커미션 합계',
    APPLY_FOR_A_REBATE: '대다',
    availableBalance: '사용 가능한 잔액',
    WITHDRAW_REBATE: '빼다',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '이전',
    MonthtoDate: '당월합계',
    UPDATE: '업데이트',
    REBATE: '리베이트',
    TOTAL_VOLUME: '총 볼륨',
    NET_FUNDING: '순 입금',
    DEPOSITS: '입금',
    WITHDRAW: '인출',
    OPEND_ACCOUNT: '개설 완료 계좌',
    PERFORMANCE: '실적',
    OpendAccount: '개설 완료 계좌',
    select_ib: 'IB 계정을 선택하십시오',
    MOST_TRADED_INSTRUMENTS: '가장 많이 거래된 상품',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '이달의 우수 실적 계좌',
    RECENTLY_OPENED_ACCOUNTS: '최근 개설 계좌',
    ACCOUNT_MANAGER: '고객지원',
    Account: '금액',
    Name: '성명',
    Volume: '볼륨',
    Rebate: '리베이트',
    Balance: '잔액',
    ContactClient: '연락하기',
    YouCannotAskForCommission: '커미션 신청에 실패하였습니다.',
    applicationFailedPleaseTryAgain: '요청 실패입니다. 잠시 후 다시 진행해주십시오.',
    applyRebateSuccessTip:
      '귀하의 리베이트 신청이 접수되었습니다.  | {amount} 은 영업일 1일 이내에 리베이트 계좌 {account}로 이체될 예정입니다. | 리베이트 금액이 계좌로 반영 완료 후, 출금 또는 거래 계좌로 이체가 가능합니다. ',
    HOVER:
      'IB 커미션은 일일 정산을 원칙으로 하고 있으며, 전 날의 커미션은 *호주 동부 표준시 기준 매일 09:00 – 11:00 에 정산되어 표기됩니다. ',
  },
  liveChat: { header: '실시간 채팅' },
  contactUs: {
    header: '연락하기',
    followUs: '우리를 따르라',
    tip: '전문가로 구성된 {platform}의 글로벌 고객지원팀이 고객님께 실시간 고객지원 서비스를 제공합니다.',
  },
  ibAccounts: {
    header: 'IB 계좌',
    ibAccount: 'IB 계좌',
    subIbs: 'SUB IB 계좌',
    tradinglist: '거래 목록',
    openlist: '목록 열기',
    closedlist: '비공개 목록',
    tradinglistColumn: {
      ticketid: '티켓 ID',
      opentime: '진입시간',
      type: '종류',
      volume: '거래량',
      item: '상품',
      openprice: '공개 가격',
      closeprice: '종가',
      closetime: '청산시간',
      commission: '수수료',
      profit: '손익',
      buy: '구입',
      sell: '팔다',
    },
  },
  withdraw: {
    default: {
      selectCC: '신용카드 선택',
      selectBA: '은행 계좌 선택',
      anotherCC: '신용카드를 수동으로 입력하세요.',
      anotherBA: '은행 계좌 추가',
      remember: '내 계정 기억',
      verificationCode:
        '{email}에 확인 코드가 전송되었습니다. 추가로 고객 지원이 필요한 경우 {supportEmail}에 문의하십시오',
    },
    alert:
      'AML 법률에 따라 인출에 사용하는 방법은 입금에 사용 된 방법과 동일해야합니다. 인출 금액이 계좌 기본 통화의 100 단위 미만인 경우 은행에서 처리 수수료 20 단위를 부과합니다.',
    NetellerChargeAlert: 'Neteller 인출은 거래 당 $ 10,000로 제한되며 2 % 수수료 ($ 30로 제한)가 적용됩니다.',
    FasaPayChargeAlert: 'FasaPay는 모든 출금에 대해 0.5 %의 거래 수수료를 부과합니다.',
    SkrillChargeAlert: 'Skrill은 모든 출금에 대해 1 %의 거래 수수료를 부과합니다.',
    cryptoAlert:
      'BUSDT를 통해 입금 또는 출금할 수 없습니다. 주소와 암호화폐가 허용되는 체인 및 통화와 일치하는지 확인하세요. 그렇지 않으면 자금을 잃을 수 있습니다.',
    REBATE_WITHDRAW_REQUEST: '리베이트 출금 신청',
    PleaseSelectDesiredWithdrawMethod: '출금 방식을 선택해주십시오',
    BankName: '수취인 거래은행',
    BankAccountsName: '예금주',
    FasaPayAccountName: 'FasaPay 계정이름',
    AustralianBankName: '호주은행명',
    BankAccountNumber: '은행계좌번호',
    FasaPayAccountNumber: 'FasaPay 계정번호',
    BankAccountBeneficiary: '계좌 수령인',
    BSB: 'BSB',
    skrillEmail: 'Skrill 이메일',
    NetellerEmail: 'Neteller 이메일',
    PerfectMoneyEmail: 'Perfect Money 이메일',
    SticpayEmail: 'SticPay 이메일',
    BitcoinEmail: 'Bitcoin 지갑 주소',
    BitwalletEmail: 'Bitwallet 이메일',
    USDTEmail: 'Tether 지갑 주소',
    BankBranch: '은행지점',
    Province: '시/도',
    City: '시/군/구',
    BankAddress: '은행주소',
    bankCity: '시/군/구',
    bankProvince: '시/도',
    AccountNumberIBAN: '계좌번호/IBAN',
    BankBeneficiaryName: '수취인명',
    BankAccountBeneficiaryName: '계좌 수령인',
    AccountHoldersAddress: '예금주 주소',
    SelectWithdrawCard: '출금하고자 하는 카드를 선택 하십시오.',
    Swift: 'Swift코드',
    SwiftAud: 'Swift코드 (호주계좌 제외)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: '중요사항',
    YourPaymentGreaterThanAvailableBalance: '입력하신 지불 금액이 잔액을 초과합니다',
    CannotUseMoreThanTwoDecimalValues: '소수점 이하 두 자리 숫자와 0이하의 숫자는 입력이 불가합니다',
    YourPaymentIsBeingProcessed: '귀하의 출금신청이 접수되었습니다. ',
    Itcannotbe0: "0'은 입력이 불가합니다.",
    noCardWarn: '출금 가능한 카드가 존재하지 않습니다. 출금 세부정보에 카드 정보를 먼저 등록하시기 바랍니다. ',
    amtLarger: '금액은 빈칸으로 제출할 수 없으며 {minLimit} {currency}보다 크거나 같아야 합니다.',
    blackList: `죄송합니다. 현재 인출 요청을 제출할 수 없습니다. 자세한 내용은 {supportEmail}에 문의하십시오.`,
    status: {
      withdrawalSubmitted: '제출됨',
      withdrawalCanceled: '취소됨',
      withdrawalAuditing: '처리중',
      withdrawalRejected: '거절',
      withdrawalAccepted: '처리중',
      withdrawalPayFailed: '실패',
      withdrawalSuccess: '처리됨',
      withdrawalFailed: '실패',
      withdrawalPending: '대기중',
      withdrawalPartialFailed: '실패',
      transferSubmitted: '처리중',
      transferSuccess: '처리됨',
      transferRejected: '거절',
      transferFailed: '실패',
      transferProcessing: '처리중',
    },
    formErrorMsg: {
      bankName: '은행명을 입력하십시오.',
      bsb: 'BSB를 입력하십시오.',
      beneName: '계좌예금주를 입력하십시오.',
      accNum: '은행계좌번호를 입력하십시오.',
      swift: 'Swift코드',
      bankAddress: '은행주소',
      holderAddress: '예금주 주소',
      amt: '금액을 입력하십시오.',
      firstSixDigits: '앞의 6자리를 입력해주십시오',
      lastFourDigits: '마지막 4자리를 입력해주십시오',
      branchName: '분점명',
      email: '이메일',
      accName: '예금주',
      province: '은행지점명',
      city: '은행주소(City시/구/군)를 입력하십시오.',
      bankCard: '카드를 선택하십시오.',
      emailFormat: '올바른 이메일을 입력해 주십시오.',
      amt0: '0 이상의 금액을 입력하십시오.',
      bitcoin: 'Bitcoin 지갑 주소를 입력해주십시오',
      addressLength: '비트코인 지갑 주소는 26~42자 사이여야 합니다',
      usdt: 'USDT 지갑 주소를 입력해주십시오',
    },
    transfer: {
      info:
        '{platform}은 매 월 1회 은행 송금 출금 수수료를 무료로 면제합니다. 한 달 안에 추가로 인출할 경우 인출 통화의 20불의 은행 송금 수수료가 발생합니다.',
      label: { upload: '은행 명세서를 업로드해주십시오. (최근 3개월 거래내역 포함)' },
      formValidation: { upload: '이체내역서를 업로드해주십시오.' },
    },
    transitNumber: '환승번호',
    institutionNumber: '기관번호',
  },
  rebate: {
    status: { processed: '처리됨', processing: '처리중', rejected: '거절' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: '리베이트 이체 신청',
    transferAmountIsGreaterThanAvailableBalance: '이체금액이 잔액을 초과했습니다',
    yourRebateTransferHasBeenProcessed: '귀하의 리베이트 이체가 접수되었습니다',
    PleaseEnterTransferInformation: '정확한 이체 정보를 입력하십시오',
    tradingAccount: '거래 계좌',
    rebateAccount: '리베이트 계좌',
    account: '금액',
    blackList: '계정 간 이체가 제한된 계정입니다. {supportEmail}로 문의하시기 바랍니다.',
  },
  common: {
    liveChat: { desc: '도움이 필요하신가요?실시간 채팅' },
    lang: {
      english: '영어',
      chinese: '중국어',
      french: '프랑스어',
      thai: '태국어',
      german: '독일어',
      spanish: '스페인어',
      malay: '말레이어',
      viet: '베트남어',
      indo: '인도네시아어',
      arabic: '아랍어',
    },
    button: { confirm: '확인', cancel: '취소', sendCode: '코드 발송', resendCode: '코드 재전송' },
    withdrawChannel: {
      banktransfer: '은행송금',
      aus: '호주은행송금',
      int: '국제은행송금',
      chn: '중국은행송금',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: '유니온페이',
      unionpaytransfer: '유니온페이이체',
      fasapay: 'FasaPay',
      creditcard: '신용카드 ',
      bpay: 'Bpay',
      creditdebitcard: '신용/체크 카드',
      bankwiretransferinternational: '해외송금(국제)',
      banktransferaustralia: '은행송금(오스트레일리아)',
      banktransferinternational: '은행송금(국제)',
      banktransferchina: '은행송금(국제)',
      mobilepay: '모바일결제',
      brokertobrokertransfer: '브로커 간 이체',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '인터넷뱅킹 (태국)',
      internetbankingnigeria: '인터넷뱅킹 (나이지리아)',
      internetbankingvietnam: '인터넷뱅킹 (베트남)',
      internetbankingmalaysia: '인터넷뱅킹 (말레이시아)',
      internetbankingindonesia: '인터넷뱅킹 (인도네시아)',
      internetbankingkorea: '인터넷뱅킹 (한국)',
      internetbankingphilippine: '인터넷뱅킹 (필리핀 제도)',
      internetbankingsouthafrica: '인터넷뱅킹 (남아프리카)',
      internetbankinguganda: '인터넷뱅킹 (우간다)',
      internetbankingrwanda: '인터넷뱅킹 (르완다)',
      internetbankingzambia: '인터넷뱅킹 (잠비아)',
      internetbankingcongo: '인터넷뱅킹 (콩고)',
      internetbankingcameroon: '인터넷뱅킹 (카메룬)',
      internetbankingburundi: '인터넷뱅킹 (부룬디)',
      internetbankingkenya: '인터넷뱅킹 (케냐)',
      internetbankingghana: '인터넷뱅킹 (가나)',
      internetbankingtanzania: '인터넷뱅킹 (탄자니아)',
      internetbankingcanada: '인터넷뱅킹 (캐나다)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '베트남 은행송금이체',
      nigeriabanktransfer: '나이지리아 은행송금이체',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: '은행송금 (오스트레일리아)',
      thailandinstantbankwiretransfer: '태국 은행 계좌이체',
      malaysiainstantbankwiretransfer: '말레이시아 은행 즉시이체',
      banktransferbpaypolipay: '은행송금/Bpay/PoliPay',
      cryptocurrencybitcoin: '암호 화폐-Bitcoin',
      cryptocurrencyusdt: '암호 화폐-USDT',
      nigeriainstantbankwiretransfer: '나이지리아 은행계좌이체',
      vietnaminstantbankwiretransfer: '베트남 은행계좌이체',
      indonesiainstantbankwiretransfer: '인도네시아 은행계좌이체',
      philippinesinstantbankwiretransfer: '필리핀 제도 은행계좌이체',
      southafricainstantbankwiretransfer: '남아프리카 은행 즉시이체',
      indiainstantbanktransfer: '인도 은행 즉시이체',
      southkoreabanktransfer: '한국 즉시 은행 송금',
      ugandabanktransfer: '우간다 국내 은행이체',
      rwandabanktransfer: '르완다 국내 은행 이체',
      zambiabanktransfer: '잠비아 국내 은행 이체',
      congobanktransfer: '콩고 국내 은행 이체',
      cameroonbanktransfer: '카메룬 국내 은행 이체',
      burundibanktransfer: '부룬디 국내 은행 이체',
      kenyabanktransfer: '케냐 국내 은행 이체',
      ghanabanktransfer: '가나 국내 은행 이체',
      tanzaniabanktransfer: '탄자니아 국내 은행 이체',
      banktransferbpaypolipayaustralia: '은행송금/BPay/PoliPay (오스트레일리아)',
      banktransferbpaypolipayinternational: '은행송금/BPay/PoliPay (국제)',
      paypal: '페이팔',
      canadianlocalbanktransfer: '캐나다 로컬 은행 계좌 이체',
      cryptobitcoin: '암호 화폐-Bitcoin',
      cryptotetheromni: '암호 화폐-Tether(OMNI)',
      cryptotethererc20: '암호 화폐-Tether(ERC20)',
      cryptotethertrc20: '암호 화폐-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: '계정으로 이체',
      E_WALLET: '전자 지갑',
      CRYPTOCURRENCY: '암호 화폐',
      LOCAL_TRANSFER: '로컬 전송',
    },
    country: { international: '국제은행송금', australia: '호주' },
    field: {
      country_region: '국가/지역',
      address: '상세주소',
      phone: '연락처',
      nat: '국가',
      country: '거주지',
      save: '투자',
      annIncome: '연수입',
      emailAdd: '이메일',
      yes: '예',
      no: '아니오',
      enterVerificationCode: '확인 코드 입력',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: '제출하기',
      FROM: '부터',
      UPDATE: '업데이트',
      TO: '까지',
      DATE: '일시',
      pleaseSelect: '선택하십시오.',
      amount: '금액',
      rebateAccount: '리베이트 계좌',
      comingSoon: '커밍순',
      tradingHistory: '거래 내역',
      noData: '데이터 없음',
      NAME: '이름',
      EMAIL: '이메일',
      ACCTYPE: '계좌유형',
      PLATFORM: '플랫폼',
      BASECURRENCY: '기준통화',
      DATERANGE: '리베이트 발생 시기',
      STATUS: '처리현황',
      DEST: '목적',
      ACCNUM: '계좌번호',
      BALANCE: '잔액',
      PROFITLOST: '손익',
      MARGINLVL: '마진 레벨',
      ACCEQUITY: '계정 자산',
      CREDIT: '신용 거래',
      NONE: '없음',
      ALL: '모두',
      actualAmonut: '실제 금액',
      currency: '통화',
      method: '방식',
    },
    dateRange: {
      today: '금일',
      yesterday: '전일',
      lastSevenDays: '7일 이전',
      lastThirtyDays: '30일 이전',
      weekToDate: '이번 주',
      monthToDate: '당월합계',
      quarterToDate: '분기별 합계',
      previousWeek: '지난 주',
      previousMonth: '지난 달',
      previousQuarter: '지난 분기',
    },
    products: {
      forex: '외환',
      commodities: '원자재',
      indices: '지수',
      crypto: '가상화폐',
    },
    formValidation: {
      dynamicReq: '{dynamic} 은 필수입력 사항입니다.',
      verCodeReq: '확인 코드가 필요합니다',
      alphanumericReq: '영숫자를 입력해주십시오.',
    },
  },
  report: {
    title: { rebateReport: '리베이트 보고서' },
    rebate: {
      column: {
        name: '이름',
        accountNumber: '계좌번호',
        accountType: '계좌유형',
        volume: '볼륨',
        rebate: '리베이트',
        totalRebate: '리베이트 합계',
        date: '일시',
        instrument: '거래 상품',
      },
      dropdown: {
        allInstruments: '모든 거래 상품',
        all: '모두',
        instruments: '거래 상품',
      },
      others: {
        rebate: '리베이트',
        instrumentsTraded: '거래 상품',
        lots: '랏',
        totalRebate: '리베이트 합계',
        rebates: '리베이트',
      },
    },
  },
  ibReport: {
    header: 'IB 보고서',
    netFunding: '순 입금',
    deposits: '입금',
    withdraw: '출금',
    openedAccs: '개설 완료 계좌',
    funding: '입출금 내역',
    withdrawals: '인출',
    appliedAmount: '적용 금액',
    depositAmount: '입금 금액',
    withdrawalAmount: '출금 금액',
  },
  lead: { header: '리즈', ibAccount: 'IB 계좌', demoAccounts: '데모계좌' },
  pendingAccounts: {
    header: '대기중 계좌',
    errorDoc:
      '다음 신청자들은 온라인으로 개설 신청서를 작성했지만, 최종 단계에서 신분증명 (ID) 자료를 제출하지 않았습니다.',
    acceptedDocType: '본인 인증 자료',
    primaryDoc: '신분증명자료',
    passport: '여권 (서명란 포함)',
    id: '신분증(앞/뒤)',
    dl: '운전면허증(앞/뒤)',
    address: '주소증명자료',
    addressList: [
      '최근 6개월 이내 발급된 공과금(전기/수도/가스) 영수증 사본을 제출해주시길 바랍니다',
      '최근 6개월 이내 발급된 신용카드내역서 사본을 제출해 주시길 바랍니다',
      '최근 6개월 이내 은행거래내역서 사본을 제출해 주시길 바랍니다',
    ],
    tip: '신분 증명 자료를 다음의 주소로 첨부하시기 바랍니다. ',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: '거래내역',
    rebateHistory: '리베이트 내역',
    withdrawHistory: '출금 내역',
    transferHistory: '이체내역',
    cancelWithdraw: `출금신청을 취소하시겠습니까?`,
    cancelError: `귀하의 출금이 현재 처리 중입니다. 따라서 취소 요청을 수락할 수 없습니다. 자세한 내용은 라이브 채팅 기능을 사용하여 문의할 수 있습니다.`,
  },
  fileUploader: {
    supportedFormat: '지원 파일 형태 : png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: '파일 업로드 크기: {maxSize} MB',
    maxFileNum: '최대 파일업로드 개수:{maxFileNum}',
    selectFile: '찾아보기',
  },
  transferHistory: {
    payStatus: {
      processing: '처리중',
      paid: '지불',
      unpaid: '미지불',
      rejected: '거절',
    },
  },
  referralLinks: {
    header: '관련 링크',
    ibAcc: 'IB 계좌',
    campLang: '언어',
    cpy: '복사',
    alreadyCPY: '복사됨',
    liveAcc: '실계좌',
    demoAcc: '데모계좌',
    homePage: '홈페이지',
    Share: '공유하기',
  },
  settings: { ibProfile: 'IB 프로필', info: '내 정보', reg: '등록' },
  myProfile: { changeDetails: '프로필 세부 정보의 업데이트가 필요하시면 {mailTo}로 문의하시기 바랍니다.' },
  unfundedAcc: {
    header: '계좌 예치 현황',
    allActivationAccs: '활성화 된 모든 계정',
    neverFundedAccs: '미예치 계좌',
    prevFundedZero: '현재 잔액없음',
  },
  multiib: {
    level: '{level} 단계',
    noib: '이 계정에는 하위 IB가 없습니다.',
  },
  header: { bckToCP: '고객 포털사이트로 되돌아가기', logout: '로그아웃' },
  menu: {
    dashboard: '대시보드',
    ibReport: 'IB 보고서',
    rebateReport: '리베이트 보고서',
    accManagement: '계좌관리',
    iblevel: '다단계',
    ibAccs: '활성화된 계좌',
    leads: '리즈',
    pendingAccs: '대기중 계좌',
    unfundedAccs: '계좌 예치 현황',
    payHistory: '거래내역',
    ibProfile: 'IB 프로필',
    refLinks: '관련 링크',
    contactUs: '연락하기',
    switchCP: '고객 포털사이트로 이동',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      '위험 경고 : CFD 거래는 자금에 높은 위험을 가져오고 손실을 초래할 수 있으므로 손실을 견딜 수있는 자금으로 만 거래해야합니다. CFD 거래는 모든 투자자에게 적합하지 않을 수 있으므로 관련된 위험을 완전히 이해하고 적절한 조치를 취하여 관리하십시오. 관련 위험 공개 문서를주의 깊게 읽으십시오.',
  },
  responseMsg: {
    410: '매개변수 검증에 실패하였습니다',
    411: '비밀번호 불일치',
    420: '사용자가 존재하지 않습니다',
    421: '비밀번호 재설정이 거부되었습니다',
    490: '중복된 카드 정보입니다. ',
    500: '에러가 발생했습니다.',
    501: '실행이 취소되었습니다. 나중에 다시 시도하십시오.',
    505: '파일 업로드에 실패하였습니다',
    520: '로그인에 실패했습니다. 다시 로그인하시기 바랍니다.',
    521: '사용자가 이미 동일 기기에 로그인했습니다.',
    522: '다시 로그인해주십시오.',
    523: '다시 로그인해주십시오.',
    524: '귀하의 이메일 주소가 유효하지 않습니다. ',
    525: '아이디 또는 비밀번호가 일치하지 않습니다.',
    527: '사용자가 IB가 아닙니다',
    528: '사용자가 존재하지 않습니다',
    529: '아이디 또는 비밀번호가 일치하지 않습니다.',
    540: '귀하의 거래 계좌를 찾을 수 없습니다.',
    541: '리베이트 계좌를 찾을 수 없습니다.',
    542: '같은 이름으로 계좌를 개설 할 수 없습니다.',
    544: '신분증이 인증될 때까지 추가 계정을 신청할 수 없습니다',
    550: '계좌번호 불일치',
    551: '잔액이 부족합니다.',
    553: '계정의 크레딧으로 인해 출금 / 이체 할 수 없습니다. 추가 도움이 필요하면 {email}에 문의하십시오.',
    554: '요청하신 금액이 0 또는 무효입니다.',
    564: '국가가 결제 채널과 일치하지 않습니다',
    565: '은행코드 불일치',
    566: '지불 방식을 찾을 수 없습니다.',
    581: '프로모션에 성공적으로 선택했습니다. 자금을 인출하려면 입금해야합니다.',
    582: '이벤트에 참여 중이며 직위가 있으므로 탈퇴 신청서를 제출할 수 없습니다.',
    584: '요청 가능한 리베이트가 존재하지 않습니다.',
    590: '송금 계좌를 찾을 수 없습니다.',
    591: '귀하의 은행 계좌를 찾을 수 없습니다.',
    593: '귀하의 계정에 크레딧이 포함되어 있기 때문에 선택한 계정에서 자금 이체를 처리할 수 없습니다.',
    594: '이 계정에서 자금 이체는 허용되지 않습니다',
    595: '귀하가 당사 활동에 참여하고 있으므로 현재 귀하의 이전 요청을 처리 할 수 ​​없습니다.',
    647: '확인 코드가 잘못 입력되었습니다',
    session_timeout: '장 시간 로그인 하지 않았습니다. 재 로그인 해주십시오.',
  },
}
