import Vue from 'vue'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import i18n from '@/lang/index'

Vue.filter('currencySymbol', function(currency) {
  switch (currency) {
    case 'USD':
      return '$'
    case 'AUD':
      return 'A$'
    case 'CAD':
      return 'C$'
    case 'EUR':
      return '€'
    case 'GBP':
      return '￡'
    case 'NZD':
      return 'NZ$'
    case 'SGD':
      return 'S$'
    case 'JPY':
      return '￥'
    case 'HKD':
      return 'HK$'
    case 'USC':
      return '￠'
    default:
      return '$'
  }
})

Vue.filter('formatDate', function(value, format) {
  if (value) {
    return format ? moment(String(value)).format(format) : moment(String(value)).format('YYYY-MM-DD')
  }
})

Vue.filter('dateFormatWithTimeZone', function(dataStr, pattern = 'DD/MM/YYYY HH:mm:ss zz') {
  let timezone = momentTZ.tz.guess()
  let EDTTime = momentTZ.tz(dataStr, 'YYYY-MM-DD HH:mm zz', 'America/New_York')
  return EDTTime.clone()
    .tz(timezone)
    .format(pattern)
})

Vue.filter('formatTimestamp', function(value, format) {
  if (value) {
    return format ? moment.unix(String(value)).format(format) : moment.unix(String(value)).format('YYYY-MM-DD')
  }
})

Vue.filter('date', function(dataStr, pattern = 'DD/MM/YYYY') {
  return moment(dataStr).format(pattern)
})

Vue.filter('payStatus', status => {
  var key = status.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  return i18n.t('transferHistory.payStatus.' + key)
})

Vue.filter('formatNumber', function(value, decimals) {
  if (value >= 0 && typeof value == 'number') {
    return decimals ? value.toFixed(decimals) : value.toFixed(2)
  }
})

Vue.filter('formatPlatform', function(value) {
  if (value == '4') {
    return 'MT4'
  }
  if (value == '5') {
    return 'MT5'
  }
})

Vue.filter('formatUppercase', function(value) {
  if (value) {
    return value.toUpperCase()
  }
})

Vue.filter('country', function(key) {
  var key = removeSpecialChars(key)
  return i18n.t('common.country.' + key)
})

function removeSpecialChars(key) {
  return key.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
}

Vue.filter('googleLangSwitch', lang => {
  switch (lang) {
    case 'en_US':
      return 'en'
    case 'zh_CN':
      return 'zh-CN'
    case 'fr_FR':
      return 'fr'
    case 'ja':
      return 'ja'
    case 'ko':
      return 'ko'
    case 'th':
      return 'th'
    case 'vi':
      return 'vi'
    case 'es':
      return 'es'
    case 'pt':
      return 'pt'
    case 'de':
      return 'de'
    case 'id':
      return 'id'
    case 'ar':
      return 'ar'
    default:
      return 'en'
  }
})

Vue.filter('methods', method => {
  var key = method.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  return i18n.t('common.withdrawChannel.' + key)
})
