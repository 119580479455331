<template>
  <div class="page_panel">
    <div class="page_panel_tab" v-if="tabs.length > 0">
      <div class="item" :class="{ 'is-active': innerTabActive === tab.name }" :data-testid="tab.dataTestId"
        v-for="tab in tabs" @click="onTabClick(tab)">
        {{ tab.label }}
      </div>
    </div>

    <div class="container" :style="containerStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagePanel',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabActive: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    innerTabActive: {
      get() {
        return this.tabActive
      },
      set(val) {
        this.$emit('update:tabActive', val)
      },
    },
    containerStyle() {
      return this.tabs.length > 0 ? {
        '--radius': `0 8px 8px 8px`
      } : { '--radius': `8px` }
    }
  },
  methods: {
    onTabClick(tab) {
      this.innerTabActive = tab.name
      this.$emit('tabClick', tab)
    },
  },
}
</script>

<style lang="scss" scoped>
.page_panel {
  &+.page_panel {
    margin-top: 20px;
  }

  .page_panel_tab {
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      padding: 0 24px;
      line-height: 32px;
      text-align: center;
      background-color: #ededf2;
      border-radius: 8px 8px 0 0;
      color: $text-primary;
      font-size: 14px;
      width: fit-content;
      cursor: pointer;
      white-space: nowrap;

      &.is-active {
        font-weight: 700;
        background-color: $white;
        color: $primary;
      }

      &+.item {
        @include rtl-sass-prop(margin-left, margin-right, 8px);
      }
    }
  }

  .container {
    background-color: $white;
    border-radius: var(--radius);
    padding: 24px;
  }
}
</style>
