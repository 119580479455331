export default {
  home: {
    NewPortal: 'بوابة جديدة',
    SecureIBPortal: 'بوابة الوسيط الـ (IB) الآمنة',
    DASHBOARD: 'اللوحة الرئيسية',
    MARKETING_TOOLS: 'أدوات التسويق',
    IB_REPORT: 'تقرير الـ IB',
    REBATE_REPORT: 'تقرير العمولة المرتجعة',
    IB_ACCOUNTS: 'حسابات الـ IB',
    PAYMENT_HISTORY: 'سجل التحويلات',
    IB_FAQ: 'تعليمات الـ IB',
    RebateAccountNumber: 'رقم حساب العمولة المرتجعة',
    totalBalance: 'اجمالي الرصيد',
    totalCommission: 'اجمالي العمولة',
    APPLY_FOR_A_REBATE: 'تطبيق',
    availableBalance: 'الرصيد المتوفر',
    WITHDRAW_REBATE: 'السحب',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'تحويل',
    MonthtoDate: 'الشهر ليوم',
    UPDATE: 'التحديث',
    REBATE: 'العمولة المرتجعة',
    TOTAL_VOLUME: 'اجمالي حجم العقد',
    NET_FUNDING: 'صافي التمويل',
    DEPOSITS: 'الايداع',
    WITHDRAW: 'السحب',
    OPEND_ACCOUNT: 'حساب مفتوح',
    PERFORMANCE: 'الاداء',
    OpendAccount: 'حساب مفتوح',
    select_ib: 'الرجاء اختر حساب الوسيط الـ (IB)',
    MOST_TRADED_INSTRUMENTS: 'الادوات الاكثر تداول',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: ' الحسابات الأكثر أداء لهذا الشهر',
    RECENTLY_OPENED_ACCOUNTS: 'الحسابات المفتوحة حديثا',
    ACCOUNT_MANAGER: 'خدمة العملاء',
    Account: 'الحساب  ',
    Name: 'الاسم',
    Volume: 'الحجم',
    Rebate: 'العمولة المرتجعة',
    Balance: 'الرصيد المتوفر',
    ContactClient: 'الاتصال بعميل',
    YouCannotAskForCommission: 'لاتستطيع طلب عمولة',
    applicationFailedPleaseTryAgain: 'فشل التطبيق ، يرجى المحاولة مرة أخرى لاحقا',
    applyRebateSuccessTip:
      'تهانينا ، لقد تلقينا طلب الخصم الخاص بك. | سيتم تحويل {amount} إلى حساب الخصم {account} قريبًا. | بمجرد المعالجة ، ستتمكن من سحب أموالك أو تحويلها.',
    HOVER: 'يتم احتساب العمولات المرتجعة لليوم السابق ومعالجتها يوميًا من الساعة 09:00 إلى 11:00 بتوقيت شرق أستراليا.',
  },
  liveChat: { header: 'دردشة مباشرة' },
  contactUs: {
    click: 'انقر',
    assist: 'للحصول على المساعدة الآن',
    header: 'اتصل بنا',
    followUs: 'تابعنا',
    tip:
      'إن فريق دعم العملاء المحترف متعدد اللغات التابع لـ {platform} مستعد لمساعدتك ومساعدتك في توفير تجربة تداول لا تضاهى.',
  },
  ibAccounts: {
    header: 'حسابات الـ IB',
    ibAccount: 'حساب IB',
    subIbs: 'حساب IB فرعي',
    tradinglist: 'قائمة المعاملات',
    openlist: 'افتح القائمة',
    closedlist: 'القائمة مغلقة',
    tradinglistColumn: {
      ticketid: 'رقم الصفقة',
      opentime: 'وقت الإفتتاح',
      type: 'النوع',
      volume: 'الحجم',
      item: 'الأداة',
      openprice: 'سعر الفتح',
      closeprice: 'سعر الإغلاق',
      closetime: 'وقت الغلق',
      commission: 'العمولة',
      profit: 'الربح',
      buy: 'شراء',
      sell: 'باع',
    },
  },
  withdraw: {
    default: {
      selectCC: 'اختر بطاقة الإئتمان',
      selectBA: 'اختر حساب البنك',
      anotherCC: 'ادخل بطاقة الإئتمان يدويا',
      anotherBA: 'اضف حساب البنك',
      remember: 'تذكر حسابي',
      verificationCode:
        'لقد أرسل رمز التحقق إلى {email}. يرجى التواصل عبر البريد الإلكتروني {supportEmail} إذا كنت بحاجة إلى مزيد من المساعدة',
    },
    alert:
      'نظرًا لقوانين مكافحة غسل الأموال ، يجب أن تكون الطريقة التي تستخدمها للسحب هي نفس الطريقة المستخدمة في الإيداع الخاص بك. إذا كان مبلغ السحب أقل من 100 وحدة من العملة الأساسية لحسابك ، فسيتم تحميلك 20 وحدة مقابل رسوم المناولة من قبل البنك.',
    NetellerChargeAlert:
      'سحوبات Neteller محدودة بحد يبلغ 10،000 دولار في المعاملة، ويطبق رسم بنسبة 2% (بحد أقصى 30 دولار).',
    FasaPayChargeAlert: 'تفرض FasaPay رسم معاملة 0.5% على كل سحب.',
    SkrillChargeAlert: 'تفرض Skrill رسم معاملة 1% على كل سحب.',
    cryptoAlert:
      'يرجى ملاحظة أننا غير قادرين على الإيداع والسحب من خلال BUSDT، يرجى التأكد من أن العنوان والعملة المشفرة يطابقان السلسلة والعملة التي نقبلها، وإلا فقد تخسر المال.',
    REBATE_WITHDRAW_REQUEST: 'طلب سحب العمولة  المرتجعة',
    PleaseSelectDesiredWithdrawMethod: 'الرجاء تحديد طريقة السحب المطلوبة',
    BankName: 'اسم البنك',
    BankAccountsName: 'اسم حسابات البنك',
    FasaPayAccountName: 'اسم حساب FasaPay',
    AustralianBankName: 'اسم البنك الاسترالي',
    BankAccountNumber: 'رقم حساب البنك',
    FasaPayAccountNumber: 'رقم حساب FasaPay',
    BankAccountBeneficiary: 'حساب بنك المستفيد',
    BSB: 'BSB',
    skrillEmail: 'ايميل Skrill',
    SticpayEmail: 'ايميل SticPay',
    NetellerEmail: 'ايميل Neteller',
    PerfectMoneyEmail: 'ايميل Perfect Money',
    BitwalletEmail: 'ايميل Bitwallet',
    BitcoinEmail: 'عنوان محفظة البيتكوين',
    USDTEmail: 'عنوان محفظة Tether',
    BankBranch: 'فرع البنك',
    Province: 'المقاطعة',
    City: 'المدينة',
    BankAddress: 'عنوان البنك',
    bankCity: 'مدينة البنك',
    bankProvince: 'مقاطعة البنك',
    AccountNumberIBAN: 'رقم الحساب / IBAN',
    BankBeneficiaryName: 'اسم بنك المستفيد',
    BankAccountBeneficiaryName: 'اسم صاحب حساب بنك المستفيد',
    AccountHoldersAddress: 'عنوان صاحب الحساب  ',
    SelectWithdrawCard: 'حدد بطاقة السحب الخاصة بك',
    Swift: 'كود التحويل',
    ifscCode: 'كود الـ IFSC',
    SwiftAud: 'كود التحويل (حسابات ليس بالدولار الاسترالي)',
    ABA_SortCodeABA: 'ABA / رمز الفرز',
    ImportantNotes: 'ملاحظات هامة',
    YourPaymentGreaterThanAvailableBalance: 'دفعتك أكبر من الرصيد المتاح',
    CannotUseMoreThanTwoDecimalValues:
      'لا يمكنك استخدام الأعداد والأرقام السالبة التي تحتوي على أكثر من منزلتين عشريتين',
    YourPaymentIsBeingProcessed: 'تم تقديم طلب السحب الخاص بك',
    Itcannotbe0: 'لا يمكن أن يكون 0',
    noCardWarn: 'ليس لديك أي بطاقة متاحة للسحب ، يرجى ربط بطاقتك في تفاصيل السحب أولاً',
    amtLarger: 'لا يمكن أن يكون المبلغ فارغًا ويجب أن يكون أكبر من أو يساوي {minLimit} {currency}',
    blackList: `للأسف لا يمكنك حاليًا تقديم طلب سحب. يرجى الاتصال بـ {supportEmail} للحصول على مزيد من المعلومات`,
    status: {
      withdrawalSubmitted: 'تم التقديم',
      withdrawalCanceled: 'تم الالغاء',
      withdrawalAuditing: 'تتم المعالجة',
      withdrawalRejected: 'تم الرفض',
      withdrawalAccepted: 'تتم المعالجة',
      withdrawalPayFailed: 'فشلت',
      withdrawalSuccess: 'تمت المعالجة',
      withdrawalFailed: 'فشلت',
      withdrawalPending: 'قيد الانتظار',
      withdrawalPartialFailed: 'فشلت',
      transferSubmitted: 'تتم المعالجة',
      transferSuccess: 'تمت المعالجة',
      transferRejected: 'تم الرفض',
      transferFailed: 'فشلت',
      transferProcessing: 'تتم المعالجة',
    },
    formErrorMsg: {
      bankName: 'اسم البنك مطلوب',
      bsb: 'BSB مطلوب الـ',
      beneName: 'مطلوب اسم بنك المستفيد ',
      accNum: ' مطلوب رقم الحساب البنكي ',
      swift: ' مطلوب كود التحويل ',
      bankAddress: 'مطلوب عنوان البنك ',
      holderAddress: 'مطلوب عنوان صاحب الحساب ',
      amt: 'الكمية مطلوبة',
      firstSixDigits: 'ادخل اول ستة ارقام ',
      lastFourDigits: 'ادخل اخر اربعة ارقام',
      branchName: 'مطلوب اسم فرع البنك',
      email: 'ادخل الايميل',
      accName: 'مطلوب اسم الحساب ',
      province: 'منطقة البنك مطلوبة',
      city: 'مدينة البنك مطلوبة',
      bankCard: 'الرجاء اختيار الكرت',
      emailFormat: 'الرجاء ادخال الايميل الصحيح',
      amt0: 'الكمية لايمكن ان تكون 0',
      ifscCode: 'مطلوب رمز الـ IFSC',
      bitcoin: 'عنوان محفظة بيتكوين مطلوب',
      addressLength: 'يجب أن يكون عنوان محفظة البيتكوين (Bitcoin) بين 26 و 42 رمزاً.',
      usdt: 'مطلوب عنوان محفظة USDT',
    },
    transfer: {
      info:
        'ستسدد {platform} الرسوم المصرفية المفروضة على عملية سحب <u> واحدة </ u> شهريًا. عمليات السحب الإضافية خلال الشهر ستتحمل رسومًا بنكية قدرها 20 وحدة من عملة السحب الخاصة بك.',
      label: {
        upload: 'يرجى تحميل نسخة من كشف حسابك المصرفي (الحالي إلى آخر 3 أشهر).',
      },
      formValidation: { upload: 'يرجى تحميل كشف حساب بنكي' },
    },
    transitNumber: 'رقم العبور',
    institutionNumber: 'رقم المؤسسة',
  },
  rebate: { status: { processed: 'تمت المعالجة', processing: 'تتم المعالجة', rejected: 'تم الرفض' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'طلب تحويل العمولة المرتجعة',
    transferAmountIsGreaterThanAvailableBalance: 'مبلغ التحويل الخاص بك أكبر من الرصيد المتاح',
    yourRebateTransferHasBeenProcessed: 'تم طلب تحويل العمولة المرتجعة',
    PleaseEnterTransferInformation: 'الرجاء إدخال معلومات نقل دقيقة',
    tradingAccount: 'حساب التداول',
    rebateAccount: 'حساب العمولة المرتجعة',
    account: 'الحساب  ',
    blackList: 'لقد حُظِر على هذا الحساب إجراء التحويلات بين الحسابات، يرجى التواصل مع {supportEmail}',
  },
  common: {
    liveChat: { desc: 'تحتاج مساعدة ؟ دردشة مباشرة' },
    lang: {
      english: 'الإنجليزية',
      chinese: 'الصينية',
      french: 'الفرنسية',
      thai: 'التايلندية',
      german: 'الالمانية',
      spanish: 'الاسبانية',
      malay: 'الملايو',
      viet: 'الفيتنامية',
      indo: 'الاندونيسية',
      arabic: 'العربية',
    },
    button: { confirm: 'تاكيد', cancel: 'الغاء', sendCode: 'إرسال الرمز', resendCode: 'أعد إرسال الرمز' },
    withdrawChannel: {
      banktransfer: 'تحويل بنكي',
      aus: 'تحويل بنكي - استراليا',
      int: 'تحويل بنكي - دولي',
      chn: 'تحويل بنكي - الصين',
      skrill: 'Skrill   ',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'تحويل Union pay',
      fasapay: 'FasaPay',
      creditcard: 'بطاقة ائتمان',
      bpay: 'Bpay',
      creditdebitcard: 'بطاقة ائتمان /  كريديت',
      bankwiretransferinternational: 'التحويل البنكي (دولي)',
      banktransferaustralia: 'التحويل بنكي (أستراليا)',
      banktransferinternational: 'تحويل بنكي (دولي)',
      banktransferchina: 'تحويل بنكي (دولي)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'وسيط لتحويل وسيط',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'الخدمات المصرفية عبر الإنترنت (تايلاند)',
      internetbankingnigeria: 'الخدمات المصرفية عبر الإنترنت (نيجيريا)',
      internetbankingvietnam: 'الخدمات المصرفية عبر الإنترنت (فيتنام)',
      internetbankingmalaysia: 'الخدمات المصرفية عبر الإنترنت (ماليزيا)',
      internetbankingindonesia: 'الخدمات المصرفية عبر الإنترنت (إندونيسيا)',
      internetbankingkorea: 'الخدمات المصرفية عبر الإنترنت (كوريا)',
      internetbankingindia: 'الخدمات المصرفية عبر الإنترنت (الهند)',
      internetbankingphilippine: 'الخدمات المصرفية عبر الإنترنت (الفلبين)',
      internetbankingsouthafrica: 'الخدمات المصرفية عبر الإنترنت (جنوب إفريقيا)',
      internetbankinguganda: '(اوغانا) الخدمات المصرفية عبر الإنترنت',
      internetbankingrwanda: '(رواندا) الخدمات المصرفية عبر الإنترنت',
      internetbankingzambia: '(زامبيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingcongo: '(كونغو) الخدمات المصرفية عبر الإنترنت',
      internetbankingcameroon: '(كاميرون) الخدمات المصرفية عبر الإنترنت',
      internetbankingburundi: '(بوروندي) الخدمات المصرفية عبر الإنترنت',
      internetbankingkenya: '(كينيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingghana: '(غانا) الخدمات المصرفية عبر الإنترنت',
      internetbankingtanzania: '(تانزانيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingcanada: '(كندا) الخدمات المصرفية عبر الإنترنت',
      skrillneteller: 'skrill / Neteller',
      vietnambanktransfer: 'فيتنام التحويل المصرفي',
      nigeriabanktransfer: 'نيجيريا التحويل المصرفي',
      polipayment: 'الدفع بالـ poli',
      bankwiretransferaustralia: 'التحويل البنكي (أستراليا)',
      thailandinstantbankwiretransfer: 'تحويل بنكي مباشر لتايلاند',
      malaysiainstantbankwiretransfer: 'تحويل بنكي مباشر لماليزيا',
      banktransferbpaypolipay: 'تحويل بنكي /BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'عملة رقمية - بتكوين',
      cryptocurrencyusdt: 'عملة رقمية - USDT',
      nigeriainstantbankwiretransfer: 'تحويل بنكي مباشر لنيجيريا',
      vietnaminstantbankwiretransfer: 'تحويل بنكي مباشر لفيتنام',
      indonesiainstantbankwiretransfer: 'تحويل بنكي مباشر لاندونيسيا',
      philippinesinstantbankwiretransfer: 'التحويل البنكي الفوري الفلبيني',
      southafricainstantbankwiretransfer: 'التحويل البنكي الفوري لجنوب أفريقيا',
      indiainstantbanktransfer: ' تحويل بنكي مباشر للهند',
      southkoreabanktransfer: 'التحويل البنكي الفوري لبنك كوريا الشمالية',
      ugandabanktransfer: 'تحويل بنك محلي  اوغاندا',
      rwandabanktransfer: 'تحويل بنك محلي رواندا',
      zambiabanktransfer: 'تحويل بنك محلي زامبيا',
      congobanktransfer: 'تحويل بنك محلي الكونغو',
      cameroonbanktransfer: 'تحويل بنك محلي الكاميرون',
      burundibanktransfer: 'تحويل بنك محلي بوروندي',
      kenyabanktransfer: 'تحويل بنك محلي كينيا',
      ghanabanktransfer: 'تحويل بنك محلي غانا',
      tanzaniabanktransfer: 'تحويل بنك محلي تانزانيا',
      banktransferbpaypolipayaustralia: 'التحويل المصرفي / BPay / PoliPay (أستراليا)',
      banktransferbpaypolipayinternational: 'التحويل المصرفي / BPay / PoliPay (دوليًا)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'التحويل المصرفي الكندي المحلي',
      cryptobitcoin: 'بيتكوين - كريبتو',
      cryptotetheromni: 'العملة الرقمية Tether(OMNI)',
      cryptotethererc20: 'العملة الرقمية Tether(ERC20)',
      cryptotethertrc20: 'العملة الرقمية Tether(TRC20)',
      astropay: 'Astropay',
      vload: 'Vload',
      sticpay: 'SticPay',
      transfer: 'تحويل الى حسابات',
      E_WALLET: 'المحفظة الإلكترونية',
      CRYPTOCURRENCY: 'عملة مشفرة',
      LOCAL_TRANSFER: 'النقل المحلي',
    },
    country: { international: 'دولي', australia: 'استراليا' },
    field: {
      country_region: 'البلد / المنطقة',
      address: 'العنوان ',
      phone: 'رقم الهاتف',
      nat: 'الجنسية',
      country: 'بلد الاقامة',
      save: 'استثمار',
      annIncome: 'الدخل السنوي',
      emailAdd: 'البريد الالكتروني',
      yes: 'نعم',
      no: 'لا',
      enterVerificationCode: 'أدخل رمز التحقق',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'ارسال',
      FROM: 'من ',
      UPDATE: 'التحديث',
      TO: 'الى',
      DATE: 'التاريخ',
      pleaseSelect: 'الرجاء الاختيار',
      amount: 'الكمية',
      rebateAccount: 'حساب العمولة المرتجعة',
      comingSoon: 'قريبا',
      tradingHistory: 'سجل التداول',
      noData: 'لايوجد بيانات',
      NAME: 'الاسم',
      EMAIL: 'الايميل',
      ACCTYPE: 'نوع الحساب',
      PLATFORM: 'المنصة',
      BASECURRENCY: 'العملة الاساسية',
      DATERANGE: 'معدل التاريخ',
      STATUS: 'الحالة',
      DEST: 'المسافة',
      ACCNUM: 'رقم الحساب',
      BALANCE: 'الرصيد المتوفر',
      PROFITLOST: 'الربح',
      MARGINLVL: 'مستوى الهامش',
      ACCEQUITY: 'رأس مال الحساب',
      CREDIT: 'الإئتمان',
      NONE: 'بدون',
      ALL: 'الكل',
      actualAmonut: 'الكمية الفعلية.',
      currency: 'العملة',
      method: 'طريقة',
    },
    dateRange: {
      today: 'اليوم',
      yesterday: 'البارحة',
      lastSevenDays: 'اخر 7 ايام',
      lastThirtyDays: 'اخر 30 يوم',
      weekToDate: 'اسبوع ليوم',
      monthToDate: 'الشهر ليوم',
      quarterToDate: 'ربع ليوم',
      previousWeek: 'الاسبوع السابق',
      previousMonth: 'الشهر السابق',
      previousQuarter: 'الربع السابق',
    },
    products: { forex: 'سوق العملات', commodities: 'السلع', indices: 'المؤشرات', crypto: 'العملات الرقمية' },
    formValidation: {
      dynamicReq: '{dynamic} مطلوب',
      verCodeReq: 'رمز التحقق مطلوب',
      alphanumericReq: 'الرجاء إدخال أبجدي رقمي',
    },
  },
  report: {
    title: { rebateReport: 'تقرير العمولة المرتجعة' },
    rebate: {
      column: {
        name: 'الاسم',
        accountNumber: 'رقم الحساب',
        accountType: 'نوع الحساب',
        volume: 'الحجم',
        rebate: 'العمولة المرتجعة',
        totalRebate: 'مجموع العمولة المرتجعة',
        date: 'التاريخ',
        instrument: 'أداة',
      },
      dropdown: { allInstruments: 'جميع الادوات', all: 'الكل', instruments: 'الادوات ' },
      others: {
        rebate: 'العمولة المرتجعة',
        instrumentsTraded: 'ادوات تم تداولها',
        lots: 'اللوتات',
        totalRebate: 'مجموع العمولة المرتجعة',
        rebates: 'العمولة المرتجعة',
      },
    },
  },
  ibReport: {
    header: 'تقرير الـ IB',
    netFunding: 'صافي التمويل',
    deposits: 'الايداع',
    withdraw: 'السحب',
    openedAccs: 'فتح حساب',
    funding: 'تمويل الحساب',
    withdrawals: 'السحوبات',
    appliedAmount: 'المبلغ المطبق',
    depositAmount: 'قيمة الايداع',
    withdrawalAmount: 'كمية السحب',
  },
  lead: { header: 'عملاء محتملون', ibAccount: 'حساب IB', demoAccounts: 'حسابات تجريبية' },
  pendingAccounts: {
    header: 'حسابات في الانتظار',
    errorDoc:
      'قام المتقدمون التاليون بملئ نموذج الطلب عبر الإنترنت ولكنهم لم يقدموا بعد إثبات الهوية (ID) من أجل تفعيل حساباتهم.',
    acceptedDocType: 'تشمل نماذج إثبات الهوية المقبولة ما يلي:',
    primaryDoc: 'الهوية الاساسية',
    passport: 'نسخة من جواز سفرك الدولي ساري المفعول (صفحة التوقيع)',
    id: 'نسخة من بطاقة الهوية الوطنية الصادرة عن حكومتك',
    dl: 'نسخة من رخصة قيادتك سارية المفعول',
    address: 'الهوية الثانية',
    addressList: [
      'نسخة من فاتورة مرافق حديثة (لا يزيد عمرها عن 6 أشهر)',
      'نسخة من كشف حساب بطاقة الائتمان (لا يزيد عن 6 أشهر)',
      'نسخة من كشف حساب مصرفي (لا يزيد عن 6 أشهر)',
    ],
    tip: 'يرجى تشجيع المتقدمين على تقديم نسخة واحدة من وثائق الهوية الأساسية والثانوية المقبولة إلى',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'سجل التحويلات',
    rebateHistory: 'العمولة المرتجعة',
    withdrawHistory: 'السحوبات',
    transferHistory: 'التحويلات',
    cancelWithdraw: `هل ترغب في إلغاء طلب السحب؟`,
    cancelError: `تتم معالجة عملية السحب الخاصة بك في الوقت الحالي. لذلك ، لا يمكننا قبول طلب الإلغاء الخاص بك. لمزيد من المعلومات ، يمكنك التواصل معنا باستخدام ميزة الدردشة الحية.`,
  },
  fileUploader: {
    supportedFormat: 'أنواع الملفات المدعومة: png ، jpg ، jpeg ، bmp ، pdf ، doc ، docx',
    maxSize: 'الحد الأقصى لحجم ملف التحميل: {maxSize} ميغا بايت',
    maxFileNum: 'أقصى رقم للملف: {maxFileNum}',
    selectFile: 'اختيار ملف',
  },
  transferHistory: {
    payStatus: { processing: 'تتم المعالجة', paid: 'تم الدفع ', unpaid: 'لم يتم الدفع', rejected: 'تم الرفض' },
  },
  referralLinks: {
    header: 'روابط الدعوة',
    ibAcc: 'حساب IB',
    campLang: 'اللغة ',
    cpy: 'نسخ',
    alreadyCPY: 'تم النسخ !',
    liveAcc: 'حساب حقيقي',
    demoAcc: 'حساب تجريبي',
    homePage: 'الصفحة الرئيسية',
    Share: 'مشاركة',
  },
  settings: { ibProfile: 'الملف الشخصي للوسيط الـ IB', info: 'معلومات', reg: 'تم التسجيل في' },
  myProfile: { changeDetails: 'إذا كنت ترغب في تحديث تفاصيل ملفك الشخصي الحالي ، فلا تتردد في الاتصال بـ {mailTo}' },
  unfundedAcc: {
    header: 'حسابات غير ممولة',
    allActivationAccs: 'الحسابات النشطة',
    neverFundedAccs: 'لم يتم تمويلها مطلقًا',
    prevFundedZero: 'الرصيد صفر',
  },
  multiib: { level: 'المستوى {level}', noib: 'لا يوجد وسطاء (IB) فرعيون ضمن هذا الحساب.' },
  header: { bckToCP: 'عودة إلى بوابة العميل', logout: 'تسجيل الخروج' },
  menu: {
    dashboard: 'اللوحة الرئيسية',
    ibReport: 'تقرير الـ IB',
    rebateReport: 'تقرير العمولة المرتجعة',
    accManagement: 'الحسابات',
    iblevel: 'مستويات الوسطاء الفرعيين',
    ibAccs: 'الحسابات النشطة',
    leads: 'عملاء محتملون',
    pendingAccs: 'حسابات تحت المراجعة',
    unfundedAccs: 'حسابات غير ممولة',
    payHistory: 'سجل التحويلات',
    ibProfile: 'الملف الشخصي للوسيط الـ IB',
    refLinks: 'روابط الدعوة',
    contactUs: 'اتصل بنا',
    switchCP: 'التبديل إلى بوابة العميل',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'تحذير من المخاطر: تداول العقود مقابل الفروقات (CFDs) يحمل درجة عالية من المخاطرة برأس المال الخاص بك ويمكن أن يؤدي إلى خسائر ، يجب أن تتداول فقط بأموال يمكنك تحمل خسارتها. قد لا يكون تداول العقود مقابل الفروقات مناسبًا لجميع المستثمرين ، يرجى التأكد من فهمك الكامل للمخاطر التي تنطوي عليها واتخاذ الإجراءات المناسبة لإدارتها. يرجى قراءة وثيقة الإفصاح عن المخاطر ذات الصلة بعناية.',
  },
  responseMsg: {
    410: 'فشل التحقق من صحة المعامل',
    411: 'كلمة سر خاطئة',
    420: 'المستخدم غير موجود',
    421: 'رفضت عملية إعادة تعيين كلمة المرور',
    490: 'الرجاء عدم تقديم معلومات بطاقة مكررة',
    500: 'حدث خطأ.',
    501: 'فشل التطبيق. الرجاء معاودة المحاولة في وقت لاحق.',
    505: 'فشلت عملية تحميل الملف',
    520: 'فشل تسجيل الدخول. حاول مرة اخرى.',
    521: 'تم تسجيل دخول المستخدم بالفعل على هذا الجهاز!',
    522: 'الرجاد الدخول على الحساب من جديد',
    523: 'الرجاد الدخول على الحساب من جديد',
    524: 'للأسف لم يتم التعرف على عنوان بريدك الإلكتروني',
    525: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    527: 'المستخدم ليس وسيط تقديم',
    528: 'المستخدم غير موجود',
    529: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    540: 'تعذر العثور على حساب التداول الخاص بك',
    541: 'لا يمكن العثور على حساب الخصم',
    542: 'لا يسمح لك بفتح حساب إضافي',
    544: 'لا يُسمح لك بتقديم طلب للحصول على حساب إضافي حتى يتم التحقق من إثبات هويتك',
    550: 'رقم الحساب غير صحيح',
    551: 'رصيدك غير كاف',
    553: 'لا يمكنك السحب / التحويل بسبب الرصيد في حسابك. يرجى الاتصال بـ {email} للحصول على مزيد من المساعدة',
    554: 'المبلغ المطلوب صفر أو فارغ',
    562: 'لم نتمكن من معالجة دفعتك ، يرجى المحاولة مرة أخرى. إذا استمرت المشكلة ، يرجى الاتصال بنا عبر الدردشة المباشرة أو البريد الإلكتروني {email}',
    564: 'البلد لا تتطابق مع قناة الدفع',
    565: 'رمز البنك غير صحيح',
    566: 'طريقة الدفع غير موجودة',
    581: 'لقد نجحت في الاشتراك في الترقية. يرجى ملاحظة أنه يجب عليك عمل إيداع لسحب الأموال.',
    582: 'نظرًا لأنك دخلت الحملة ولديك صفقات مفتوحة ، فلن نتمكن من معالجة طلب السحب الخاص بك.',
    584: 'ليس لديك أي خصومات قابلة للتطبيق',
    590: 'لا يمكن العثور على حساب التحويل',
    591: 'لا يمكن العثور على حساب التحويل',
    593: 'لا يمكننا معالجة تحويل أموالك من الحساب المحدد لأن  حسابك يحتوي على رصيد.',
    594: 'لا يسمح بتحويل الأموال من هذا الحساب',
    595: 'نظرًا لأنك دخلت حملتنا ، فلن نتمكن من معالجة طلب التحويل الخاص بك',
    647: 'رمز التحقق غير صحيح',
    session_timeout: 'انتهت مدة الجلسة ، إعادة التوجيه إلى صفحة تسجيل الدخول.',
  },
}
