export default {
  en: '英語',
  zh: '中文',
  home: {
    NewPortal: '新ポータル',
    SecureIBPortal: '安全なIBポータル',
    DASHBOARD: 'ダッシュボード',
    MARKETING_TOOLS: 'マーケティングツール',
    IB_REPORT: 'IBリポート',
    REBATE_REPORT: 'リベートリポート',
    IB_ACCOUNTS: 'IBアカウント',
    PAYMENT_HISTORY: '取引履歴',
    IB_FAQ: 'IB よくある質問',
    RebateAccountNumber: 'リベート口座番号',
    totalBalance: '総合収支',
    totalCommission: '合計手数料',
    APPLY_FOR_A_REBATE: '適用する',
    availableBalance: '利用可能残高',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '送金',
    MonthtoDate: '過去１カ月間',
    UPDATE: '更新',
    REBATE: 'リベート',
    TOTAL_VOLUME: '出来高合計',
    NET_FUNDING: '純資産',
    DEPOSITS: '預入金',
    WITHDRAW: '出金する',
    OPEND_ACCOUNT: '開設されたアカウント',
    PERFORMANCE: 'パフォーマンス',
    OpendAccount: '開設されたアカウント',
    select_ib: 'IBアカウントを選択してください',
    MOST_TRADED_INSTRUMENTS: '最も取引されている金融証書',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '今月のトップパフォーマンスアカウント',
    RECENTLY_OPENED_ACCOUNTS: '最近開設されたアカウント',
    ACCOUNT_MANAGER: '顧客サポート',
    Account: 'アカウント',
    Name: '名前',
    Volume: '出来高',
    Rebate: 'リベート',
    Balance: 'バランス',
    ContactClient: 'クライアントに連絡',
    YouCannotAskForCommission: "You can't ask for a commission",
    applicationFailedPleaseTryAgain: 'アプリケーションエラーが発生しました、しばらくしてからもう一度お試しください',
    applyRebateSuccessTip:
      'おめでとうございます。リベートリクエストを受け取りました。| {amount} は1営業日以内にリベートアカウント{account} に送金されます。 | リベートが処理されたら、資金を出金したり送金したりできます。',
    HOVER: '前日のリベートは、AESTの毎日09:00～11:00の間に計算および処理されます。',
  },
  liveChat: { header: 'ライブチャット' },
  contactUs: {
    header: 'お問い合わせ',
    followUs: 'フォローする',
    tip:
      ' {platform}の専門的な多言語カスタマーサポートチームは、お客様を支援し、比類のない取引体験の提供を支援する準備ができています。',
  },
  ibAccounts: {
    header: 'IBアカウント',
    ibAccount: 'IBアカウント',
    subIbs: 'サブIBアカウント',
    tradinglist: 'トレーディングリスト',
    openlist: 'オープンリスト',
    closedlist: 'クローズドリスト',
    tradinglistColumn: {
      ticketid: '注文番号',
      opentime: '注文時間',
      type: '取引種別',
      volume: '数量',
      item: '通貨ペア',
      openprice: '始値',
      closeprice: '終値',
      closetime: '決済時間',
      commission: '手数料',
      profit: '損益',
      buy: '買う',
      sell: '売る',
    },
  },
  withdraw: {
    default: {
      selectCC: 'クレジットカードを選択',
      selectBA: '銀行口座を選択',
      anotherCC: 'クレジットカードを手動で入力してください',
      anotherBA: '銀行口座を追加',
      remember: '自分のアカウントを記憶',
      verificationCode:
        '認証コードが{email}へ送信されました。さらにサポートが必要な場合は、{supportEmail}へご連絡ください',
    },
    alert:
      'AML法により、出金に使用する方法は、入金に使用した方法と同じでなければなりません。引き出し金額がアカウントの基本通貨の100単位未満の場合、銀行から手数料として20単位が請求されます。',
    NetellerChargeAlert:
      'Neteller の引き出しは 1 回の取引につき 10,000 ドルに制限され、2% の手数料 (上限は 30 ドル) が適用されます。 ',
    FasaPayChargeAlert: 'FasaPayは、引き出しごとに0.5％の取引手数料を請求します。',
    SkrillChargeAlert: 'Skrillは、引き出しごとに1％の取引手数料を請求します。',
    cryptoAlert:
      '当サイトがBUSDTを介して出入金をできないということにご注意ください。アドレスと仮想通貨が当サイトの取り扱っているチェーンと通貨に一致していることをお確かめください。一致していない場合、その資金が失われることになります。',
    REBATE_WITHDRAW_REQUEST: 'リベート出金リクエスト',
    PleaseSelectDesiredWithdrawMethod: '希望する引き出し方法を選択してください',
    BankName: '銀行名',
    BankAccountsName: '銀行アカウント名',
    FasaPayAccountName: 'FasaPayアカウント名',
    AustralianBankName: 'オーストラリアの銀行名',
    BankAccountNumber: '銀行の口座番号',
    FasaPayAccountNumber: 'FasaPayアカウント番号',
    BankAccountBeneficiary: '銀行口座受取人',
    BSB: 'BSB',
    skrillEmail: 'Skrill Eメール',
    NetellerEmail: 'Neteller Eメール',
    PerfectMoneyEmail: 'Perfect Money Eメール',
    SticpayEmail: 'SticPay Eメール',
    BitcoinEmail: 'Bitcoin ウォレットアドレス',
    BitwalletEmail: 'Bitwallet Eメール',
    USDTEmail: 'Tether ウォレットアドレス',
    BankBranch: '銀行支店',
    Province: '州',
    City: '市',
    BankAddress: '銀行住所',
    bankCity: '銀行の市',
    bankProvince: '銀行の都道府県',
    AccountNumberIBAN: '口座番号/IBAN',
    BankBeneficiaryName: '銀行の受取人名',
    BankAccountBeneficiaryName: '銀行口座受取人名',
    AccountHoldersAddress: '口座名義人の住所',
    SelectWithdrawCard: '出金するカードを選択してください',
    Swift: 'SWIFT',
    SwiftAud: 'SWIFT（オーストラリアアカウントではない場合）',
    ABA_SortCodeABA: 'ABA/ソートコード',
    ImportantNotes: '重要事項',
    YourPaymentGreaterThanAvailableBalance: 'お支払いが利用可能残高を超えています',
    CannotUseMoreThanTwoDecimalValues: '小数点以下2桁以上の数値と負の数値は使用できません',
    YourPaymentIsBeingProcessed: '出金申請が送信されました',
    Itcannotbe0: '0にはできません',
    noCardWarn: '引き出しに利用できるカードがありません、最初に引出しの詳細でカードを登録してください',
    amtLarger: '金額は空白にできません。{minLimit} {currency}以上でなければなりません',
    blackList: `申し訳ありませんが現在お引き出しのリクエストを送信できません。さらなる情報は{supportEmail}へお問い合わせください。`,
    status: {
      withdrawalSubmitted: '提出されました',
      withdrawalCanceled: 'キャンセルされました',
      withdrawalAuditing: '処理中',
      withdrawalRejected: '却下されました',
      withdrawalAccepted: '処理中',
      withdrawalPayFailed: '失敗しました',
      withdrawalSuccess: '処理されました',
      withdrawalFailed: '失敗しました',
      withdrawalPending: '保留中',
      withdrawalPartialFailed: '失敗しました',
      transferSubmitted: '処理中',
      transferSuccess: '処理されました',
      transferRejected: '却下されました',
      transferFailed: '失敗しました',
      transferProcessing: '処理中',
    },
    formErrorMsg: {
      bankName: '銀行名は必須です',
      bsb: 'BSBは必須です',
      beneName: '銀行受取人の名前は必須です',
      accNum: '銀行口座番号は必須です',
      swift: 'Swiftは必須です',
      bankAddress: '銀行の住所は必須です',
      holderAddress: '所有者の住所は必須です',
      amt: '金額は必須です',
      firstSixDigits: '最初の6桁を入力してください',
      lastFourDigits: '下4桁を入力してください',
      branchName: '支店名は必須です',
      email: 'Eメールアドレスは必須です',
      accName: 'アカウント名は必須です',
      province: '銀行の州は必須です',
      city: '銀行の市は必須です',
      bankCard: 'カードを選択してください',
      emailFormat: '正しいメールアドレスを入力してください',
      amt0: '金額を0にすることはできません',
      bitcoin: 'Bitcoinンウォレットアドレスが必須です',
      addressLength: 'ビットコインアドレスは２６～42文字の英数字からなっておりますので、もう一度お試しください。',
      usdt: 'USDTンウォレットアドレスが必須です',
    },
    transfer: {
      info:
        '{platform}は、<u>月に1回の出金</u>にかかる銀行手数料を払い戻します。 その月の追加の出金には、出金通貨の20単位の銀行手数料がかかります。',
      label: { upload: '銀行の明細書のコピー（過去3か月まで）をアップロードしてください' },
      formValidation: { upload: '銀行取引明細書をアップロードしてください' },
    },
    transitNumber: '乗り継ぎ番号',
    institutionNumber: '機関番号',
  },
  rebate: { status: { processed: '処理されました', processing: '処理中', rejected: '却下されました' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'リベート転送要求',
    transferAmountIsGreaterThanAvailableBalance: '送金金額が利用可能な残高を超えています',
    yourRebateTransferHasBeenProcessed: 'リベート転送が送信されました',
    PleaseEnterTransferInformation: '正確な送金情報を入力してください',
    tradingAccount: '取引アカウント',
    rebateAccount: 'リベートアカウント',
    account: 'アカウント',
    blackList: 'こちらの口座には口座間送金の制限がかけられています。詳しくは{supportEmail}までお問い合わせください。',
  },
  common: {
    liveChat: { desc: '助けが必要ですか？ライブチャット' },
    lang: {
      english: '英語',
      chinese: '中国語',
      french: 'フランス語',
      thai: 'タイ語',
      german: 'ドイツ語',
      spanish: 'スペイン語',
      malay: 'マレー語',
      viet: 'ベトナム語',
      indo: 'インドネシア語',
      arabic: 'アラビア語',
    },
    button: { confirm: '確認する', cancel: 'キャンセル', sendCode: 'コードを送信', resendCode: 'コードを再送する' },
    withdrawChannel: {
      banktransfer: '銀行振込',
      aus: '銀行振込 - オーストラリア',
      int: '銀行振込 - 海外',
      chn: '銀行振込 - 中国',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay送金',
      fasapay: 'FasaPay',
      creditcard: 'クレジットカード',
      bpay: 'BPAY',
      creditdebitcard: 'クレジット/デビットカード',
      bankwiretransferinternational: '銀行電信送金（国際）',
      banktransferaustralia: '銀行振込（オーストラリア）',
      banktransferinternational: '銀行振込（国際）',
      banktransferchina: '銀行振込（国際）',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'ブローカーからブローカーへの転送',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'インターネットバンキング（タイ）',
      internetbankingnigeria: 'インターネットバンキング（ナイジェリア）',
      internetbankingvietnam: 'インターネットバンキング（ベトナム）',
      internetbankingmalaysia: 'インターネットバンキング（マレーシア）',
      internetbankingindonesia: 'インターネットバンキング（インドネシア）',
      internetbankingphilippine: 'インターネットバンキング（フィリピン）',
      internetbankingsouthafrica: 'インターネットバンキング（南アフリカ）',
      internetbankingkorea: 'インターネットバンキング（韓国）',
      internetbankinguganda: 'インターネットバンキング (ウガンダ)',
      internetbankingrwanda: 'インターネットバンキング (ルワンダ)',
      internetbankingzambia: 'インターネットバンキング (ザンビア)',
      internetbankingcongo: 'インターネットバンキング (コンゴ)',
      internetbankingcameroon: 'インターネットバンキング (カメルーン)',
      internetbankingburundi: 'インターネットバンキング (ブルンジ)',
      internetbankingkenya: 'インターネットバンキング (ケニア)',
      internetbankingghana: 'インターネットバンキング (ガーナ)',
      internetbankingtanzania: 'インターネットバンキング (タンザニア)',
      internetbankingcanada: 'インターネットバンキング (カナダ)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'ベトナム銀行振込',
      nigeriabanktransfer: 'ナイジェリア銀行振込',
      polipayment: 'Poli支払い',
      bankwiretransferaustralia: '銀行電信送金（オーストラリア）',
      thailandinstantbankwiretransfer: 'タイの即時銀行電信送金',
      malaysiainstantbankwiretransfer: 'マレーシアの即時銀行電信送金',
      banktransferbpaypolipay: '銀行振込/ BPay / /PoliPay',
      cryptocurrencybitcoin: '暗号通貨-Bitcoin',
      cryptocurrencyusdt: '暗号通貨-USDT',
      nigeriainstantbankwiretransfer: 'ナイジェリアの即時銀行電信送金',
      vietnaminstantbankwiretransfer: 'ベトナムの即時銀行電信送金',
      indonesiainstantbankwiretransfer: 'インドネシアの即時銀行電信送金',
      philippinesinstantbankwiretransfer: 'フィリピンの即時銀行電信送金',
      southafricainstantbankwiretransfer: '南アフリカの即時銀行電信送金',
      indiainstantbanktransfer: 'インド即時銀行電信送金',
      southkoreabanktransfer: '韓国インスタント銀行電信送金',
      ugandabanktransfer: 'ウガンダ国内銀行送金',
      rwandabanktransfer: 'ルワンダ国内銀行送金',
      zambiabanktransfer: 'ザンビア国内銀行送金',
      congobanktransfer: 'コンゴ国内銀行送金',
      cameroonbanktransfer: 'カメルーン国内銀行送金',
      burundibanktransfer: 'ブルンジ国内銀行送金',
      kenyabanktransfer: 'ケニア国内銀行送金',
      ghanabanktransfer: 'ガーナ国内銀行送金',
      tanzaniabanktransfer: 'タンザニア国内銀行送金',
      banktransferbpaypolipayaustralia: '銀行振込/ BPay / PoliPay （オーストラリア）',
      banktransferbpaypolipayinternational: '銀行振込/ BPay / PoliPay （国際）',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'カナダの国内銀行送金',
      cryptobitcoin: '暗号通貨-Bitcoin',
      cryptotetheromni: '暗号通貨-Tether(OMNI)',
      cryptotethererc20: '暗号通貨-Tether(ERC20)',
      cryptotethertrc20: '暗号通貨-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'アカウントに転送する',
      E_WALLET: '電子財布',
      CRYPTOCURRENCY: '暗号通貨',
      LOCAL_TRANSFER: 'ローカル転送',
    },
    country: { international: '海外', australia: 'オーストラリア' },
    field: {
      country_region: '国/地域',
      address: '住所',
      phone: '電話番号',
      nat: '国籍',
      country: '居住国',
      save: '投資',
      annIncome: '年収',
      emailAdd: 'Eメールアドレス',
      yes: 'はい',
      no: 'いいえ',
      enterVerificationCode: '認証コードを入力してください',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: '提出する',
      FROM: 'から',
      UPDATE: '更新',
      TO: 'まで',
      DATE: '日付',
      pleaseSelect: '選択してください',
      amount: '金額',
      rebateAccount: 'リベートアカウント',
      comingSoon: '近日公開',
      tradingHistory: '取引履歴',
      noData: 'データなし',
      NAME: '名前',
      EMAIL: 'Eメール',
      ACCTYPE: 'アカウントタイプ',
      PLATFORM: 'プラットフォーム',
      BASECURRENCY: '基本通貨',
      DATERANGE: '日付範囲',
      STATUS: 'ステータス',
      DEST: '振込先',
      ACCNUM: '口座番号',
      BALANCE: '残高',
      PROFITLOST: '損益',
      MARGINLVL: 'マージンレベル',
      ACCEQUITY: 'アカウントの公平性',
      CREDIT: 'クレジット',
      NONE: 'なし',
      ALL: 'すべて',
      actualAmonut: '実際金額',
      currency: '通貨',
      method: '方法',
    },
    dateRange: {
      today: '今日',
      yesterday: '昨日',
      lastSevenDays: '過去7日間',
      lastThirtyDays: '過去30日間',
      weekToDate: '過去1週間',
      monthToDate: '過去1カ月間',
      quarterToDate: '過去の四半期',
      previousWeek: '前週',
      previousMonth: '前月',
      previousQuarter: '前四半期',
    },
    products: { forex: 'FX', commodities: 'コモディティ', indices: '指標', crypto: '仮想通貨' },
    formValidation: {
      dynamicReq: '{dynamic} は必須です',
      verCodeReq: '認証コードが必要です',
      alphanumericReq: '英数字を入力してください',
    },
  },
  report: {
    title: { rebateReport: 'リベートリポート' },
    rebate: {
      column: {
        name: '名前',
        accountNumber: '口座番号',
        accountType: 'アカウントタイプ',
        volume: '出来高',
        rebate: 'リベート',
        totalRebate: 'リベート合計',
        date: '日付',
        instrument: '金融証書',
      },
      dropdown: { allInstruments: '全金融証書', all: 'すべて', instruments: '金融証書' },
      others: {
        rebate: 'リベート',
        instrumentsTraded: '取引された金融証書',
        lots: 'ロット',
        totalRebate: 'リベート合計',
        rebates: 'リベート',
      },
    },
  },
  ibReport: {
    header: 'IBリポート',
    netFunding: '純資産',
    deposits: '預入金',
    withdraw: '出金する',
    openedAccs: '開設されたアカウント',
    funding: '資金調達',
    withdrawals: '出金する',
    appliedAmount: '申請額',
    depositAmount: '入金額',
    withdrawalAmount: '出金額',
  },
  lead: { header: 'リード', ibAccount: 'IBアカウント', demoAccounts: 'デモ口座' },
  pendingAccounts: {
    header: '保留中のアカウント',
    errorDoc:
      '以下の申請者は、オンライン申請フォームに記入しましたが、アカウントを完成するための身分証明書(ID)をまだ提出していません。',
    acceptedDocType: '使用できるIDの種類は次のとおりです：',
    primaryDoc: '主要ID',
    passport: '有効な国際パスポートのコピー（署名ページ）',
    id: '政府発行の国民IDカード',
    dl: '有効な運転免許証のコピー',
    address: '補助ID',
    addressList: [
      '最近の公共料金請求書のコピー（6か月以内）',
      'クレジットカードの明細書のコピー（6か月以内）',
      '銀行取引明細書のコピー（6か月以内）',
    ],
    tip: '申請者に、承認された主要または補助ID書類のコピー1通を提出するようにしてもらってください',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: '取引履歴',
    rebateHistory: 'リベート履歴',
    withdrawHistory: '出金履歴',
    transferHistory: '転送履歴',
    cancelWithdraw: `出金依頼をキャンセルしますか?`,
    cancelError: `出金は現在処理中です。 そのため、キャンセルのお申し出はお受けできません。 詳細については、ライブ チャット機能を使用してご連絡ください。`,
  },
  fileUploader: {
    supportedFormat: 'サポートされているファイル形式：png、jpg、jpeg、bmp、pdf、doc、docx',
    maxSize: '最大アップロードファイルサイズ：{maxSize} MB',
    maxFileNum: '最大ファイル数：{maxFileNum}',
    selectFile: 'ファイルを選択',
  },
  transferHistory: {
    payStatus: { processing: '処理中', paid: '支払済み', unpaid: '未払い', rejected: '却下されました' },
  },
  referralLinks: {
    header: '参照リンク',
    ibAcc: 'IBアカウント',
    campLang: 'キャンペーン言語',
    cpy: 'コピー',
    alreadyCPY: 'コピーしました！',
    liveAcc: 'ライブアカウント',
    demoAcc: 'デモアカウント',
    homePage: 'ホームページ',
    Share: 'シェア',
  },
  settings: { ibProfile: 'IBプロフィール', info: '情報', reg: 'に登録済み' },
  myProfile: { changeDetails: '既存のプロファイルの詳細を更新したい場合は、{mailTo}までお問い合わせください。' },
  unfundedAcc: {
    header: '資金提供されないアカウント',
    allActivationAccs: '有効化されたすべてのアカウント',
    neverFundedAccs: '決して資金提供されないアカウント',
    prevFundedZero: 'ゼロバランス',
  },
  multiib: {
    level: 'レベル {level}',
    noib: 'このアカウントにはサブIBはありません。',
  },
  header: { bckToCP: 'クライアントポータルに戻る', logout: 'ログアウト' },
  menu: {
    dashboard: 'ダッシュボード',
    ibReport: 'IBリポート',
    rebateReport: 'リベートリポート',
    accManagement: 'アカウント',
    iblevel: 'マルチレベル',
    ibAccs: '活性化されたアカウント',
    leads: 'リード',
    pendingAccs: '保留中のアカウント',
    unfundedAccs: '資金提供されないアカウント',
    payHistory: '取引履歴',
    ibProfile: 'IBプロフィール',
    refLinks: '参照リンク',
    contactUs: 'お問い合わせ',
    switchCP: 'クライアントポータルに切り替える',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'リスク警告：CFD取引はファンドに高いリスクをもたらし、損失を引き起こす可能性があります。損失に耐えることができるファンドとのみ取引する必要があります。 CFD取引は、すべての投資家に適しているとは限りません。関連するリスクを十分に理解し、適切な対策を講じて管理してください。 関連するリスク開示文書を注意深くお読みください。',
  },
  responseMsg: {
    410: 'パラーメータの検証に失敗しました',
    411: '不正なパスワード',
    420: 'ユーザーが存在しません',
    421: 'パスワードのリセットが拒否されました',
    490: '重複したカード情報を提出しないでください',
    500: 'エラーが発生しました。',
    501: 'アプリケーションが失敗しました。後でもう一度やり直してください。',
    505: 'ファイルのアップロードに失敗しました',
    520: 'ログインに失敗しました。もう一度やり直してください。',
    521: 'ユーザーは既にこのデバイスにログインしています！',
    522: 'もう一度ログインしてください',
    523: 'もう一度ログインしてください',
    524: '残念ながらあなたのEメールアドレスは認識されませんでした',
    525: 'ユーザーネームまたはパスワードが違います',
    527: 'ユーザーがIBではありません',
    528: 'ユーザーが存在しません',
    529: 'ユーザーネームまたはパスワードが違います',
    540: '取引口座が見つかりませんでした',
    541: 'リベートアカウントが見つかりません',
    542: '追加のアカウントを開くことは許可されていません',
    544: 'お客様は、身分証明書が認証されるまで追加のアカウントを申請することができません。',
    550: '口座番号が間違っています',
    551: '残高が不足しています',
    553: 'アカウントのクレジットにより、出金・送金できません。さらにサポートが必要な場合は、{email}にお問い合わせください',
    554: 'リクエストされた金額はゼロまたは空白です',
    564: '国名が決済チャネルと一致しません',
    565: '不正な銀行コード',
    566: 'お支払い方法が見つかりません',
    581: 'プロモーションへのオプトインに成功しました。資金を引き出すには、預金をする必要がありますのでご注意ください',
    582: 'あなたはイベントに参加しており、ポジションを持っているため、退会申請を提出することはできません。',
    584: '該当するリベートはありません',
    590: '送金口座が見つかりません',
    591: '入金口座が見つかりません',
    593: 'お客様の口座には預金があるため、選択した口座からの資金送金手続きを行うことができませんでした。',
    594: 'この口座からの資金の送金は禁止されています',
    595: 'あなたは私たちの活動に参加しているので、あなたの転送要求は現在処理できません。',
    647: '認証コードが正しくありません',
    session_timeout: 'セッションタイムアウト、ログインページにリダイレクトします。',
  },
}
