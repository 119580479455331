const { WHITELIST, WHITELIST2 } = require('@/constants/internationalBankTransfer')

module.exports = {
  info: {
    url: 'http://startrader.com/',
    email: 'info@startrader.com',
    accountEmail: 'info@startrader.com',
    referralSite: 'http://startrader.com/',
    blacklistEmail: 'info@startrader.com',
  },
  menus: [
    {
      name: 'menu.dashboard',
      path: '/home',
      icon: 'menu_icon_home',
    },
    {                          
      name: 'menu.ibReport',
      path: '/ibreport',
      icon: 'menu_icon_ib_report',
    },
    {
      name: 'menu.rebateReport',
      path: '/rebatereport',
      icon: 'menu_icon_rebate_report',
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: 'menu_icon_account',
      // children: [
      //   {
      //     name: 'menu.ibAccs',
      //     path: '/ibaccounts',
      //   },
      //   {
      //     name: 'menu.pendingAccs',
      //     path: '/pendingaccounts',
      //   },
      //   {
      //     name: 'menu.leads',
      //     path: '/leads',
      //   },
      // ],
    },
    {
      name: 'menu.payHistory',
      path: '/rebatePaymentHistory',
      icon: 'menu_icon_history',
    },
    {
      name: 'menu.iblevel',
      path: '/iblevel',
      icon: 'menu_icon_diagram',
    },
    {
      name: 'menu.refLinks',
      path: '/referralLinks',
      icon: 'menu_icon_links',
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'menu_icon_contact',
    },
  ],
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
    60: 'Internet Banking (Canada)',
    61: 'Perfect Money',
  },
  internationalBankTransfer: {
    WITHDRAW: {
      ALLOW_ALL: true,
      WHITELIST: WHITELIST,
    },
    WITHDRAW2: {
      ALLOW_ALL: true,
      WHITELIST: WHITELIST2,
    },
  },
}
