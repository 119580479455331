export default {
  en: 'Inglés',
  zh: '中文',
  home: {
    NewPortal: 'Nuevo portal',
    SecureIBPortal: 'Portal IB seguro',
    DASHBOARD: 'PANEL DE CONTROL',
    MARKETING_TOOLS: 'HERRAMIENTAS DE MARKETING',
    IB_REPORT: 'INFORME IB',
    REBATE_REPORT: 'REPORTE DE COMISÍON',
    IB_ACCOUNTS: 'CUENTAS IB',
    PAYMENT_HISTORY: 'HISTORIAL DE TRANSACCIONES',
    IB_FAQ: 'Preguntas frecuentes del IB',
    RebateAccountNumber: 'Número de cuenta de comisíon',
    totalBalance: 'balance total',
    totalCommission: 'comisión total',
    APPLY_FOR_A_REBATE: 'SOLICITE A COMISÍON',
    availableBalance: 'Saldo disponible',
    WITHDRAW_REBATE: 'RETIRAR COMISÍON',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'CUENTA',
    MonthtoDate: 'Mes hasta la fecha',
    UPDATE: 'ACTUALIZAR',
    REBATE: 'COMISÍON',
    TOTAL_VOLUME: 'VOLUMEN TOTAL',
    NET_FUNDING: 'FINANCIAMIENTO NETO',
    DEPOSITS: 'DEPOSITOS',
    WITHDRAW: 'RETIRAR',
    OPEND_ACCOUNT: 'CUENTA ABIERTA',
    PERFORMANCE: 'ACTUACIÓN',
    OpendAccount: 'Cuenta abierta',
    select_ib: 'POR FAVOR SELECCIONE LA CUENTA IB',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MÁS COMERCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CUENTAS DE MAYOR RENDIMIENTO PARA ESTE MES',
    RECENTLY_OPENED_ACCOUNTS: 'CUENTAS RECIENTEMENTE ABIERTAS',
    ACCOUNT_MANAGER: 'CUSTOMER SUPPORT',
    Account: 'Cuenta',
    Name: 'Nombre',
    Volume: 'Volumen',
    Rebate: 'COMISÍON',
    Balance: 'balance ',
    ContactClient: 'Comuníquese con el cliente',
    YouCannotAskForCommission: 'No puedes pedir una comisión',
    applicationFailedPleaseTryAgain: 'La aplicación falló. Vuelve a intentarlo más tarde.',
    applyRebateSuccessTip:
      'Felicitaciones, hemos recibido su solicitud de reembolso. | {amount} se transferirá a la cuenta de reembolso {account} dentro de 1 día hábil. | Puede retirar o transferir fondos una vez que se procese su reembolso.',
    HOVER: 'Las comisíones del día anterior se calculan y procesan entre las 09:00 y las 11:00 AEST todos los días.',
  },
  liveChat: { header: 'Chat en vivo' },
  contactUs: {
    click: 'Pulse',
    assist: ' CONTACTE CON NOSOTROS',
    header: 'CONTÁCTENOS',
    followUs: 'Síguenos',
    tip:
      '{platform} el equipo profesional de atención al cliente multilingüe está listo para ayudarle a ofrecerle una experiencia de negocios sin igual.',
  },
  ibAccounts: {
    header: 'CUENTAS IB',
    ibAccount: 'Cuenta IB',
    subIbs: 'Cuenta Sub IB',
    tradinglist: 'Lista de comercio',
    openlist: 'Lista abierta',
    closedlist: 'Lista cerrada',
    tradinglistColumn: {
      ticketid: 'Identificación de entradas',
      opentime: 'Tiempo abierto',
      type: 'Escribe',
      volume: 'Volumen',
      item: 'Artículo',
      openprice: 'Precio de apertura',
      closeprice: 'Precio de cierre',
      closetime: 'Hora de cierre',
      commission: 'Comisión',
      profit: 'Lucro',
      buy: 'Comprar',
      sell: 'Vender',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Seleccione la tarjeta de crédito',
      selectBA: 'Seleccione la cuenta bancaria',
      anotherCC: 'Introduzca la tarjeta de crédito manualmente',
      anotherBA: 'Añadir cuenta bancaria',
      remember: 'Recuerda mi cuenta',
      verificationCode:
        'El código de verificación ha sido enviado a {email}. Por favor, contacte con {supportEmail} si necesita más ayuda',
    },
    alert:
      'Debido a las leyes de AML, el método que utilice para retirar debe ser el mismo que el utilizado para su depósito. Si la cantidad retirada es inferior a 100 unidades de la moneda base de su cuenta, el banco cobrará una comisión de 20 unidades por su manejo. ',
    NetellerChargeAlert:
      'Los retiros de Neteller están limitados a $ 10,000 por transacción y se aplica una tarifa del 2% (con un límite de $ 30).',
    FasaPayChargeAlert: 'FasaPay cobra una tarifa de transacción del 0,5% por cada retiro.',
    SkrillChargeAlert: 'Skrill cobra una tarifa de transacción del 1% por cada retiro.',
    cryptoAlert:
      'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
    REBATE_WITHDRAW_REQUEST: 'Solicitar la retirada de la comisión',
    PleaseSelectDesiredWithdrawMethod: 'Seleccione el método de retiro deseado',
    BankName: 'Nombre del banco',
    BankAccountsName: 'Nombre de cuentas bancarias',
    FasaPayAccountName: 'Nombre de la cuenta de FasaPay',
    AustralianBankName: 'Nombre del banco australiano',
    BankAccountNumber: 'Número de cuenta bancaria',
    FasaPayAccountNumber: 'Número de cuenta de FasaPay',
    BankAccountBeneficiary: 'Beneficiario de la cuenta bancaria',
    BSB: 'BSB',
    skrillEmail: 'Correo electrónico de Skrill',
    SticpayEmail: 'SticPay Email',
    NetellerEmail: 'Correo electrónico de Neteller',
    PerfectMoneyEmail: 'Correo electrónico de Perfect Money',
    BitwalletEmail: 'Bitwallet Email',
    BitcoinEmail: 'Dirección de la cartera de Bitcoin',
    USDTEmail: 'Tether Wallet Address',
    BankBranch: 'Sucursal bancaria',
    Province: 'Provincia',
    City: 'Ciudad',
    BankAddress: 'Dirección del banco',
    bankCity: 'Ciudad del banco',
    bankProvince: 'Provincia del banco',
    AccountNumberIBAN: 'Número de cuenta / IBAN',
    BankBeneficiaryName: 'Nombre del beneficiario del banco',
    BankAccountBeneficiaryName: 'Nombre del beneficiario de la cuenta bancaria',
    AccountHoldersAddress: 'Dirección del titular de la cuenta',
    SelectWithdrawCard: 'Seleccione su tarjeta de retiro',
    Swift: 'Swift',
    ifscCode: 'IFSC Code',
    SwiftAud: 'Swift (cuentas sin AUD)',
    ABA_SortCodeABA: 'ABA / Código de clasificación',
    ImportantNotes: 'Notas importantes',
    YourPaymentGreaterThanAvailableBalance: 'Su pago es mayor que el saldo disponible',
    CannotUseMoreThanTwoDecimalValues: 'No puede usar números y números negativos con más de dos lugares decimales',
    YourPaymentIsBeingProcessed: 'Su solicitud de retiro ha sido enviada',
    Itcannotbe0: 'No puede ser 0',
    noCardWarn:
      'No tiene ninguna tarjeta disponible para realizar retiros, primero vincule su tarjeta en Detalles del retiro',
    amtLarger: 'La cantidad no puede ser nula y debe ser mayor o igual a {minLimit} {currency}',
    blackList: `Desafortunadamente actualmente no puede solicitar una retirada. Por favor, póngase en contacto con {supportEmail} para más información`,
    status: {
      withdrawalSubmitted: 'Presentada',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Procesando',
      withdrawalRejected: 'Rechazado',
      withdrawalAccepted: 'Procesando',
      withdrawalPayFailed: 'Ha fallado',
      withdrawalSuccess: 'Procesada',
      withdrawalFailed: 'Ha fallado',
      withdrawalPending: 'Pendiente',
      withdrawalPartialFailed: 'Ha fallado',
      transferSubmitted: 'Procesando',
      transferSuccess: 'Procesada',
      transferRejected: 'Rechazado',
      transferFailed: 'Ha fallado',
      transferProcessing: 'Procesando',
    },
    formErrorMsg: {
      bankName: 'Se requiere el nombre del banco',
      bsb: 'Se requiere BSB',
      beneName: 'Se requiere el nombre del beneficiario del banco',
      accNum: 'Se requiere el número de cuenta bancaria',
      swift: 'Se requiere Swift',
      bankAddress: 'Se requiere la dirección del banco',
      holderAddress: 'Se requiere la dirección del titular',
      amt: 'Se requiere la cantidad',
      firstSixDigits: 'Ingrese los primeros 6 dígitos',
      lastFourDigits: 'Ingrese los últimos 4 dígitos',
      branchName: 'El nombre de la sucursal es obligatorio',
      email: 'correo electronico es requerido',
      accName: 'Se requiere el nombre de la cuenta',
      province: 'La provincia del banco es obligatoria',
      city: 'La ciudad del banco es obligatoria',
      bankCard: 'Por favor seleccione una tarjeta',
      emailFormat: 'Por favor, introduzca una dirección de email correcta',
      amt0: 'La cantidad no puede ser 0',
      ifscCode: 'Se requiere IFSC',
      bitcoin: 'La dirección de la cartera de Bitcoin es obligatoria',
      addressLength: 'La dirección de la cartera de Bitcoin debe tener entre 26 y 42 caracteres',
      usdt: 'La dirección de la cartera de USDT es obligatoria',
    },
    transfer: {
      info:
        '{platform} reembolsará la comisión bancaria cobrada por <u>UNA</u> retirada al mes. Las retiradas adicionales durante el mes tendrán en una comisión bancaria de 20 unidades de su divisa de retirada.',
      label: {
        upload:
          'Por favor, envíe una copia de su extracto bancario (actualizado con una antigüedad no superior a 3 meses)',
      },
      formValidation: {
        upload: 'Por favor, envíe un extracto bancario',
      },
    },
    transitNumber: 'Número de tránsito',
    institutionNumber: 'Número de institución',
  },
  rebate: { status: { processed: 'Procesada', processing: 'Procesando', rejected: 'Rechazado' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'SOLICITUD DE TRANSFERENCIA DE COMISIÓN',
    transferAmountIsGreaterThanAvailableBalance: 'El monto de su transferencia es mayor que el saldo disponible',
    yourRebateTransferHasBeenProcessed: 'su transferencia de reembolso ha sido enviada',
    PleaseEnterTransferInformation: 'Ingrese información de transferencia precisa',
    tradingAccount: 'Cuenta de operaciones',
    rebateAccount: 'Cuenta de comisíon',
    account: 'Cuenta',
    blackList:
      'Esta cuenta ha sido restringida por hacer una transferencia entre cuentas, por favor, póngase en contacto en {supportEmail}',
  },
  common: {
    liveChat: { desc: '<li><p>¿Necesita ayuda?</p><span>Chat en vivo</span></li>' },
    lang: {
      english: 'Inglés',
      chinese: 'chino',
      french: 'francés',
      thai: 'tailandés',
      german: 'alemán',
      spanish: 'Español',
      malay: 'malayo',
      viet: 'vietnamita',
      indo: 'indonesio',
      arabic: 'Arábica',
    },
    button: { confirm: 'CONFIRMAR', cancel: 'CANCELAR', sendCode: 'Enviar código', resendCode: 'Reenviar codigo' },
    withdrawChannel: {
      banktransfer: 'Transferencia bancaria',
      aus: 'Transferencia bancaria - Australia',
      int: 'Transferencia bancaria - Internacional',
      chn: 'Transferencia bancaria - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Pago sindical',
      unionpaytransfer: 'Transferencia UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Tarjeta de crédito',
      bpay: 'Bpay',
      creditdebitcard: 'Tarjeta de crédito/débito',
      bankwiretransferinternational: 'Transferencia bancaria (Internacional)',
      banktransferaustralia: 'Transferencia bancaria (Australia)',
      banktransferinternational: 'Transferencia bancaria (Internacional)',
      banktransferchina: 'Transferencia bancaria (Internacional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferencia de Broker a Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banca online (Tailândia)',
      internetbankingnigeria: 'Banca online (Nigéria)',
      internetbankingvietnam: 'Banca online (Vietname)',
      internetbankingmalaysia: 'Banca online (Malásia)',
      internetbankingindonesia: 'Banca online (Indonésia)',
      internetbankingkorea: 'Banca online (Corea)',
      internetbankingindia: 'Banca online (India)',
      internetbankingphilippine: 'Banca online (Philippine)',
      internetbankingsouthafrica: 'Banca online (Sudáfrica)',
      internetbankinguganda: 'Banca online (Uganda)',
      internetbankingrwanda: 'Banca online (Ruanda)',
      internetbankingzambia: 'Banca online (Zambia)',
      internetbankingcongo: 'Banca online (Congo)',
      internetbankingcameroon: 'Banca online (Camerún)',
      internetbankingburundi: 'Banca online (Burundi)',
      internetbankingkenya: 'Banca online (Kenia)',
      internetbankingghana: 'Banca online (Ghana)',
      internetbankingtanzania: 'Banca online (Tanzania)',
      internetbankingcanada: 'Banca online (Canadá)',
      skrillneteller: 'Skrill / Neteller',
      polipayment: 'Pagamento con Poli',
      bankwiretransferaustralia: 'Transferencia bancaria (Australia)',
      thailandinstantbankwiretransfer: 'Transferencia bancaria instantánea de Tailandia',
      malaysiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Malasia',
      banktransferbpaypolipay: 'Transferencia bancaria / BPay / PoliPay',
      nigeriainstantbankwiretransfer: 'Transferencia bancaria instantánea de Nigeria',
      vietnaminstantbankwiretransfer: 'Transferencia bancaria instantánea de Vietnam',
      indonesiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Indonesia',
      philippinesinstantbankwiretransfer: 'Transferencia bancaria instantánea de Philippine',
      southafricainstantbankwiretransfer: 'Transferencia bancaria instantánea de Sudáfrica',
      indiainstantbanktransfer: 'Transferencia bancaria instantánea de India',
      southkoreabanktransfer: 'Transferencia bancaria instantánea de Corea del Sur',
      ugandabanktransfer: 'Transferencia bancaria local de Uganda',
      rwandabanktransfer: 'Transferencia bancaria local de Ruanda',
      zambiabanktransfer: 'Transferencia bancaria local de Zambia',
      congobanktransfer: 'Transferencia bancaria local del Congo',
      cameroonbanktransfer: 'Transferencia bancaria local de Camerún',
      burundibanktransfer: 'Transferencia bancaria local de Burundi',
      kenyabanktransfer: 'Transferencia bancaria local de Kenia',
      ghanabanktransfer: 'Transferencia bancaria local de Ghana',
      tanzaniabanktransfer: 'Transferencia bancaria local de Tanzania',
      banktransferbpaypolipayaustralia: 'Transferencia bancaria/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transferencia bancaria/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptodivisa-Bitcoin',
      cryptotetheromni: 'Criptodivisa-Tether(OMNI)',
      cryptotethererc20: 'Criptodivisa-Tether(ERC20)',
      cryptotethertrc20: 'Criptodivisa-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptodivisa-Bitcoin',
      cryptocurrencyusdt: 'Criptodivisa-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'Transferencia a las cuentas',
      E_WALLET: 'CARTERA ELECTRÓNICA',
      CRYPTOCURRENCY: 'CRIPTOMONEDA',
      LOCAL_TRANSFER: 'TRASLADO LOCAL',
    },
    country: { international: 'Internacional', australia: 'Australia' },
    field: {
      country_region: 'País / Región',
      address: 'Direccíon',
      phone: 'Número de teléfono',
      nat: 'Nacionalidad',
      country: 'País de residencia',
      save: 'Inversión',
      annIncome: 'Ingresos anuales',
      emailAdd: 'Dirección de email',
      yes: 'Sí',
      enterVerificationCode: 'Introducir código de verificación',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DESDE',
      UPDATE: 'ACTUALIZAR',
      TO: 'HASTA',
      DATE: 'FECHA',
      pleaseSelect: 'Por favor seleccione',
      amount: 'Cantidad',
      rebateAccount: 'Cuenta de comisíon',
      comingSoon: 'En breve',
      tradingHistory: 'HISTORIAL COMERCIAL',
      noData: 'Sin datos',
      NAME: 'NOMBRE',
      EMAIL: 'CORREO ELECTRÓNICO',
      ACCTYPE: 'TIPO DE CUENTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MONEDA BASE',
      DATERANGE: 'RANGO DE FECHAS',
      STATUS: 'ESTADO',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DE CUENTA',
      BALANCE: 'SALDO',
      PROFITLOST: 'LUCRO',
      MARGINLVL: 'NIVEL DE MARGEN',
      ACCEQUITY: 'PATRIMONIO DE LA CUENTA',
      CREDIT: 'CRÉDITO',
      NONE: 'Ninguno',
      ALL: 'Todas',
      actualAmonut: 'cantidad real',
      currency: 'DIVISA',
      method: 'MÉTODO',
    },
    dateRange: {
      today: 'Hoy',
      yesterday: 'Ayer',
      lastSevenDays: 'Los últimos 7 días',
      lastThirtyDays: 'Últimos 30 días',
      weekToDate: 'Semana hasta la fecha',
      monthToDate: 'Mes hasta la fecha',
      quarterToDate: 'Trimestre hasta la fecha',
      previousWeek: 'Semana pasada',
      previousMonth: 'Mes anterior',
      previousQuarter: 'Trimestre anterior',
    },
    products: { forex: 'FOREX', commodities: 'COMMODITIES', indices: 'ÍNDICES', crypto: 'CRYPTO' },
    formValidation: {
      dynamicReq: 'El campo {dynamic} es obligatorio',
      verCodeReq: 'El código de verificación es obligatorio',
      alphanumericReq: 'Por favor, introduzca un alfanumérico',
    },
  },
  report: {
    title: { rebateReport: 'REPORTE DE COMISÍON' },
    rebate: {
      column: {
        name: 'NOMBRE',
        accountNumber: 'NÚMERO DE CUENTA',
        accountType: 'TIPO DE CUENTA',
        volume: 'Volumen',
        rebate: 'COMISÍON',
        totalRebate: 'COMISÍON TOTAL',
        date: 'FECHA',
        instrument: 'INSTRUMENTO',
      },
      dropdown: { allInstruments: 'Todos los instrumentos', all: 'Todas', instruments: 'Instrumentos' },
      others: {
        rebate: 'COMISÍON',
        instrumentsTraded: 'INSTRUMENTOS COMERCIADOS',
        lots: 'Lotes',
        totalRebate: 'Reembolso total',
        rebates: 'COMISÍON',
      },
    },
  },
  ibReport: {
    header: 'INFORME IB',
    netFunding: 'FINANCIAMIENTO NETO',
    deposits: 'DEPOSITOS',
    withdraw: 'RETIRAR',
    openedAccs: 'CUENTAS ABIERTAS',
    funding: 'FONDOS',
    withdrawals: 'RETIRAR',
    appliedAmount: 'cantidad aplicada',
    depositAmount: 'Cantidad del depósito',
    withdrawalAmount: 'Cantidad de retiro',
  },
  lead: { header: 'LEADS', ibAccount: 'Cuenta IB', demoAccounts: 'CUENTAS DEMO' },
  pendingAccounts: {
    header: 'CUENTAS PENDIENTES',
    errorDoc:
      'Los siguientes solicitantes han completado el formulario de solicitud en línea, pero aún deben proporcionar una prueba de identificación (ID) para finalizar su cuenta.',
    acceptedDocType: 'Las formas de identificación aceptadas incluyen:',
    primaryDoc: 'ID principal',
    passport: 'Una copia de su pasaporte internacional válido (página de firma)',
    id: 'Una copia de la tarjeta de identidad nacional emitida por su gobierno',
    dl: 'Una copia de su licencia de conducir válida.',
    address: 'Identificación secundaria',
    addressList: [
      'Copia de una factura de servicios públicos reciente (no más de 6 meses)',
      'Copia del extracto de la tarjeta de crédito (no más de 6 meses)',
      'Copia de un extracto bancario (no más de 6 meses)',
    ],
    tip:
      'Anime a los solicitantes a proporcionar una copia de UNO de los documentos de identificación primarios y secundarios aceptados a ',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'HISTORIAL DE TRANSACCIONES',
    rebateHistory: 'COMISÍON',
    withdrawHistory: 'RETIROS',
    transferHistory: 'TRANSFERENCIAS',
    cancelWithdraw: `Le gustaría cancelar la solicitud de retiro?`,
    cancelError: `Su retiro se está procesando en este momento. Por lo tanto, no podemos aceptar su solicitud de cancelación. Para obtener más información, puede comunicarse con nosotros mediante la función de chat en vivo.`,
  },
  fileUploader: {
    supportedFormat: 'Tipos de archivo compatibles: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Tamaño máximo de archivo subido: {maxSize} MB',
    maxFileNum: 'Número máximo de archivos: {maxFileNum}',
    selectFile: 'Seleccionar archivo',
  },
  transferHistory: {
    payStatus: { processing: 'Procesando', rejected: 'Rechazado', paid: 'Pagado', unpaid: 'No pagado' },
  },
  referralLinks: {
    header: 'ENLACES DE REFERENCIA',
    ibAcc: 'Cuenta IB',
    campLang: 'Idioma de la campaña',
    cpy: 'COPIAR',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Cuenta real',
    demoAcc: 'Cuenta demo',
    homePage: 'Página de inicio',
    Share: 'Compartir',
  },
  settings: { ibProfile: 'PERFIL IB', info: 'INFORMACÍON', reg: 'REGISTRADO EN' },
  unfundedAcc: {
    header: 'CUENTAS NO FINANCIADAS',
    allActivationAccs: 'TODAS LAS CUENTAS DE ACTIVACIÓN',
    neverFundedAccs: 'CUENTAS NUNCA FINANCIADAS',
    prevFundedZero: 'SALDO CERO',
  },
  multiib: { level: 'NIVEL {level}', noib: 'No hay sub-IBs en esta cuenta.' },
  header: { bckToCP: 'VOLVER AL PORTAL DEL CLIENTE', logout: 'CERRAR SESIÓN' },
  menu: {
    dashboard: 'PANEL DE CONTROL',
    accManagement: 'CUENTAS',
    ibReport: 'INFORME IB',
    rebateReport: 'REPORTE DE COMISÍON',
    iblevel: 'MULTINIVEL',
    ibAccs: 'CUENTAS ACTIVADAS',
    leads: 'LEADS',
    pendingAccs: 'CUENTAS PENDIENTES',
    unfundedAccs: 'CUENTAS NO FINANCIADAS',
    payHistory: 'HISTORIAL DE TRANSACCIONES',
    ibProfile: 'PERFIL IB',
    refLinks: 'ENLACES DE REFERENCIA',
    contactUs: 'CONTÁCTENOS',
    switchCP: 'CAMBIAR AL PORTAL DEL CLIENTE',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'Advertencia de riesgo: el comercio de contratos por diferencia (CFD) conlleva un alto nivel de riesgo para su capital y puede resultar en pérdidas; solo debe operar con dinero que pueda permitirse perder. El comercio de CFD puede no ser adecuado para todos los inversores, asegúrese de comprender completamente los riesgos involucrados y de tomar las medidas adecuadas para gestionarlos. Lea atentamente el documento de divulgación de riesgos correspondiente.',
  },
  responseMsg: {
    410: 'Error de validación del parámetro',
    411: 'La contraseña no es correcta',
    420: 'El usuario no existe',
    421: 'Restablecimiento de contraseña rechazado',
    490: 'No envíe información de tarjetas duplicadas',
    500: 'Ha ocurrido un error.',
    501: 'Error en su solicitud Por favor, inténtelo de nuevo más tarde.',
    505: 'Error al enviar el archivo',
    520: 'Error al iniciar sesión. Por favor, vuelva a intentarlo.',
    521: '¡Un usuario ya ha iniciado sesión en este dispositivo!',
    522: 'Por favor, inicie sesión de nuevo',
    523: 'Por favor, inicie sesión de nuevo',
    524: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
    525: 'El nombre de usuario y la contraseña no son correctos',
    527: 'El usuario no es un IB',
    528: 'El usuario no existe',
    529: 'El nombre de usuario y la contraseña no son correctos',
    540: 'No se pudo encontrar su cuenta de trading',
    541: 'No se pudo encontrar la cuenta de reembolso',
    542: 'No se le permite abrir cuentas adicionales',
    544: 'No puede solicitar una cuenta adicional hasta que se verifique su prueba de identidad',
    550: 'El número de cuenta no es correcto',
    551: 'No tiene suficiente saldo',
    553: 'No puede retirar/transferir debido al saldo de su cuenta. Por favor, póngase en contacto con {email} para más información',
    554: 'La cantidad solicitada es cero o nula',
    562: 'No fue posible procesar su pago, por favor, inténtelo de nuevo. Si el problema persiste, por favor contáctenos por chat, o por correo electrónico.',
    564: 'El país no coincide con el medio de pago',
    565: 'El código bancario no es correcto',
    566: 'Método de pago no encontrado',
    581: 'Elegiste con éxito el ascenso. Por favor, tenga en cuenta que debe hacer un depósito para poder retirar los fondos.',
    582: 'Como se encuentra en el período de promoción y tiene puestos vacantes, no pudimos procesar su solicitud de retiro.',
    584: 'No tiene ninguna comisíon aplicable',
    590: 'No se pudo encontrar la cuenta de su transferencia saliente',
    591: 'No se pudo encontrar la cuenta de su transferencia entrante',
    593: 'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito.',
    594: 'No se permiten transferencias de fondos desde esta cuenta',
    595: 'Dado que participa en nuestras actividades, su solicitud de transferencia no se puede procesar en este momento.',
    647: 'El código de verificación es incorrecto',
    session_timeout: 'La sesión ha llegado al límite de tiempo, serás redirigido a la página de acceso.',
  },
}
