export default {
  en: 'Inglês',
  zh: '中文',
  home: {
    NewPortal: 'Novo Portal',
    SecureIBPortal: 'Portal seguro do IB',
    DASHBOARD: 'PAINEL DE CONTROLE',
    MARKETING_TOOLS: 'FERRAMENTAS DE MARKETING',
    IB_REPORT: 'Relatório IB ',
    REBATE_REPORT: 'RELATÓRIO DE COMISSÃO',
    IB_ACCOUNTS: 'CONTAS IB',
    PAYMENT_HISTORY: 'HISTÓRICO DE TRANSAÇÕES',
    IB_FAQ: 'IB Perguntas frequentes',
    RebateAccountNumber: 'Número da conta de comissão',
    totalBalance: 'balanço total',
    totalCommission: 'comissão total',
    APPLY_FOR_A_REBATE: 'SOLICITE A COMISSÃO',
    availableBalance: 'saldo disponível',
    WITHDRAW_REBATE: 'RETIRAR COMISSÃO',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'CONTA',
    MonthtoDate: 'Do mês até a data',
    UPDATE: 'ATUALIZAR',
    REBATE: 'COMISSÃO',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    select_ib: 'Conta IB',
    NET_FUNDING: 'FINANCIAMENTO LÍQUIDO',
    DEPOSITS: 'DEPÓSITOS',
    WITHDRAW: 'LEVANTAR',
    OPEND_ACCOUNT: 'CONTA ABERTA',
    PERFORMANCE: 'DESEMPENHO',
    OpendAccount: 'Conta Aberta',
    select_ib: 'SELECIONE A CONTA IB',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MAIS NEGOCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CONTAS COM MELHOR DESEMPENHO PARA ESTE MÊS',
    RECENTLY_OPENED_ACCOUNTS: 'CONTAS ABERTAS RECENTEMENTE',
    ACCOUNT_MANAGER: 'CUSTOMER SUPPORT',
    Account: 'Conta',
    Name: 'Nome',
    Volume: 'Volume',
    Rebate: 'COMISSÃO',
    Balance: 'Saldo',
    ContactClient: 'Cliente de contato',
    YouCannotAskForCommission: 'Você não pode pedir uma comissão',
    applicationFailedPleaseTryAgain: 'O aplicativo falhou, tente novamente mais tarde',
    applyRebateSuccessTip:
      'Parabéns, recebemos sua solicitação de reembolso. | {montante} será transferido para a conta de reembolso {conta} dentro de 1 dia útil. | Você pode sacar ou transferir fundos assim que seu reembolso for processado.',
    HOVER: 'As comissões do dia anterior são calculados e processados ​​diariamente entre 9h e 11h AEST.',
  },
  liveChat: { header: 'Bate-papo ao vivo' },
  contactUs: {
    click: 'Clique',
    assist: ' CONTACTE-NOS',
    header: 'CONTATE-NOS',
    followUs: 'Siga-nos',
    tip:
      '{platform} a equipe profissional multilíngüe de suporte ao cliente está pronta para ajudá-lo e ajudar a proporcionar-lhe uma experiência comercial incomparável.',
  },
  ibAccounts: {
    header: 'CONTAS IB',
    ibAccount: 'Conta IB',
    subIbs: 'Conta Sub IB',
    tradinglist: 'Lista Comercial',
    openlist: 'Lista aberta',
    closedlist: 'Lista Fechada',
    tradinglistColumn: {
      ticketid: 'ID do bilhete',
      opentime: 'Tempo aberto',
      type: 'Modelo',
      volume: 'Volume',
      item: 'Item',
      openprice: 'Preço Aberto',
      closeprice: 'Preço de fechamento',
      closetime: 'Hora de fechamento',
      commission: 'Comissão',
      profit: 'Lucro',
      buy: 'Comprar',
      sell: 'Vender',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
      verificationCode:
        'O código de verificação foi enviado para {email}. Por favor, entre em contacto com {supportEmail} se precisar de mais assistência',
    },
    alert:
      'Devido às leis AML, o método que você usa para retirar deve ser o mesmo que o método usado para seu depósito. Se o valor do saque for inferior a 100 unidades da moeda base de sua conta, será cobrada uma taxa de 20 unidades pelo banco para manuseio.',
    NetellerChargeAlert:
      'As retiradas do Neteller são limitadas a $ 10.000 por transação e aplica-se uma taxa de 2% (limitada a $ 30).',
    FasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
    SkrillChargeAlert: 'Skrill cobra taxa de transação de 1% para cada retirada.',
    cryptoAlert:
      'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
    REBATE_WITHDRAW_REQUEST: 'SOLICITAR LEVANTAMENTO DE COMISSÃO',
    PleaseSelectDesiredWithdrawMethod: 'Selecione o método de levantamento desejado',
    BankName: 'Nome do banco',
    BankAccountsName: 'Nome das contas bancárias',
    FasaPayAccountName: 'Nome da conta FasaPay',
    AustralianBankName: 'Nome do banco australiano',
    BankAccountNumber: 'Número da conta de banco',
    FasaPayAccountNumber: 'Número da conta FasaPay',
    BankAccountBeneficiary: 'Beneficiário da conta bancária',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill',
    SticpayEmail: 'SticPay Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    BitwalletEmail: 'Bitwallet Email',
    BitcoinEmail: 'Endereço da carteira Bitcoin',
    USDTEmail: 'Tether Wallet Address',
    BankBranch: 'Agência Bancária',
    Province: 'Província',
    City: 'Cidade',
    BankAddress: 'Endereço do banco',
    bankCity: 'Cidade do Banco',
    bankProvince: 'Província do Banco',
    AccountNumberIBAN: 'Número da conta / IBAN',
    BankBeneficiaryName: 'Nome do beneficiário do banco',
    BankAccountBeneficiaryName: 'Nome do beneficiário da conta bancária',
    AccountHoldersAddress: 'Endereço do titular da conta',
    SelectWithdrawCard: 'Selecione o seu cartão de retirada',
    Swift: 'Swift',
    ifscCode: 'IFSC Code',
    SwiftAud: 'Swift (contas não AUD)',
    ABA_SortCodeABA: 'ABA / Código de classificação',
    ImportantNotes: 'Notas importantes',
    YourPaymentGreaterThanAvailableBalance: 'Seu pagamento é maior que o saldo disponível',
    CannotUseMoreThanTwoDecimalValues: 'Você não pode usar números e números negativos com mais de duas casas decimais',
    YourPaymentIsBeingProcessed: 'Seu pedido de levantamento foi enviado',
    Itcannotbe0: 'Não pode ser 0',
    noCardWarn:
      'Você não tem nenhum cartão disponível para fazer levantamentos, primeiro vincule seu cartão aos Detalhes de saque',
    amtLarger: 'O valor não pode ser nulo e deve ser maior ou igual a {minLimit} {currency}',
    blackList: `Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações`,
    status: {
      withdrawalSubmitted: 'Submetido',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Em processamento',
      withdrawalRejected: 'Rejeitado',
      withdrawalAccepted: 'Em processamento',
      withdrawalPayFailed: 'Falhou',
      withdrawalSuccess: 'Processado',
      withdrawalFailed: 'Falhou',
      withdrawalPending: 'Pendente',
      withdrawalPartialFailed: 'Falhou',
      transferSubmitted: 'Em processamento',
      transferSuccess: 'Processado',
      transferRejected: 'Rejeitado',
      transferFailed: 'Falhou',
      transferProcessing: 'Em processamento',
    },
    formErrorMsg: {
      bankName: 'Nome do banco é obrigatório',
      bsb: 'BSB é obrigatório',
      beneName: 'O nome do beneficiário do banco é obrigatório',
      accNum: 'O número da conta bancária é obrigatório',
      swift: 'Swift é necessário',
      bankAddress: 'O endereço do banco é obrigatório',
      holderAddress: 'O endereço do titular é obrigatório',
      amt: 'Quantidade é necessária',
      firstSixDigits: 'Digite os 6 primeiros dígitos',
      lastFourDigits: 'Insira os 4 últimos dígitos',
      branchName: 'Nome da filial é obrigatório',
      email: 'E-mail é obrigatório',
      accName: 'O nome da conta é obrigatório',
      province: 'A província do banco é necessária',
      city: 'Cidade do banco é obrigatória',
      bankCard: 'Selecione um cartão',
      emailFormat: 'Por favor, insira o endereço de e-mail correto',
      amt0: 'A quantidade não pode ser 0',
      ifscCode: 'IFSC Code is required',
      bitcoin: 'O endereço da Carteira Bitcoin é obrigatório',
      addressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      usdt: 'O endereço da Carteira USDT é obrigatório',
    },
    transfer: {
      info:
        'A {platform} irá reembolsar a taxa bancária cobrada por <u>UM</u> levantamento por mês. Os levantamentos adicionais dentro do mês incorrem numa taxa bancária de 20 unidades da sua moeda de levantamento.',
      label: {
        upload: 'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
      },
      formValidation: { upload: 'Por favor, faça o envio de um extrato bancário' },
    },
    transitNumber: 'Número de Trânsito',
    institutionNumber: 'Número da Instituição',
  },
  rebate: { status: { processed: 'Processado', processing: 'Em processamento', rejected: 'Rejeitado' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PEDIDO DE TRANSFERÊNCIA DE COMISSÃO',
    transferAmountIsGreaterThanAvailableBalance: 'O valor da sua transferência é maior do que o saldo disponível',
    yourRebateTransferHasBeenProcessed: 'sua transferência de reembolso foi enviada',
    PleaseEnterTransferInformation: 'Insira informações de transferência precisas',
    tradingAccount: 'Conta de negociação',
    rebateAccount: 'Conta de comissão',
    account: 'Conta',
    blackList: 'Esta conta foi restringida de fazer transferências entre contas, por favor, contacte {supportEmail}',
  },
  common: {
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    lang: {
      english: 'Inglês',
      chinese: 'chinês',
      french: 'francês',
      thai: 'tailandês',
      german: 'alemão',
      spanish: 'espanhol',
      malay: 'malaio',
      viet: 'vietnamita',
      indo: 'indonésio',
      arabic: 'árabe',
    },
    button: { confirm: 'CONFIRME', cancel: 'CANCELAR', sendCode: 'Enviar Código', resendCode: 'Reenviar código' },
    withdrawChannel: {
      banktransfer: 'Transferência bancária',
      aus: 'Transferência Bancária - Austrália',
      int: 'Transferência Bancária - Internacional',
      chn: 'Transferência Bancária - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferência UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Cartão de Crédito',
      bpay: 'Bpay',
      creditdebitcard: 'Cartão de Crédito/Débito',
      bankwiretransferinternational: 'Transferência Bancária (Internacional)',
      banktransferaustralia: 'Transferência Bancária (Austrália)',
      banktransferinternational: 'Transferência Bancária (Internacional)',
      banktransferchina: 'Transferência Bancária (Internacional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferência de Corretora para Corretora',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Serviço Bancário Online (Tailândia)',
      internetbankingnigeria: 'Serviço Bancário Online (Nigéria)',
      internetbankingvietnam: 'Serviço Bancário Online (Vietname)',
      internetbankingmalaysia: 'Serviço Bancário Online (Malásia)',
      internetbankingindonesia: 'Serviço Bancário Online (Indonésia)',
      internetbankingkorea: 'Serviço Bancário Online (Coréia)',
      internetbankingindia: 'Serviço Bancário Online (India)',
      internetbankingphilippine: 'Serviço Bancário Online (Philippine)',
      internetbankingsouthafrica: 'Serviço Bancário Online (África do Sul)',
      internetbankinguganda: 'Serviço Bancário Online (Uganda)',
      internetbankingrwanda: 'Serviço Bancário Online (Ruanda)',
      internetbankingzambia: 'Serviço Bancário Online (Zâmbia)',
      internetbankingcongo: 'Serviço Bancário Online (Congo)',
      internetbankingcameroon: 'Serviço Bancário Online (Camarões)',
      internetbankingburundi: 'Serviço Bancário Online (Burundi)',
      internetbankingkenya: 'Serviço Bancário Online (Quênia)',
      internetbankingghana: 'Serviço Bancário Online (Gana)',
      internetbankingtanzania: 'Serviço Bancário Online (Tanzânia)',
      internetbankingcanada: 'Serviço Bancário Online (Canadá)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transferência Bancária do Vietnam',
      nigeriabanktransfer: 'Transferência Bancária da Nigéria',
      polipayment: 'Pagamento com Poli',
      bankwiretransferaustralia: 'Transferência Bancária (Austrália)',
      thailandinstantbankwiretransfer: ' Transferência Bancária Instantânea da Tailândia',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea Malaia',
      banktransferbpaypolipay: 'Transferência Bancária / BPay / PoliPay',
      nigeriainstantbankwiretransfer: 'Transferência Bancária Instantânea Nigeriana',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea Vietnamita',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea da Philippine',
      southafricainstantbankwiretransfer: 'Transferência Bancária Instantânea da África do Sul',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      southkoreabanktransfer: 'Transferência bancária instantânea da Coreia do Sul',
      ugandabanktransfer: 'Transferência de banco local de Uganda',
      rwandabanktransfer: 'Transferência de banco local de Ruanda',
      zambiabanktransfer: 'Transferência de banco local da Zâmbia',
      congobanktransfer: 'Transferência de banco local do Congo',
      cameroonbanktransfer: 'Transferência de banco local de Camarões',
      burundibanktransfer: 'Transferência bancária local do Burundi',
      kenyabanktransfer: 'Transferência bancária local do Quênia',
      ghanabanktransfer: 'Transferência de banco local de Gana',
      tanzaniabanktransfer: 'Transferência de banco local da Tanzânia',
      transfer: 'Transferência para contas',
      banktransferbpaypolipayaustralia: 'Transferência Bancária/BPay/PoliPay (Austrália)',
      banktransferbpaypolipayinternational: 'Transferência Bancária/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptomoeda-Bitcoin',
      cryptotetheromni: 'Criptomoeda-Tether(OMNI)',
      cryptotethererc20: 'Criptomoeda-Tether(ERC20)',
      cryptotethertrc20: 'Criptodivisa-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptomoeda-Bitcoin',
      cryptocurrencyusdt: 'Criptomoeda-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRIPTOCURRÊNCIA',
      LOCAL_TRANSFER: 'TRANSFERÊNCIA LOCAL',
    },
    country: { international: 'Internacional', australia: 'Austrália' },
    field: {
      country_region: 'País / Região',
      address: 'Endereço',
      phone: 'Número de telefone',
      nat: 'Nacionalidade',
      country: 'País de residência',
      save: 'Investimento',
      annIncome: 'Rendimento Anual',
      emailAdd: 'Endereço de E-mail',
      yes: 'Sim',
      no: 'Não',
      enterVerificationCode: 'Insira o Código de Verificação',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DE',
      UPDATE: 'ATUALIZAR',
      TO: 'PARA',
      DATE: 'DATA',
      pleaseSelect: 'Por favor selecione',
      amount: 'Montante',
      rebateAccount: 'Conta de comissão',
      comingSoon: 'em breve',
      tradingHistory: 'HISTÓRIA DE NEGOCIAÇÃO',
      noData: 'Sem dados',
      NAME: 'NOME',
      EMAIL: 'O EMAIL',
      ACCTYPE: 'TIPO DE CONTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MOEDA BASE',
      DATERANGE: 'INTERVALO DE DATA',
      STATUS: 'ESTADO',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DA CONTA',
      BALANCE: 'SALDO',
      PROFITLOST: 'LUCRO',
      MARGINLVL: 'NIVEL DE MARGEN',
      ACCEQUITY: 'PATRIMONIO DE LA CUENTA',
      CREDIT: 'CRÉDITO',
      NONE: 'Nenhum',
      ALL: 'Todos',
      actualAmonut: 'montante atual',
      currency: 'MOEDA',
      method: 'MÉTODO',
    },
    dateRange: {
      today: 'Hoje',
      yesterday: 'Ontem',
      lastSevenDays: 'Últimos 7 dias',
      lastThirtyDays: 'Últimos 30 dias',
      weekToDate: 'Semana até a data',
      monthToDate: 'Do mês até a data',
      quarterToDate: 'Trimestre até a data',
      previousWeek: 'Semana anterior',
      previousMonth: 'Mês anterior',
      previousQuarter: 'Trimestre Anterior',
    },
    products: { forex: 'FOREX', commodities: 'COMMODITIES', indices: 'ÍNDICES', crypto: 'CRIPTO' },
    formValidation: {
      dynamicReq: '{dynamic} é obrigatório',
      verCodeReq: 'O código de verificação é obrigatório',
      alphanumericReq: 'Por favor, insira o alfanumérico',
    },
  },
  report: {
    title: { rebateReport: 'RELATÓRIO DE COMISSÃO' },
    rebate: {
      column: {
        name: 'NOME',
        accountNumber: 'NÚMERO DA CONTA',
        accountType: 'TIPO DE CONTA',
        volume: 'Volume',
        rebate: 'COMISSÃO',
        totalRebate: 'COMISSÃO TOTAL',
        date: 'DATA',
        instrument: 'INSTRUMENTO',
      },
      dropdown: { allInstruments: 'Todos os instrumentos', all: 'Todos', instruments: 'Instrumentos' },
      others: {
        rebate: 'COMISSÃO',
        instrumentsTraded: 'INSTRUMENTOS NEGOCIADOS',
        lots: 'quantidade',
        totalRebate: 'Desconto Total',
        rebates: 'COMISSÃO',
      },
    },
  },
  ibReport: {
    header: 'Relatório IB ',
    netFunding: 'FINANCIAMENTO LÍQUIDO',
    deposits: 'DEPÓSITOS',
    withdraw: 'LEVANTAR',
    openedAccs: 'CONTAS ABERTAS',
    funding: 'FINANCIAMENTO',
    withdrawals: 'LEVANTAR',
    appliedAmount: 'quantidade aplicada',
    depositAmount: 'Valor do depósito',
    withdrawalAmount: 'Quantidade retirada',
  },
  lead: { header: 'LEADS', ibAccount: 'Conta IB', demoAccounts: ' CONTAS DEMO' },
  pendingAccounts: {
    header: 'CONTAS PENDENTES',
    errorDoc:
      'Os seguintes candidatos preencheram o formulário de inscrição online, mas ainda precisam fornecer prova de identificação (ID) para finalizar sua conta.',
    acceptedDocType: 'As formas de identificação aceitas incluem:',
    primaryDoc: 'ID primária',
    passport: 'Uma cópia do seu passaporte internacional válido (página de assinatura)',
    id: 'Uma cópia da carteira de identidade nacional emitida pelo governo',
    dl: 'Uma cópia de sua carteira de motorista válida',
    address: 'ID Secundário',
    addressList: [
      'Cópia de uma conta de serviço público recente (não mais de 6 meses)',
      'Cópia do extrato do cartão de crédito (com menos de 6 meses)',
      'Cópia de um extrato bancário (não mais de 6 meses)',
    ],
    tip:
      'Incentive os candidatos a fornecer uma cópia de UM dos documentos de identidade primários e secundários aceitos para',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'HISTÓRICO DE TRANSAÇÕES',
    rebateHistory: 'COMISSÃO',
    withdrawHistory: 'LEVANTAMENTOS',
    transferHistory: 'TRANSFERÊNCIAS',
    cancelWithdraw: `Deseja Cancelar a Solicitação de Retirada?`,
    cancelError: `Sua retirada está sendo processada no momento. Portanto, não podemos aceitar sua solicitação de cancelamento. Para obter mais informações, você pode entrar em contato conosco usando o recurso de bate-papo ao vivo.`,
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
    selectFile: 'Selecionar Ficheiro',
  },
  transferHistory: {
    payStatus: { processing: 'Em Processamento', paid: 'Pago', unpaid: 'Por pagar', rejected: 'Rejeitado' },
  },
  referralLinks: {
    header: 'LINKS DE REFERÊNCIA',
    ibAcc: 'Conta IB',
    campLang: 'Idioma da campanha',
    cpy: 'CÓPIA DE',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Conta Real',
    demoAcc: 'Conta Demo',
    homePage: 'Pagina inicial',
    Share: 'Compartilhar',
  },
  settings: { ibProfile: 'PERFIL IB ', info: 'INFORMAÇÃO', reg: 'REGISTADO EM' },
  unfundedAcc: {
    header: 'CONTAS NÃO FINANCIADAS',
    allActivationAccs: 'TODAS AS CONTAS DE ATIVAÇÃO',
    neverFundedAccs: ' CONTAS NUNCA FINANCIADAS',
    prevFundedZero: 'SALDO ZERO',
  },
  multiib: { level: 'NÍVEL {level}', noib: 'Não há sub-IBs sob esta conta.' },
  header: { bckToCP: 'VOLTAR AO PORTAL DO CLIENTE', logout: 'SAIR' },
  menu: {
    dashboard: 'PAINEL DE CONTROLE',
    accManagement: 'CONTAS',
    ibReport: 'Relatório IB ',
    rebateReport: 'RELATÓRIO DE COMISSÃO',
    iblevel: 'MULTI-NÍVEL',
    ibAccs: 'CONTAS ATIVADAS',
    leads: 'LEADS',
    pendingAccs: 'CONTAS PENDENTES',
    unfundedAccs: 'CONTAS NÃO FINANCIADAS',
    payHistory: 'HISTÓRICO DE TRANSAÇÕES',
    ibProfile: 'PERFIL IB ',
    refLinks: 'LINKS DE REFERÊNCIA',
    contactUs: 'CONTATE-NOS',
    switchCP: 'MUDAR PARA O PORTAL DO CLIENTE',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'Aviso de risco: a negociação de contratos por diferença (CFDs) carrega um alto nível de risco para o seu capital e pode resultar em perdas. Você só deve negociar com dinheiro que pode perder. A negociação de CFDs pode não ser adequada para todos os investidores, certifique-se de compreender totalmente os riscos envolvidos e de tomar as medidas adequadas para gerenciá-los. Leia o documento relevante de divulgação de riscos com atenção.',
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    553: 'Não pode levantar/transferir devido ao crédito na sua conta. Por favor, entre em contacto com {email} para obter mais ajuda',
    554: 'O valor solicitado é zero ou nulo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de retirar fundos.',
    582: 'Você está participando do evento e possui vagas, portanto não pode enviar um pedido de retirada.',
    584: 'Não tem compensações aplicáveis',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    595: 'Como você está participando de nossas atividades, sua solicitação de transferência não pode ser processada no momento.',
    647: 'O código de verificação está incorreto',
    session_timeout: 'A sessão chegou ao tempo limite, você será redirecionando para a página de login.',
  },
}
