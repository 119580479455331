export default {
  en: 'Englisch',
  home: {
    NewPortal: 'Neues Portal',
    SecureIBPortal: 'Sicheres IB-Portal',
    DASHBOARD: 'DASHBOARD',
    MARKETING_TOOLS: 'MARKETING-TOOLS',
    IB_REPORT: 'IB-BERICHT',
    REBATE_REPORT: 'RABATT-BERICHT',
    IB_ACCOUNTS: 'IB-KONTEN',
    PAYMENT_HISTORY: 'TRANSAKTIONSHISTORIE',
    IB_FAQ: 'IB-FAQ',
    RebateAccountNumber: 'Rabatt-Kontonummer',
    totalBalance: 'Gesamtguthaben',
    totalCommission: 'Gesamtprovision',
    APPLY_FOR_A_REBATE: 'ANWENDEN',
    availableBalance: 'verfügbares Guthaben',
    WITHDRAW_REBATE: 'AUSZAHLUNGEN',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Überweisung',
    MonthtoDate: 'Monat bis heute',
    UPDATE: 'AKTUALISIEREN',
    REBATE: 'RABATT',
    TOTAL_VOLUME: 'GESAMTVOLUMEN',
    NET_FUNDING: 'NETTO-FINANZIERUNG',
    DEPOSITS: 'EINZAHLUNGEN',
    WITHDRAW: 'AUSZAHLUNGEN',
    OPEND_ACCOUNT: 'ERÖFFNETES KONTO',
    PERFORMANCE: 'PERFORMANCE',
    OpendAccount: 'ERÖFFNETES KONTO',
    select_ib: 'Bitte IB-Konto auswählen',
    MOST_TRADED_INSTRUMENTS: 'MEIST GEHANDELTE INSTRUMENTE',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'KONTEN MIT DER BESTEN PERFORMANCE IN DIESEM MONAT',
    RECENTLY_OPENED_ACCOUNTS: 'KÜRZLICH ERÖFFNETE KONTEN',
    ACCOUNT_MANAGER: 'KUNDENSUPPORT',
    Account: 'Konto',
    Name: 'Name',
    Volume: 'Volumen',
    Rebate: 'Rabatte',
    Balance: 'Guthaben',
    ContactClient: 'Kundenkontakt',
    YouCannotAskForCommission: 'Sie können keine Provision beantragen',
    applicationFailedPleaseTryAgain: 'Der Antrag ist fehlgeschlagen, bitte versuchen Sie es später noch einmal',
    applyRebateSuccessTip:
      'Herzlichen Glückwunsch, wir haben Ihren Rabattantrag erhalten. | {amount} wird in Kürze auf das Rabattkonto {account} überwiesen. | Sobald die Bearbeitung abgeschlossen ist, können Sie Ihr Geld abheben oder überweisen.',
    HOVER: 'Die Rabatte des Vortages werden täglich zwischen 09:00 - 11:00 AEST berechnet und verarbeitet.',
  },
  liveChat: { header: 'Live-Chat' },
  contactUs: {
    click: 'Klicken Sie jetzt',
    assist: 'für Unterstützung',
    header: 'KONTAKT',
    followUs: 'FOLGEN SIE UNS',
    tip:
      'Das professionelle, mehrsprachige Kundensupport-Team von {platform} ist bereit, Sie zu unterstützen und Ihnen ein unvergleichliches Trading-Erlebnis zu bieten.',
  },
  ibAccounts: {
    header: 'IB-KONTEN',
    ibAccount: 'IB-Konto',
    subIbs: 'IB-Unterkonto',
    tradinglist: 'Handelsliste',
    openlist: 'Liste öffnen',
    closedlist: 'Geschlossene Liste',
    tradinglistColumn: {
      ticketid: 'Ticketnummer',
      opentime: 'Offene Zeit',
      type: 'Typ',
      volume: 'Volumen',
      item: 'Artikel',
      openprice: 'Offener Preis',
      closeprice: 'Preis schließen',
      closetime: 'Schließzeit',
      commission: 'Kommission',
      profit: 'Profitieren',
      buy: 'Besorgen',
      sell: 'Verkaufen',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Kreditkarte wählen',
      selectBA: 'Bankkonto wählen',
      anotherCC: 'Kreditkarte manuell eingeben',
      anotherBA: 'Bankkonto hinzufügen',
      remember: 'Mein Konto merken',
      verificationCode:
        'Der Verifizierungscode wurde an {email} gesendet. Bitte kontaktieren Sie {supportEmail}, wenn Sie weitere Hilfe benötigen',
    },
    alert:
      'Aufgrund von AML-Gesetzen muss die Methode, die Sie zur Auszahlung verwenden, dieselbe sein wie die, die Sie für Ihre Einzahlung verwendet haben. Wenn der Auszahlungsbetrag weniger als 100 Einheiten der Basiswährung Ihres Kontos beträgt, wird Ihnen von der Bank eine Bearbeitungsgebühr von 20 Einheiten berechnet.',
    NetellerChargeAlert:
      'Neteller-Auszahlungen sind auf 10.000 US-Dollar pro Transaktion begrenzt und es fällt eine Gebühr von 2 % (maximal 30 US-Dollar) an.',
    FasaPayChargeAlert: 'FasaPay berechnet für jede Auszahlung eine Transaktionsgebühr von 0,5%.',
    SkrillChargeAlert: 'Skrill erhebt eine Transaktionsgebühr von 1% für jede Auszahlung.',
    cryptoAlert:
      'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
    REBATE_WITHDRAW_REQUEST: 'ANTRAG AUF RABATTAUSZAHLUNG',
    PleaseSelectDesiredWithdrawMethod: 'Bitte wählen Sie die gewünschte Auszahlungssmethode',
    BankName: 'Bank Name',
    BankAccountsName: 'Bankkonto Name',
    FasaPayAccountName: 'FasaPay Kontoname',
    AustralianBankName: 'Australische Bank Name',
    BankAccountNumber: 'Bankkontonummer',
    FasaPayAccountNumber: 'FasaPay-Kontonummer',
    BankAccountBeneficiary: 'Bankkonto Begünstigter',
    BSB: 'BSB',
    skrillEmail: 'Skrill E-Mail',
    SticpayEmail: 'SticPay E-Mail',
    NetellerEmail: 'Neteller-E-Mail',
    PerfectMoneyEmail: 'Perfect Money-Email',
    BitwalletEmail: 'Bitwallet E-Mail',
    BitcoinEmail: 'Bitcoin-Wallet Adresse',
    USDTEmail: 'Tether-Wallet Adresse',
    BankBranch: 'Bankfiliale',
    Province: 'Provinz',
    City: 'Stadt',
    BankAddress: 'Bank Adresse',
    bankCity: 'Bank Stadt',
    bankProvince: 'Bank Provinz',
    AccountNumberIBAN: 'Kontonummer/IBAN',
    BankBeneficiaryName: 'Bank Name des Begünstigten',
    BankAccountBeneficiaryName: 'Bankkonto Name des Begünstigten',
    AccountHoldersAddress: 'Adresse des Kontoinhabers',
    SelectWithdrawCard: 'Wählen Sie Ihre Auszahlungskarte',
    Swift: 'Swift',
    ifscCode: 'IFSC-Code',
    SwiftAud: 'Swift (Nicht-AUD-Konten)',
    ABA_SortCodeABA: 'ABA/Sort-Code',
    ImportantNotes: 'Wichtige Hinweise',
    YourPaymentGreaterThanAvailableBalance: 'Ihre Zahlung ist höher als das verfügbare Guthaben',
    CannotUseMoreThanTwoDecimalValues:
      'Sie können keine Zahlen und negativen Zahlen mit mehr als zwei Dezimalstellen verwenden',
    YourPaymentIsBeingProcessed: 'Ihr Auszahlungsantrag wurde eingereicht',
    Itcannotbe0: 'Es kann nicht 0 sein',
    noCardWarn:
      'Sie haben keine verfügbare Karte für die Auszahlung, bitte verknüpfen Sie Ihre Karte zuerst in den Auszahlungsdetails',
    amtLarger: 'Der Betrag kann nicht null sein und muss größer oder gleich ${minLimit} {currency} sein',
    blackList: `Leider können Sie derzeit keinen Auszahlungsantrag einreichen. Bitte kontaktieren Sie {supportEmail} für weitere Informationen`,
    status: {
      withdrawalSubmitted: 'Abgesendet',
      withdrawalCanceled: 'Abgebrochen',
      withdrawalAuditing: 'In Bearbeitung',
      withdrawalRejected: 'Abgelehnt',
      withdrawalAccepted: 'In Bearbeitung',
      withdrawalPayFailed: 'Gescheitert',
      withdrawalSuccess: 'Verarbeitet',
      withdrawalFailed: 'Gescheitert',
      withdrawalPending: 'Ausstehend',
      withdrawalPartialFailed: 'Gescheitert',
      transferSubmitted: 'In Bearbeitung',
      transferSuccess: 'Verarbeitet',
      transferRejected: 'Abgelehnt',
      transferFailed: 'Gescheitert',
      transferProcessing: 'In Bearbeitung',
    },
    formErrorMsg: {
      bankName: 'Bankname ist erforderlich',
      bsb: 'BSB ist erforderlich',
      beneName: 'Bank Name des Begünstigten ist erforderlich',
      accNum: 'Bankkontonummer ist erforderlich',
      swift: 'Swift ist erforderlich',
      bankAddress: 'Bankadresse ist erforderlich',
      holderAddress: 'Inhaberadresse ist erforderlich',
      amt: 'Betrag ist erforderlich',
      firstSixDigits: 'Bitte geben Sie die ersten 6 Ziffern ein',
      lastFourDigits: 'Bitte geben Sie die letzten 4 Ziffern ein',
      branchName: 'Filialname ist erforderlich',
      email: 'E-Mail ist erforderlich',
      accName: 'Kontoname ist erforderlich',
      province: 'Bank Provinz ist erforderlich',
      city: 'Bank Stadt ist erforderlich',
      bankCard: 'Bitte wählen Sie eine Karte',
      emailFormat: 'Bitte korrekte E-Mail Adresse eingeben',
      amt0: 'Betrag kann nicht 0 sein',
      ifscCode: 'IFSC-Code ist erforderlich',
      bitcoin: 'Bitcoin-Wallet-Adresse ist erforderlich',
      addressLength: 'Bitcoin-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      usdt: 'USDT-Wallet-Adresse ist erforderlich',
    },
    transfer: {
      info:
        '{platform} wird die Bankgebühr für <u>EINE</u> Auszahlung pro Monat zurückerstatten. Für weitere Auszahlungen innerhalb des Monats fällt eine Bankgebühr von 20 Einheiten Ihrer Auszahlungswährung an.',
      label: {
        upload: 'Bitte laden Sie eine Kopie Ihres Kontoauszugs hoch (aktuell für die letzten 3 Monate).',
      },
      formValidation: { upload: 'Bitte laden Sie einen Kontoauszug hoch.' },
    },
    transitNumber: 'Transitnummer',
    institutionNumber: 'Institutionsnummer',
  },
  rebate: { status: { processed: 'Verarbeitet', processing: 'In Bearbeitung', rejected: 'Abgelehnt' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'RABATT-ÜBERWEISUNGSANTRAG',
    transferAmountIsGreaterThanAvailableBalance: 'Ihr Überweisungsbetrag ist größer als das verfügbare Guthaben',
    yourRebateTransferHasBeenProcessed: 'Ihre Rabatt-Überweisung wurde eingereicht',
    PleaseEnterTransferInformation: 'Bitte geben Sie genaue Überweisungsinformationen ein',
    tradingAccount: 'Trading-Konto',
    rebateAccount: 'Rabatt-Konto',
    account: 'Konto',
    blackList: 'Dieses Konto wurde für Transfers zwischen Konten gesperrt. Bitte kontaktieren Sie {supportEmail}',
  },
  common: {
    liveChat: { desc: 'Brauchen Sie Hilfe? Live-Chat' },
    lang: {
      english: 'Englisch',
      chinese: 'Chinesisch',
      french: 'Französisch',
      thai: 'Thailändisch',
      german: 'Deutsch',
      spanish: 'Spanisch',
      malay: 'Malaiisch',
      viet: 'Vietnamesisch',
      indo: 'Indonesisch',
      arabic: 'Arabisch',
    },
    button: { confirm: 'BESTÄTIGEN', cancel: 'ABBRECHEN', sendCode: 'Code senden', resendCode: 'Code erneut senden' },
    withdrawChannel: {
      banktransfer: 'Banküberweisung',
      aus: 'Banküberweisung - Australien',
      int: 'Banküberweisung - International',
      chn: 'Banküberweisung - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'UnionPay Überweisung',
      fasapay: 'Fasapay',
      creditcard: 'Kreditkarte',
      bpay: 'Bpay',
      creditdebitcard: 'Kredit-/Debitkarte',
      bankwiretransferinternational: 'Banküberweisung (International)',
      banktransferaustralia: 'Banküberweisung (Australien)',
      banktransferinternational: 'Banküberweisung (International)',
      banktransferchina: 'Banküberweisung (International)',
      mobilepay: 'Mobiles Bezahlen',
      brokertobrokertransfer: 'Broker-zu-Broker-Überweisung',
      skrillmoneybookers: 'Skrill/Moneybookers',
      internetbankingthailand: 'Internet-Banking (Thailand)',
      internetbankingnigeria: 'Internet-Banking (Nigeria)',
      internetbankingvietnam: 'Internet-Banking (Vietnam)',
      internetbankingmalaysia: 'Internet-Banking (Malaysia)',
      internetbankingindonesia: 'Internet-Banking (Indonesia)',
      internetbankingkorea: 'Internet-Banking (Korea)',
      internetbankingindia: 'Internet-Banking (Indien)',
      internetbankingphilippine: 'Internet-Banking (Philippinen)',
      internetbankingsouthafrica: 'Internet-Banking (Südafrika)',
      internetbankinguganda: 'Internet-Banking (Uganda)',
      internetbankingrwanda: 'Internet-Banking (Ruanda)',
      internetbankingzambia: 'Internet-Banking (Sambia)',
      internetbankingcongo: 'Internet-Banking (Kongo)',
      internetbankingcameroon: 'Internet-Banking (Kamerun)',
      internetbankingburundi: 'Internet-Banking (Burundi)',
      internetbankingkenya: 'Internet-Banking (Kenia)',
      internetbankingghana: 'Internet-Banking (Ghana)',
      internetbankingtanzania: 'Internet-Banking (Tansania)',
      internetbankingcanada: 'Internet-Banking (Kanada)',
      skrillneteller: 'Skrill/Neteller',
      vietnambanktransfer: 'Vietnam Banküberweisung',
      nigeriabanktransfer: 'Nigeria Banküberweisung',
      polipayment: 'Poli-Zahlung',
      bankwiretransferaustralia: 'Banküberweisung (Australien)',
      thailandinstantbankwiretransfer: 'Thailand Sofortige Banküberweisung',
      malaysiainstantbankwiretransfer: 'Malaysia Sofortige Banküberweisung',
      banktransferbpaypolipay: 'Banküberweisung/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Kryptowährung-Bitcoin',
      cryptocurrencyusdt: 'Kryptowährung-USDT',
      nigeriainstantbankwiretransfer: 'Nigerianische Sofortüberweisung',
      vietnaminstantbankwiretransfer: 'Vietnamesische Sofortüberweisung',
      indonesiainstantbankwiretransfer: 'Indonesien Sofortüberweisung',
      philippinesinstantbankwiretransfer: 'Philippinische Sofortige Banküberweisung',
      southafricainstantbankwiretransfer: 'Südafrikanische Instant BankÜberweisung',
      indiainstantbanktransfer: 'Indien Sofortige Banküberweisung',
      southkoreabanktransfer: 'Südkorea lokale Banküberweisung',
      ugandabanktransfer: 'Uganda lokale Banküberweisung',
      rwandabanktransfer: 'Ruanda lokale Banküberweisung',
      zambiabanktransfer: 'Sambia lokale Banküberweisung',
      congobanktransfer: 'Kongo lokale Banküberweisung',
      cameroonbanktransfer: 'Kamerun lokale Banküberweisung',
      burundibanktransfer: 'Burundi lokale Banküberweisung',
      kenyabanktransfer: 'Kenia lokale Banküberweisung',
      ghanabanktransfer: 'Ghana lokale Banküberweisung',
      tanzaniabanktransfer: 'Tansania lokale Banküberweisung',
      banktransferbpaypolipayaustralia: 'Banküberweisung/BPay/PoliPay (Australien)',
      banktransferbpaypolipayinternational: 'Banküberweisung/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Kanadische lokale Banküberweisung',
      cryptobitcoin: 'Krypto-Bitcoin',
      cryptotetheromni: 'Krypto-Tether (OMNI)',
      cryptotethererc20: 'Krypto-Tether (ERC20)',
      astropay: 'Astropay',
      vload: 'Vload',
      sticpay: 'SticPay',
      transfer: 'Überweisung auf Konten',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'KRYPTOWÄHRUNG',
      LOCAL_TRANSFER: 'LOKALER TRANSFER',
    },
    country: { international: 'International', australia: 'Australien' },
    field: {
      country_region: 'Land/Region',
      address: 'Adresse',
      phone: 'Telefonnummer',
      nat: 'Nationalität',
      country: 'Land des Wohnsitzes',
      save: 'Investition',
      annIncome: 'Jährliches Einkommen',
      emailAdd: 'E-Mail-Adresse',
      yes: 'Ja',
      no: 'Nein',
      enterVerificationCode: 'Verifizierungscode eingeben',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'ABSENDEN',
      FROM: 'VON',
      UPDATE: 'AKTUALISIEREN',
      TO: 'AN',
      DATE: 'DATUM',
      pleaseSelect: 'Bitte auswählen',
      amount: 'Betrag',
      rebateAccount: 'Rabatt-Konto',
      comingSoon: 'demnächst',
      tradingHistory: 'TRADING-HISTORIE',
      noData: 'Keine Daten',
      NAME: 'NAME',
      EMAIL: 'E-MAIL',
      ACCTYPE: 'KONTO-TYP',
      PLATFORM: 'PLATTFORM',
      BASECURRENCY: 'BASISWÄHRUNG',
      DATERANGE: 'DATUMSBEREICH',
      STATUS: 'STATUS',
      DEST: 'ZIEL',
      ACCNUM: 'KONTONUMMER',
      BALANCE: 'GUTHABEN',
      PROFITLOST: 'PROFITIEREN',
      MARGINLVL: 'MARGIN-PEGEL',
      ACCEQUITY: 'KONTOEIGENKAPITAL',
      CREDIT: 'KREDIT',
      NONE: 'Keine',
      ALL: 'Alle',
      actualAmonut: 'Tatsächlicher Betrag',
      currency: 'WÄHRUNG',
      method: 'METHODE',
    },
    dateRange: {
      today: 'Heute',
      yesterday: 'Gestern',
      lastSevenDays: 'Letzte 7 Tage',
      lastThirtyDays: 'Letzte 30 Tage',
      weekToDate: 'Woche bis heute',
      monthToDate: 'Monat bis heute',
      quarterToDate: 'Quartal bis heute',
      previousWeek: 'Vorherige Woche',
      previousMonth: 'Vorheriger Monat',
      previousQuarter: 'Vorheriges Quartal',
    },
    products: { forex: 'FOREX', commodities: 'ROHSTOFFE', indices: 'INDIZES', crypto: 'CRYPTO' },
    formValidation: {
      dynamicReq: '{dynamic} ist erforderlich',
      verCodeReq: 'Verifizierungscode ist erforderlich',
      alphanumericReq: 'Bitte alphanumerisch eingeben',
    },
  },
  report: {
    title: { rebateReport: 'RABATT-BERICHT' },
    rebate: {
      column: {
        name: 'NAME',
        accountNumber: 'KONTONUMMER',
        accountType: 'KONTO-TYP',
        volume: 'Volumen',
        rebate: 'Rabatt',
        totalRebate: 'GESAMTRABATT',
        date: 'DATUM',
        instrument: 'INSTRUMENT',
      },
      dropdown: { allInstruments: 'Alle Instrumente', all: 'Alle', instruments: 'Instrumente' },
      others: {
        rebate: 'RABATT',
        instrumentsTraded: 'GEHANDELTE INSTRUMENTE',
        lots: 'Lots',
        totalRebate: 'GESAMTRABATT',
        rebates: 'Rabatte',
      },
    },
  },
  ibReport: {
    header: 'IB-BERICHT',
    netFunding: 'NETTO-FINANZIERUNG',
    deposits: 'EINZAHLUNGEN',
    withdraw: 'AUSZAHLUNGEN',
    openedAccs: 'ERÖFFNETE KONTEN',
    funding: 'FINANZIERUNG',
    withdrawals: 'AUSZAHLUNGEN',
    appliedAmount: 'angewendeter Betrag',
    depositAmount: 'Einzahlungsbetrag',
    withdrawalAmount: 'Auszahlungsbetrag',
  },
  lead: { header: 'LEADS', ibAccount: 'IB-Konto', demoAccounts: 'DEMO-KONTEN' },
  pendingAccounts: {
    header: 'AUSSTEHENDE KONTEN',
    errorDoc:
      'Die folgenden Antragsteller haben das Online-Antragsformular ausgefüllt, müssen aber noch einen Identifikationsnachweis (ID) erbringen, um ihr Konto abschließen zu können.',
    acceptedDocType: 'Akzeptierte Formen der ID sind:',
    primaryDoc: 'Primäre-ID',
    passport: 'Eine Kopie Ihres gültigen internationalen Reisepasses (Unterschriftenseite)',
    id: 'Eine Kopie Ihres staatlich ausgestellten Personalausweises',
    dl: 'Eine Kopie Ihres gültigen Führerscheins',
    address: 'Sekundäre-ID',
    addressList: [
      'Kopie einer aktuellen Nebenkostenabrechnung (nicht älter als 6 Monate)',
      'Kopie einer Kreditkartenabrechnung (nicht älter als 6 Monate)',
      'Kopie eines Kontoauszugs (nicht älter als 6 Monate)',
    ],
    tip:
      'Bitte fordern Sie Antragsteller auf, eine Kopie EINES der akzeptierten primären und sekundären Ausweisdokumente vorzulegen, um ',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'TRANSAKTIONSHISTORIE',
    rebateHistory: 'RABATTE',
    withdrawHistory: 'AUSZAHLUNGEN',
    transferHistory: 'ÜBERWEISUNGEN',
    cancelWithdraw: `Möchten Sie die Auszahlungsanfrage stornieren?`,
    cancelError: `Ihre Auszahlung wird gerade bearbeitet. Daher können wir Ihre Stornierungsanfrage nicht annehmen. Für weitere Informationen können Sie uns über die Live-Chat-Funktion erreichen.`,
  },
  fileUploader: {
    supportedFormat: 'Unterstützte Dateitypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Maximale Dateigröße beim Hochladen: {maxSize}MB',
    maxFileNum: 'Maximale Dateianzahl: {maxFileNum}',
    selectFile: 'Datei auswählen',
  },
  transferHistory: {
    payStatus: { processing: 'In Bearbeitung', paid: 'Bezahlt', unpaid: 'Unbezahlt', rejected: 'Abgelehnt' },
  },
  referralLinks: {
    header: 'EMPFEHLUNGSLINKS',
    ibAcc: 'IB-Konto',
    campLang: 'Kampagnen-Sprache',
    cpy: 'KOPIE',
    alreadyCPY: 'KOPIERT!',
    liveAcc: 'Live-Konto',
    demoAcc: 'Demo-Konto',
    homePage: 'Startseite',
    Share: 'Teilen',
  },
  settings: { ibProfile: 'IB-PROFIL', info: 'INFO', reg: 'REGISTRIERT AUF' },
  myProfile: {
    changeDetails:
      'Wenn Sie Ihre bestehenden Profildaten aktualisieren möchten, zögern Sie nicht, sich an {mail To} zu wenden',
  },
  unfundedAcc: {
    header: 'UNGEDECKTE KONTEN',
    allActivationAccs: 'ALLE AKTIVIERUNGSKONTEN',
    neverFundedAccs: 'NIE FINANZIERTE',
    prevFundedZero: 'KEIN GUTHABEN',
  },
  multiib: { level: 'EBENE {level}', noib: 'Es gibt keine Sub-IBs unter diesem Konto.' },
  header: { bckToCP: 'ZURÜCK ZUM KUNDENPORTAL', logout: 'AUSLOGGEN' },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'IB-BERICHT',
    rebateReport: 'RABATT-BERICHT',
    accManagement: 'KONTEN',
    iblevel: 'MULTI-LEVEL',
    ibAccs: 'AKTIVIERTE KONTEN',
    leads: 'LEADS',
    pendingAccs: 'AUSSTEHENDE KONTEN',
    unfundedAccs: 'UNGEDECKTE KONTEN',
    payHistory: 'TRANSAKTIONSHISTORIE',
    ibProfile: 'IB-PROFIL',
    refLinks: 'EMPFEHLUNGSLINKS',
    contactUs: 'KONTAKT',
    switchCP: 'ZUM KUNDENPORTAL WECHSELN',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'Risiko-Warnung: Das Traden mit Differenzkontrakten (CFDs) birgt ein hohes Risiko für Ihr Kapital und kann zu Verlusten führen. Sie sollten nur mit Geld traden, das  zu verlieren Sie sich leisten können. Das Traden mit CFDs ist möglicherweise nicht für alle Investoren geeignet. Bitte stellen Sie sicher, dass Sie die damit verbundenen Risiken vollständig verstehen und geeignete Maßnahmen ergreifen, um diese zu steuern. Bitte lesen Sie das entsprechende Dokument mit den Risikohinweisen sorgfältig durch.',
  },
  responseMsg: {
    410: 'Parametervalidierung fehlgeschlagen',
    411: 'Falsches Passwort',
    420: 'Benutzer existiert nicht',
    421: 'Passwortrücksetzung verweigert',
    490: 'Bitte geben Sie keine doppelten Karteninformationen ein',
    500: 'Es ist ein Fehler aufgetreten.',
    501: 'Antrag fehlgeschlagen. Bitte versuchen Sie es später erneut.',
    505: 'Datei-Upload fehlgeschlagen',
    520: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    521: 'Es ist bereits ein Benutzer auf diesem Gerät angemeldet!',
    522: 'Bitte melden Sie sich erneut an',
    523: 'Bitte melden Sie sich erneut an',
    524: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
    525: 'Falscher Benutzername oder falsches Passwort',
    527: 'Benutzer ist kein IB',
    528: 'Benutzer existiert nicht',
    529: 'Falscher Benutzername oder falsches Passwort',
    540: 'Ihr Trading-Konto konnte nicht gefunden werden',
    541: 'Kann Rabatt-Konto nicht finden',
    542: 'Sie dürfen kein zusätzliches Konto eröffnen',
    544: 'Sie können kein weiteres Konto beantragen, bis Ihr Identitätsnachweis verifiziert wurde',
    550: 'Falsche Kontonummer',
    551: 'Ihr Guthaben ist nicht ausreichend',
    553: 'Sie können aufgrund des Guthabens auf Ihrem Konto nicht auszahlen/überweisen. Bitte kontaktieren Sie {email} für weitere Hilfe.',
    554: 'Der angeforderte Betrag ist Null oder nicht gültig',
    562: 'Wir konnten Ihre Zahlung nicht verarbeiten, bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte per Live-Chat oder E-Mail {email}',
    564: 'Land stimmt nicht mit dem Zahlungskanal überein',
    565: 'Falsche Bankleitzahl',
    566: 'Zahlungsmethode nicht gefunden',
    581: 'Sie haben sich erfolgreich für die Promotion angemeldet. Bitte beachten Sie, dass Sie eine Einzahlung vornehmen müssen, um Geld auszahlen zu können.',
    582: 'Da Sie an der Kampagne teilnehmen und offene Positionen haben, können wir Ihren Auszahlungsantrag nicht bearbeiten.',
    584: 'Sie haben keine anwendbaren Rabatte',
    590: 'Transfer-Out-Konto nicht gefunden',
    591: 'Kann Einbuchungskonto nicht finden',
    593: 'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto Guthaben enthält.',
    594: 'Geldtransfer von diesem Konto ist nicht erlaubt',
    595: 'Da Sie an unserer Kampagne teilnehmen, können wir Ihre Überweisungsanfrage nicht bearbeiten',
    647: 'Verifizierungscode ist nicht korrekt',
    session_timeout: 'Session-Timeout, Weiterleitung zur Anmeldeseite.',
  },
}
