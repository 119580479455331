export default {
  en: 'English',
  zh: '中文',
  home: {
    NewPortal: '体验新版',
    SecureIBPortal: '安全的代理平台',
    DASHBOARD: '概览',
    MARKETING_TOOLS: '推广工具',
    IB_REPORT: '代理报表',
    IB_ACCOUNTS: '客户账号列表',
    REBATE_REPORT: '返佣报表',
    PAYMENT_HISTORY: '交易历史',
    IB_FAQ: '代理FAQ',
    RebateAccountNumber: '返佣账号',
    totalBalance: '可申请额度',
    totalCommission: '总佣金',
    APPLY_FOR_A_REBATE: '申请',
    availableBalance: '余额',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '转账',
    MonthtoDate: '当月',
    UPDATE: '更新',
    REBATE: '佣金',
    TOTAL_VOLUME: '交易量',
    NET_FUNDING: '净入金',
    DEPOSITS: '总入金',
    WITHDRAW: '总出金',
    OPEND_ACCOUNT: '已开账号数',
    PERFORMANCE: '趋势图',
    OpendAccount: '已开账号数',
    select_ib: '请选择IB账户',
    MOST_TRADED_INSTRUMENTS: '交易产品分布',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '本月业绩表现TOP',
    RECENTLY_OPENED_ACCOUNTS: '近期新开户',
    ACCOUNT_MANAGER: '专属客服',
    Account: '账号',
    Name: '姓名',
    Volume: '交易量',
    Rebate: '佣金',
    Balance: '余额',
    ContactClient: '联系客户',
    YouCannotAskForCommission: '您无可申请的佣金',
    applicationFailedPleaseTryAgain: '申请失败，请稍后重试',
    applyRebateSuccessTip:
      '祝贺你，我们收到了您的申请要求。 | {amount}将在1个工作日内转入返佣账户{account}。 | 一旦您的申请处理完毕，您可以提取或转移资金。',
    HOVER: '前一天的佣金将会在澳大利亚东部时间9至11时之间计算',
  },
  liveChat: {
    header: '在线聊天',
  },
  contactUs: {
    header: '联系我们',
    followUs: '更多方式关注我们',
    tip: '{platform} 全球多语言团队随时为您提供帮助和服务, 让您拥有无与伦比的交易体验',
  },
  rebate: {
    status: {
      processed: '已完成',
      processing: '处理中',
      rejected: '被拒绝',
    },
  },
  ibAccounts: {
    header: '客户管理',
    ibAccount: '返佣账号',
    subIbs: '子代理返佣账号',
    tradinglist: '交易清单',
    openlist: '未关闭交易',
    closedlist: '已关闭交易',
    tradinglistColumn: {
      ticketid: '订单号码',
      opentime: '开仓时间',
      type: '类型',
      volume: '交易量',
      item: '产品名称',
      openprice: '开盘价',
      closeprice: '收盘价',
      closetime: '平仓时间',
      commission: '返佣',
      profit: '损益',
      buy: '买',
      sell: '卖',
    },
  },
  withdraw: {
    default: {
      selectCC: '选择信用卡',
      selectBA: '选择银行账户',
      anotherCC: '手动输入信用卡',
      anotherBA: '添加银行帐户',
      remember: '记住我的帐号',
      verificationCode: '验证码已发送至{email}。若您需要进一步协助，请联系{supportEmail}。',
    },
    alert:
      '根据反洗钱法，提取资金的方式必须与用于存款的方式相同。如果提款金额少于您帐户基础货币的100个单位，则银行将向您收取20个单位的手续费。',
    NetellerChargeAlert: 'Neteller 出金限额为每笔交易 10,000 美元，并收取 2% 的手续费（上限为 30 美元）。',
    FasaPayChargeAlert: 'FasaPay 对每次出金收取 0.5% 的手续费。',
    SkrillChargeAlert: 'Skrill 对每次出金收取 1% 的手续费。',
    cryptoAlert:
      '请注意，我们无法通过 BUSDT 进行存款或取款，请确保地址和加密货币与我们接受的链和货币匹配，否则您可能会损失资金。',
    REBATE_WITHDRAW_REQUEST: '出金申请',
    usdtNote:
      '受ERC20的矿工费上涨影响，出金处理时间也相应延迟。为了保障您的出金处理速度，您可以选择使用TRC20，或支付较高的矿工费申请出金，以加快您的出金申请进度。',
    PleaseSelectDesiredWithdrawMethod: '请选择所需的出金方法',
    BankName: '银行名称',
    BankAccountsName: '银行账户名',
    FasaPayAccountName: 'FasaPay账户姓名',
    AustralianBankName: '澳洲银行名称',
    BankAccountNumber: '银行账号',
    FasaPayAccountNumber: 'FasaPay 账户号码',
    BankAccountBeneficiary: '银行收款人姓名',
    BSB: 'BSB信息',
    skrillEmail: 'Skrill邮箱',
    NetellerEmail: 'Neteller邮箱',
    PerfectMoneyEmail: 'Perfect Money邮箱',
    BitwalletEmail: 'Bitwallet邮箱',
    SticpayEmail: 'Sticpay邮箱',
    BitcoinEmail: 'Bitcoin钱包地址',
    USDTEmail: 'Tether钱包地址',
    BankBranch: '银行支行',
    Province: '省',
    City: '市',
    BankAddress: '银行地址',
    bankCity: '银行所在城市',
    bankProvince: '银行所在省',
    AccountNumberIBAN: '银行账号',
    BankBeneficiaryName: '银行收款人姓名',
    BankAccountBeneficiaryName: '银行账户收款人姓名',
    AccountHoldersAddress: '账户持有人地址',
    SelectWithdrawCard: '请选择出金卡',
    Swift: 'Swift代码',
    SwiftAud: 'Swift代码(非澳元账号)',
    ABA_SortCodeABA: 'Sort代码',
    ImportantNotes: '重要信息',
    YourPaymentGreaterThanAvailableBalance: '您的出金金额大于可用余额',
    CannotUseMoreThanTwoDecimalValues: '无法使用超过两位小数的数值和负数',
    YourPaymentIsBeingProcessed: '您的出金申请已经提交',
    Itcannotbe0: '出金不能为0',
    noCardWarn: '没有有效的卡, 请先绑卡',
    amtLarger: '金额不能为空，同时必须大于或等于 {minLimit} {currency}',
    blackList: `很遗憾，您目前无法提交提款请求。请联系{supportEmail}取得更多信息`,
    status: {
      withdrawalSubmitted: '已提交',
      withdrawalCanceled: '取消',
      withdrawalAuditing: '处理中',
      withdrawalRejected: '拒绝',
      withdrawalAccepted: '处理中',
      withdrawalPayFailed: '失败',
      withdrawalSuccess: '已完成',
      withdrawalFailed: '失败',
      withdrawalPending: '等候中',
      withdrawalPartialFailed: '失败',
      transferSubmitted: '处理中',
      transferSuccess: '已完成',
      transferRejected: '拒绝',
      transferFailed: '失败',
      transferProcessing: '处理中',
    },
    formErrorMsg: {
      bankName: '请填写银行名称',
      bsb: '请填写BSB',
      beneName: '请填写帐户名',
      accNum: '请填写帐户号码',
      swift: '请填写Swift',
      bankAddress: '请填写银行地址',
      holderAddress: '请填写持卡人地址',
      amt: '请填写金额',
      firstSixDigits: '请填写卡号前六位',
      lastFourDigits: '请填写卡号前四位',
      branchName: '请填写分行名称',
      email: '请填写电子邮件',
      accName: '请填写银行账户名',
      province: '请填写银行所在省',
      city: '请填写银行所在市',
      cardbankCard: '请选择一张卡',
      emailFormat: '请填写正确的电邮地址',
      amt0: '金额不能为0',
      bitcoin: '请填写Bitcoin钱包地址',
      addressLength: '比特币钱包地址必须在 26 到 42 个字符之间',
      usdt: '请填写USDT钱包地址',
    },
    transfer: {
      info: '{platform} 会垫付每个月第一次出金所产生的银行费用. 当月内的其他出金将会产生20单位出金货币的银行费用.',
      label: {
        upload: '请上传一张近3个月银行对账单的截图、照片或复印件',
      },
      formValidation: {
        upload: '请上传银行帐单',
      },
    },
    transitNumber: '中转号码',
    institutionNumber: '机构编号',
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: '转账申请',
    transferAmountIsGreaterThanAvailableBalance: '您的转账金额大于可用余额',
    yourRebateTransferHasBeenProcessed: '您的转账申请已提交',
    PleaseEnterTransferInformation: '请输入准确的转账信息',
    tradingAccount: '交易账号',
    rebateAccount: '返佣账号',
    account: '账号',
    blackList: '此账户已被限制进行账户间转账，请联系 {supportEmail}',
  },
  common: {
    liveChat: { desc: '需要帮助？在线客服' },
    lang: {
      english: '英文',
      chinese: '中文',
      french: '法文',
      thai: '泰文',
      german: '德文',
      spanish: '西班牙文',
      malay: '马来西亚文',
      viet: '越南文',
      indo: '印尼文',
      arabic: '阿拉拍文',
    },
    button: {
      confirm: '确认',
      cancel: '取消',
      sendCode: '发送验证码',
      resendCode: '重发验证码',
    },
    withdrawChannel: {
      banktransfer: '银行转帐',
      aus: '银行转账 (澳大利亚)',
      int: '银行转账 (国际)',
      chn: '银行转账 (中国)',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: '银联转帐',
      unionpaytransfer: '银联转帐',
      fasapay: 'FasaPay',
      creditcard: '信用卡',
      bpay: 'Bpay',
      creditdebitcard: '信用卡/借记卡',
      bankwiretransferinternational: '银行转帐 (国际)',
      banktransferaustralia: '银行转帐 (澳大利亚)',
      banktransferinternational: '银行转帐 (国际)',
      banktransferchina: '银行转帐 (国际)',
      mobilepay: '移动支付',
      brokertobrokertransfer: '交易商到交易商',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '线上银行 (泰国)',
      internetbankingnigeria: '线上银行 (尼日利亚)',
      internetbankingvietnam: '线上银行 (越南)',
      internetbankingmalaysia: '线上银行 (马来西亚)',
      internetbankingindonesia: '线上银行 (印度尼西亚)',
      internetbankingkorea: '线上银行 (韩国)',
      internetbankingindia: '线上银行 (印度)',
      internetbankingphilippine: '线上银行 (菲律宾)',
      internetbankingsouthafrica: '线上银行 (南非)',
      internetbankinguganda: '线上银行 (乌干达)',
      internetbankingrwanda: '线上银行 (卢旺达)',
      internetbankingzambia: '线上银行 (赞比亚)',
      internetbankingcongo: '线上银行 (刚果)',
      internetbankingcameroon: '线上银行 (喀麦隆)',
      internetbankingburundi: '线上银行 (布隆迪)',
      internetbankingkenya: '线上银行 (肯尼亚)',
      internetbankingghana: '线上银行 (加纳)',
      internetbankingtanzania: '线上银行 (坦桑尼亚)',
      internetbankingcanada: '线上银行 (加拿大)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '越南银行银行转帐',
      nigeriabanktransfer: '尼日利亚银行转帐',
      polipayment: 'Poli支付',
      bankwiretransferaustralia: '银行转帐 (澳大利亚)',
      thailandinstantbankwiretransfer: '泰国银行即时转账',
      malaysiainstantbankwiretransfer: '马来西亚银行即时转账',
      banktransferbpaypolipay: '银行转帐/BPay银行转帐/PoLi支付',
      cryptocurrencybitcoin: '加密货币-Bitcoin',
      cryptocurrencyusdt: '加密货币-USDT',
      nigeriainstantbankwiretransfer: '尼日利亚银行即时转账',
      vietnaminstantbankwiretransfer: '越南银行即时转账',
      indonesiainstantbankwiretransfer: '印度尼西亚银行即时转账',
      philippinesinstantbankwiretransfer: '菲律宾银行即时转账',
      southafricainstantbankwiretransfer: '南非银行即时转账',
      indiainstantbanktransfer: '印度银行即时转账',
      southkoreabanktransfer: '韩国即时银行电汇',
      ugandabanktransfer: '乌干达当地银行转账',
      rwandabanktransfer: '卢旺达当地银行转账',
      zambiabanktransfer: '赞比亚当地银行转账',
      congobanktransfer: '刚果当地银行转账',
      cameroonbanktransfer: '喀麦隆当地银行转账',
      burundibanktransfer: '布隆迪当地银行转账',
      kenyabanktransfer: '肯尼亚当地银行转账',
      ghanabanktransfer: '加纳当地银行转账',
      tanzaniabanktransfer: '坦桑尼亚当地银行转账',
      banktransferbpaypolipayaustralia: '银行转帐/BPay银行转帐/PoLi支付 (澳大利亚)',
      banktransferbpaypolipayinternational: '银行转帐/BPay银行转帐/PoLi支付 (国际)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: '加拿大本地银行转帐',
      cryptobitcoin: '加密货币-Bitcoin',
      cryptotetheromni: '加密货币-Tether(OMNI)',
      cryptotethererc20: '加密货币-Tether(ERC20)',
      ryptotethertrc20: '加密货币-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: '转账至账号',
      E_WALLET: '电子钱包',
      CRYPTOCURRENCY: '加密货币',
      LOCAL_TRANSFER: '本地转帐',
    },
    country: { international: '国际', australia: '澳大利亚' },
    field: {
      country_region: '国家/地区',
      address: '地址',
      phone: '电话',
      nat: '国籍',
      country: '居住国家',
      save: '投资',
      annIncome: '年收入',
      emailAdd: '电子邮件地址',
      yes: '是',
      no: '否',
      enterVerificationCode: '输入验证码',
      infoDetails: '信息详情',
      noBind: '未绑定',
      noDataNow: '暂无数据',
      confirmClose: '确定关闭吗？',
      ibAccount: 'IB账户',
      quickFilter: '快捷筛选',
      byDate: '起止日期',
      accountOpeningRecord: '开户记录',
      depositRecords: '入金记录',
      withdrawRecords: '出金记录',
      productType: '产品类型',
      commissionList: '佣金列表',
      tips: '提示',
      query: '查询',
    },
    keys: {
      SUBMIT: '提交',
      FROM: '从',
      TO: '到',
      DATE: '日期',
      UPDATE: '更新',
      pleaseSelect: '请选择',
      amount: '金额',
      rebateAccount: '返佣账号',
      comingSoon: '敬请期待',
      noData: '没有数据',
      NAME: '姓名',
      EMAIL: '电子邮件',
      ACCTYPE: '账户类型',
      PLATFORM: '交易平台',
      BASECURRENCY: '币种',
      DATERANGE: '申请时间',
      STATUS: '状态',
      DEST: '目标帐户',
      tradingHistory: '交易历史',
      ACCNUM: '账号',
      BALANCE: '余额',
      PROFITLOST: '损益',
      MARGINLVL: '保证金水平',
      ACCEQUITY: '账户净值',
      CREDIT: '信用',
      NONE: '无',
      ALL: '全部',
      actualAmonut: '实际入金',
      currency: '货币类型',
      method: '方式',
    },
    dateRange: {
      today: '今天',
      yesterday: '昨天',
      lastSevenDays: '最近7天',
      lastThirtyDays: '最近30天',
      weekToDate: '本周',
      monthToDate: '本月',
      quarterToDate: '本季度',
      previousWeek: '上周',
      previousMonth: '上月',
      previousQuarter: '上季度',
    },
    products: {
      forex: '外汇',
      commodities: '大宗商品',
      indices: '指数',
      crypto: '加密货币',
    },
    formValidation: {
      dynamicReq: '请填写{dynamic}',
      verCodeReq: '验证码为必填项',
      alphanumericReq: '请填写字母数字',
    },
  },
  report: {
    title: {
      rebateReport: '佣金报告',
    },
    rebate: {
      column: {
        date: '日期',
        name: '名字',
        accountNumber: '账号',
        accountType: '账号类型',
        volume: '交易量',
        rebate: '佣金',
        totalRebate: '总佣金',
        instrument: '产品',
      },
      dropdown: {
        allInstruments: '所有产品',
        all: '全部',
        instruments: '产品',
      },
      others: {
        lots: '手数',
        rebate: '佣金',
        totalRebate: '总佣金',
        instrumentsTraded: '已交易产品',
      },
    },
    comingSoon: '敬请期待',
    noData: '没有数据',
  },
  ibReport: {
    header: 'IB 报告',
    netFunding: '净入金',
    deposits: '总入金',
    withdraw: '总出金',
    openedAccs: '已开账户数',
    funding: '出入金记录',
    withdrawals: '总出金',
    appliedAmount: '申请金额',
    depositAmount: '入金金额',
    withdrawalAmount: '出金金额',
  },
  lead: {
    header: '潜在客户',
    ibAccount: '返佣账号',
    demoAccounts: '模拟账户',
  },
  pendingAccounts: {
    header: '待审核客户',
    errorDoc: '以下客户已经提交注册申请, 但还未提供满足开设账户的相关证件.',
    acceptedDocType: '可接受的证件类型包括:',
    primaryDoc: '身份证明',
    passport: '身份证(正面与背面)',
    id: '护照（完整的个人信息页）',
    dl: '有效的驾驶证',
    address: '地址证明',
    addressList: [
      '最近的公用事业账单副本（不超过6个月）',
      '信用卡对账单副本(不超过6个月)',
      '银行对账单副本（不超过6个月）',
    ],
    tip: '请联系客户提供一份身份证明和地址证明到 ',
    addMaterial: '补充材料说明',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: '交易历史',
    rebateHistory: '佣金申请记录',
    withdrawHistory: '佣金出金记录',
    transferHistory: '转账历史',
    cancelWithdraw: `您要取消提款请求吗？`,
    cancelError: `您的提款目前正在处理中。 因此，我们不能接受您的取消请求。 如需更多信息，您可以使用实时聊天功能联系我们。`,
  },
  fileUploader: {
    supportedFormat: '文件支持类型: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: '最大文件大小: {maxSize}MB',
    maxFileNum: '最多文件数: {maxFileNum}',
    selectFile: '上传文件',
  },
  transferHistory: {
    payStatus: {
      processing: '处理中',
      paid: '已付款',
      unpaid: '未付款',
      rejected: '被拒绝',
    },
  },
  referralLinks: {
    header: '推广链接',
    ibAcc: '返佣账号',
    campLang: '推广语言',
    cpy: '复制链接',
    alreadyCPY: '已复制!',
    liveAcc: '真实账户',
    demoAcc: '模拟账户',
    homePage: '主页链接',
    Share: '分享',
  },
  settings: {
    ibProfile: '个人信息',
    info: '信息',
    reg: '注册日期',
  },
  myProfile: { changeDetails: '如您想更改账户资讯, 请联系: {mailTo}' },
  unfundedAcc: {
    header: '未入金客户',
    allActivationAccs: '所有已激活账户',
    neverFundedAccs: '从未入金',
    prevFundedZero: '余额为0',
  },
  multiib: {
    level: '{level} 级',
    noib: '此帐户下没有子介绍经纪商（IB）。',
  },
  header: {
    bckToCP: '返回客户后台',
    logout: '登出',
  },
  menu: {
    dashboard: '概览',
    ibReport: 'IB 报告',
    rebateReport: '佣金报告',
    accManagement: '账户管理',
    iblevel: '多级介绍经纪商',
    ibAccs: '已激活账户',
    leads: '潜在客户',
    pendingAccs: '待审核客户',
    unfundedAccs: '未入金客户',
    payHistory: '交易历史',
    ibProfile: '个人信息',
    refLinks: '推广链接',
    contactUs: '联系我们',
    switchCP: '返回客户后台',
    rebateTransfer: '转账',
    rebateWithdraw: '出金',
  },
  footer: {
    riskWarn: `风险提示：差价合约（CFD）交易会给您的资金带来高风险，并可能导致损失，您只应使用可以承受损失的资金进行交易。 差价合约交易可能并不适合所有投资者，请确保您完全了解所涉及的风险并采取适当措施进行管理。 请仔细阅读相关的风险披露文件。`,
  },
  responseMsg: {
    //4xx warning
    410: '参数验证失败',
    411: '密码错误',
    420: '用户不存在',
    421: '密码重置被拒',
    490: '请不要重复输入卡信息',
    //5xx error
    500: '发生错误',
    501: '申请失败, 请稍后重试',
    505: '文件上传失败',
    520: '登录失败, 请重试',
    521: '您在此设备上已处于登入状态!',
    522: '请重新登录',
    523: '请重新登录',
    524: '您提供的邮箱尚未注册',
    525: '用户名或密码错误',
    527: '用户不是IB',
    528: '用户不存在',
    529: '用户名或密码错误',
    540: `系统找不到您的交易账号`,
    541: '无法找到返佣账号',
    542: '抱歉，您不允许开同名账号',
    544: '您不允许申请一个额外的账户，直到您的身份证明核实为止',
    550: '账号错误',
    551: '您的余额不足',
    553: '您好！您当前的账号尚有信用金，暂时无法提交出金/转账，如需帮助请发送邮件至{email}或联系官方客服',
    554: '请求的数额为0或者空',
    562: '无法处理您的支付请求，请重新尝试。如问题仍未解决，请通过Live Chat或者电子邮件{email}与我们取得联系',
    564: '国家未能配合该支付渠道',
    565: '银行代码错误',
    566: '无法找到支付方式',
    581: '您已成功选择加入促销活动。 请注意，您必须先入金才能出金。',
    582: '您正在参与活动且有持仓，无法提交出金申请。',
    584: `您没有可申请的佣金`,
    590: '无法找到转出账号',
    591: '无法找到转入账号',
    593: '由于您的账户包含信贷部分，我们无法对您所选择的账户进行资金转移处理。',
    594: '不允许从这个账户转移资金',
    595: '您正在参与活动，无法提交转账申请。',
    647: '验证码错误',
    session_timeout: '登录时间超时, 正在回到登录页面',
  },
}
