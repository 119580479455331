import { apiTo_logout, apiTo_login_by_token, apiGet_cp_url } from '@/resource'
import { handleError } from './handleError'
import store from '@/store'
import router from '@/router'
import { getRegulator } from '@/util/regulator'
import { getCookies, setCookies } from '@/util/cookies'
import { Base64 } from 'js-base64'
import config from '@/config'

export default {
  signOut(method = 'backToClientPortal', path = '') {
    const base64AccessToken = store.state.common.base64AccessToken
    store.commit('common/reset')
    store.commit('behavior/reset')
    store.commit('regulator/reset')
    setCookies('token', '')
    // apiTo_logout().then(() => {
    this.getCpUrl(method, base64AccessToken, path)
    // }, handleError)
  },
  loginByToken(accessToken) {
    apiTo_login_by_token({
      headers: {
        accessToken: Base64.decode(accessToken),
      },
    })
      .then(resp => {
        if (resp.data.code === 0) {
          store.dispatch('regulator/actionGetInformation')
          store.commit('common/setBase64AccessToken', accessToken)
          setCookies('token', accessToken, 30)
          store.commit('common/setLoginStatus', true)
          store.commit('common/setUserName', resp.data.data.userName)
          store.commit('common/setEmail', resp.data.data.email)
          store.commit('common/setPhone', resp.data.data.phone)
          store.commit('common/setCreateTime', resp.data.data.date)
          store.commit('common/setAddress', resp.data.data.address)
          store.commit('common/setLang', resp.data.data.language)
          store.commit('common/setCountryCode', resp.data.data.countryCode)
          store.commit('common/setRegulator', getRegulator(resp.data.data.regulator))
          store.commit('common/setEmailMasked', resp.data.data.emailMasked)
          setCookies('cpUrl', resp.data.data.url, 60 * 24 * 30)
          router.push('/home')
        }
      }, handleError)
      .catch(err => {
        router.go(-1)
      })
  },
  getCpUrl(method, base64AccessToken, path = '') {
    const cpUrl = getCookies('cpUrl')
    if (method === 'logout') store.commit('common/setAutoNotifyStatus')
    if (cpUrl) this.goToCp(method, cpUrl, base64AccessToken, path)
    else
      apiGet_cp_url().then(resp => {
        setCookies('cpUrl', resp.data.data, 60 * 24 * 30)
        this.goToCp(method, resp.data.data, base64AccessToken, path)
      }, handleError)
  },
  goToCp(method = 'backToClientPortal', cpUrl = config.cpUrl, base64AccessToken = '', path) {
    const baseUrl = process.env.NODE_ENV == 'development' ? 'http://localhost:9082/' : cpUrl
    let cpPath = ''
    if (path != null) cpPath = `&path=${path}`

    window.location =
      baseUrl +
      (method == 'logout' ? `logout?lang=${store.state.common.lang}` : `?accessToken=${base64AccessToken}${cpPath}`)
  },
}
