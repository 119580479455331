export default {
  en: 'English',
  home: {
    NewPortal: 'Cổng Mới',
    SecureIBPortal: 'Cổng IB',
    DASHBOARD: 'Bảng Điều Khiển',
    MARKETING_TOOLS: 'Công Cụ Marketing',
    IB_REPORT: 'Báo Cáo IB',
    REBATE_REPORT: 'Báo Cáo Hoa Hồng',
    IB_ACCOUNTS: 'Tài Khoản IB',
    PAYMENT_HISTORY: 'LỊCH SỬ GIAO DỊCH',
    IB_FAQ: 'Câu Hỏi Thường Gặp Về IB',
    RebateAccountNumber: 'Số Tài Khoản Hoa Hồng',
    totalBalance: 'Tổng Số Dư',
    totalCommission: 'Tổng Hoa Hồng',
    APPLY_FOR_A_REBATE: 'ỨNG DỤNG',
    availableBalance: 'Số Dư Khả Dụng',
    WITHDRAW_REBATE: 'Rút',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Chuyển khoản',
    MonthtoDate: 'Từ Đầu Tháng',
    UPDATE: 'CẬP NHẬT',
    REBATE: 'HOA HỒNG',
    TOTAL_VOLUME: 'TỔNG KHỐI LƯỢNG',
    NET_FUNDING: 'TIỀN NẠP RÒNG',
    DEPOSITS: 'Nạp Tiền',
    WITHDRAW: 'Rút Tiền',
    OPEND_ACCOUNT: 'Tài Khoản Đã Mở',
    PERFORMANCE: 'HIỆU SUẤT',
    OpendAccount: 'Tài Khoản Đã Mở',
    select_ib: 'VUI LÒNG CHỌN TÀI KHOẢN IB',
    MOST_TRADED_INSTRUMENTS: 'NHỮNG MÃ GIAO DỊCH NHIỀU NHẤT',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'NHỮNG TÀI KHOẢN HIỆU SUẤT TỐT NHẤT THÁNG NÀY',
    RECENTLY_OPENED_ACCOUNTS: 'NHỮNG TÀI KHOẢN MỚI MỞ',
    ACCOUNT_MANAGER: 'HỖ TRỢ KHÁCH HÀNG',
    Account: 'Tài Khoản',
    Name: 'Tên',
    Volume: 'Khối Lượng',
    Rebate: 'Hoa Hồng',
    Balance: 'Số Dư',
    ContactClient: 'HỖ TRỢ KHÁCH HÀNG',
    YouCannotAskForCommission: 'Bạn không thể yêu cầu một khoản hoa hồng',
    applicationFailedPleaseTryAgain: 'Yêu cầu thất bại, vui lòng thử lại sau',
    applyRebateSuccessTip:
      'Xin chúc mừng, chúng tôi đã nhận được yêu cầu rút hoa hồng của bạn. | {amount} sẽ được chuyển vào Tài khoản hoa hồng {account} trong vòng 1 ngày làm việc. | Bạn có thể rút hoặc chuyển tiền sau khi khoản hoa hồng của bạn được xử lý.',
    HOVER: 'Các khoản hoa hồng được tính toán và xử lý trong khoảng 09:00 - 11:00 AEST hàng ngày.',
  },
  liveChat: { header: 'Live Chat' },
  contactUs: {
    header: 'Liên Hệ Với Chúng Tôi',
    followUs: 'theo chúng tôi',
    tip:
      'Nhóm hỗ trợ khách hàng chuyên nghiệp đa ngôn ngữ của {platform} sẵn sàng hỗ trợ bạn và giúp cung cấp cho bạn trải nghiệm giao dịch không đâu có thể sánh bằng.',
  },
  ibAccounts: {
    header: 'Tài Khoản IB',
    ibAccount: 'Tài Khoản IB',
    subIbs: 'Tài Khoản IB Phụ',
    tradinglist: 'Danh sách các giao dịch',
    openlist: 'Mở danh sách',
    closedlist: 'Danh sách đã đóng',
    tradinglistColumn: {
      ticketid: 'Ticket ID',
      opentime: 'Thời gian mở',
      type: 'Loại',
      volume: 'Khối lượng',
      item: 'Khối lượng',
      openprice: 'Mở giá',
      closeprice: 'Đóng giá',
      closetime: 'Thời gian đóng',
      commission: 'Hoa hồng',
      profit: 'Lợi nhuận',
      buy: 'Mua',
      sell: 'Bán',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Chọn thẻ tín dụng',
      selectBA: 'Chọn tài khoản ngân hàng',
      anotherCC: 'Nhập thẻ tín dụng bằng cách thủ công',
      anotherBA: 'Thêm tài khoản ngân hàng',
      remember: 'Ghi nhớ tài khoản của tôi',
      verificationCode: 'Mã xác minh được gửi đến {email}. Vui lòng liên hệ {supportEmail} nếu bạn cần hỗ trợ thêm',
    },
    alert:
      'Do luật AML, phương thức bạn sử dụng để rút phải giống với phương thức được sử dụng để gửi tiền của bạn. Nếu số tiền rút ít hơn 100 đơn vị tiền tệ cơ sở trong tài khoản của bạn, bạn sẽ bị ngân hàng tính 20 đơn vị phí xử lý.',
    NetellerChargeAlert:
      'Rút tiền qua Neteller được giới hạn ở mức 10.000 đô la cho mỗi giao dịch và áp dụng phí 2% (giới hạn ở mức 30 đô la).',
    FasaPayChargeAlert: 'FasaPay tính phí giao dịch 0,5% cho mỗi lần rút tiền.',
    SkrillChargeAlert: 'Skrill tính phí giao dịch 1% cho mỗi lần rút tiền.',
    cryptoAlert:
      'Xin lưu ý rằng chúng tôi không thể nạp hoặc rút tiền qua BUSDT, vui lòng đảm bảo địa chỉ và Tiền điện tử khớp với chuỗi và đơn vị tiền tệ mà chúng tôi chấp nhận, nếu không bạn có thể bị mất tiền.',
    REBATE_WITHDRAW_REQUEST: 'YÊU CẦU RÚT HOA HỒNG',
    PleaseSelectDesiredWithdrawMethod: 'Vui lòng chọn phương thức rút tiền',
    BankName: 'Tên Ngân Hàng',
    BankAccountsName: 'Tên Tài Khoản Ngân Hàng',
    FasaPayAccountName: 'Tên Tài Khoản FasaPay',
    AustralianBankName: 'Tên Ngân Hàng Úc',
    BankAccountNumber: 'Số Tài Khoản Ngân Hàng',
    FasaPayAccountNumber: 'Số Tài Khoản FasaPay',
    BankAccountBeneficiary: 'Tài Khoản Thụ Hưởng',
    BSB: 'BSB',
    skrillEmail: 'Skrill Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    SticpayEmail: 'SticPay Email',
    BitcoinEmail: 'Địa chỉ ví Bitcoin',
    USDTEmail: 'Địa chỉ ví Tether',
    BankBranch: 'Chi Nhánh Ngân Hàng',
    Province: 'Tỉnh',
    City: 'Thành Phố',
    BankAddress: 'Địa Chỉ Ngân Hàng',
    bankCity: 'Tỉnh',
    bankProvince: 'Thành Phố',
    AccountNumberIBAN: 'Số Tài Khoản/IBAN',
    BankBeneficiaryName: 'Tên Người Thụ Hưởng Ngân Hàng',
    BankAccountBeneficiaryName: 'Tên Người Thụ Hưởng Ngân Hàng',
    AccountHoldersAddress: 'Tên Chủ Tài Khoản',
    SelectWithdrawCard: 'Chọn Loại Thẻ Bạn Muốn Rút',
    Swift: 'Mã SWIFT',
    SwiftAud: 'Mã SWIFT (Không phải tài khoản AUD)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: 'Ghi Chú Quan Trọng',
    YourPaymentGreaterThanAvailableBalance: 'Khoản thanh toán của bạn lớn hơn số dư khả dụng',
    CannotUseMoreThanTwoDecimalValues: 'Bạn không thể sử dụng Số và Số âm có nhiều hơn hai chữ số thập phân',
    YourPaymentIsBeingProcessed: 'Đơn xin rút tiền của bạn đã được gửi đi.',
    Itcannotbe0: 'Không thể điền 0.',
    noCardWarn: 'Bạn không có thẻ nào để rút tiền, vui lòng liên kết thẻ của bạn trong Chi Tiết Rút Tiền trước',
    amtLarger: 'Số tiền không thể để trống và phải lớn hơn hoặc bằng {minLimit} {currency}',
    blackList: `Rất tiếc, bạn hiện không thể gửi yêu cầu rút tiền. Vui lòng liên hệ {supportEmail} để biết thêm chi tiết.`,
    status: {
      withdrawalSubmitted: 'Đã gửi',
      withdrawalCanceled: 'Đã hủy',
      withdrawalAuditing: 'Đang Xử Lý',
      withdrawalRejected: 'Từ Chối',
      withdrawalAccepted: 'Đang Xử Lý',
      withdrawalPayFailed: 'Lỗi',
      withdrawalSuccess: 'Đã Xử Lý',
      withdrawalFailed: 'Lỗi',
      withdrawalPending: 'Tạm Ngưng',
      withdrawalPartialFailed: 'Lỗi',
      transferSubmitted: 'Đang Xử Lý',
      transferSuccess: 'Đã Xử Lý',
      transferRejected: 'Từ Chối',
      transferFailed: 'Lỗi',
      transferProcessing: 'Đang Xử Lý',
    },
    formErrorMsg: {
      bankName: 'Tên Ngân Hàng là bắt buộc',
      bsb: 'BSB là bắt buộc',
      beneName: 'Tên người thụ hưởng ngân hàng là bắt buộc',
      accNum: 'Số tài khoản ngân hàng là bắt buộc',
      swift: 'Mã Swift là bắt buộc',
      bankAddress: 'Địa chỉ ngân hàng là bắt buộc',
      holderAddress: 'Địa chỉ chủ tài khoản là bắt buộc',
      amt: 'Số tiền là bắt buộc',
      firstSixDigits: 'Vui lòng nhập 6 chữ số đầu tiên',
      lastFourDigits: 'Vui lòng nhập 4 chữ số cuối cùng',
      branchName: 'Tên chi nhánh là bắt buộc',
      email: 'Email là bắt buộc',
      accName: 'Tên tài khoản là bắt buộc',
      province: 'Địa chỉ ngân hàng (Tỉnh) là bắt buộc',
      city: 'Địa chỉ ngân hàng (Thành Phố) là bắt buộc',
      bankCard: 'Vui lòng chọn một thẻ',
      emailFormat: 'Vui lòng nhập địa chỉ email chính xác',
      amt0: 'Số tiền không thể là 0',
      bitcoin: 'Địa chỉ ví Bitcoin là bắt buộc',
      addressLength: 'Địa chỉ ví Bitcoin phải chứa từ 26-42 ký tự',
      usdt: 'Địa chỉ ví USDT là bắt buộc',
    },
    transfer: {
      info:
        '{platform} sẽ hoàn trả phí ngân hàng được tính cho rút tiền <u> ONE </ u> mỗi tháng. Rút tiền bổ sung trong tháng sẽ phải chịu một khoản phí ngân hàng là 20 đơn vị tiền tệ rút tiền của bạn.',
      label: { upload: 'Vui lòng tải lên bản sao bảng sao kê ngân hàng của bạn (trong vòng 3 tháng)' },
      formValidation: { upload: 'Vui lòng tải lên một sao kê ngân hàng' },
    },
    transitNumber: 'Số quá cảnh',
    institutionNumber: 'Số tổ chức',
  },
  rebate: { status: { processed: 'Đã Xử Lý', processing: 'Đang Xử Lý', rejected: 'Từ Chối' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'YÊU CẦU CHUYỂN HOA HỒNG',
    transferAmountIsGreaterThanAvailableBalance: 'Số tiền chuyển của bạn lớn hơn số dư khả dụng',
    yourRebateTransferHasBeenProcessed: 'Chuyển khoản hoa hồng của bạn đã được gửi',
    PleaseEnterTransferInformation: 'Vui lòng nhập thông tin chuyển khoản chính xác',
    tradingAccount: 'Tài khoản giao dịch',
    blackList:
      'Tài khoản này đã bị hạn chế thực hiện chuyển khoản giữa các tài khoản, vui lòng liên hệ với {supportEmail}',
  },
  common: {
    lang: {
      english: 'English',
      chinese: 'Tiếng Trung',
      french: 'Tiếng Pháp',
      thai: 'Tiếng Thái Lan',
      german: 'Tiếng Đức',
      spanish: 'Tiếng Tây Ban Nha',
      malay: 'Tiếng Malaysia',
      viet: 'Tiếng Việt',
      indo: 'Tiếng Indonesia',
      arabic: 'Tiếng Ả Rập',
    },
    button: { confirm: 'XÁC NHẬN', cancel: 'HỦY BỎ', sendCode: 'Gửi mã code', resendCode: 'Mã gửi lại' },
    withdrawChannel: {
      banktransfer: 'Chuyển khoản ngân hàng',
      aus: 'Chuyển khoản ngân hàng - Úc',
      int: 'Chuyển khoản ngân hàng - Quốc Tế',
      chn: 'Chuyển khoản ngân hàng - Trung Quốc',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Chuyển khoản UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Thẻ tín dụng',
      bpay: 'Bpay',
      creditdebitcard: 'Thẻ tín dụng / thẻ ghi nợ',
      bankwiretransferinternational: 'Chuyển khoản ngân hàng (quốc tế)',
      banktransferaustralia: 'Chuyển khoản ngân hàng (Úc)',
      banktransferinternational: 'Chuyển khoản ngân hàng (quốc tế)',
      banktransferchina: 'Chuyển khoản ngân hàng (quốc tế)',
      mobilepay: 'Thanh toán di động',
      brokertobrokertransfer: 'Chuyển từ nhà môi giới khác',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Ngân Hàng Điện Tử (Thái Lan)',
      internetbankingnigeria: 'Ngân Hàng Điện Tử (Nigeria)',
      internetbankingvietnam: 'Ngân Hàng Điện Tử (Việt Nam)',
      internetbankingmalaysia: 'Ngân Hàng Điện Tử (Malaysia)',
      internetbankingindonesia: 'Ngân Hàng Điện Tử (Indonesia)',
      internetbankingkorea: 'Ngân Hàng Điện Tử (Korea)',
      internetbankingphilippine: 'Ngân Hàng Điện Tử (Philippines)',
      internetbankingsouthafrica: 'Ngân Hàng Điện Tử (Nam Phi)',
      internetbankinguganda: 'Ngân Hàng Điện Tử (Uganda)',
      internetbankingrwanda: 'Ngân Hàng Điện Tử (Rwanda)',
      internetbankingzambia: 'Ngân Hàng Điện Tử (Zambia)',
      internetbankingcongo: 'Ngân Hàng Điện Tử (Congo)',
      internetbankingcameroon: 'Ngân Hàng Điện Tử (Cameroon)',
      internetbankingburundi: 'Ngân Hàng Điện Tử (Burundi)',
      internetbankingkenya: 'Ngân Hàng Điện Tử (Kenya)',
      internetbankingghana: 'Ngân Hàng Điện Tử (Ghana)',
      internetbankingtanzania: 'Ngân Hàng Điện Tử (Tanzania)',
      internetbankingcanada: 'Ngân Hàng Điện Tử (Canada)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Chuyển khoản ngân hàng Việt Nam',
      nigeriabanktransfer: 'Chuyển khoản ngân hàng Nigeria',
      polipayment: 'Thanh toán Poli',
      bankwiretransferaustralia: 'Chuyển khoản ngân hàng (Úc)',
      thailandinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Thái Lan',
      malaysiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Malaysia',
      banktransferbpaypolipay: 'Chuyển khoản ngân hàng / BPay / PoliPay',
      cryptocurrencybitcoin: 'Tiền điện tử-Bitcoin',
      cryptocurrencyusdt: 'Tiền điện tử-USDT',
      nigeriainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nigeria',
      vietnaminstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Việt Nam',
      indonesiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Indonesia',
      philippinesinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Philippines',
      southafricainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nam Phi',
      indiainstantbanktransfer: 'Chuyển khoản nhanh ngân hàng Ấn độ',
      southkoreabanktransfer: 'Chuyển khoản ngân hàng tức thì của Hàn Quốc',
      ugandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Uganda',
      rwandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Rwanda',
      zambiabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Zambia',
      congobanktransfer: 'Chuyển khoản Ngân hàng Địa phương Congo',
      cameroonbanktransfer: 'Chuyển khoản Ngân hàng Địa phương Cameroon',
      burundibanktransfer: 'Chuyển khoản Ngân hàng Địa phương Burundi',
      kenyabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Kenya',
      ghanabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Ghana',
      tanzaniabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Tanzania',
      banktransferbpaypolipayaustralia: 'Chuyển khoản ngân hàng / BPay / PoliPay (Úc)',
      banktransferbpaypolipayinternational: 'Chuyển khoản ngân hàng / BPay / PoliPay (Quốc tế)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Chuyển khoản Ngân hàng Nội địa Canada',
      cryptobitcoin: 'Tiền điện tử-Bitcoin',
      cryptotetheromni: 'Tiền điện tử-Tether(OMNI)',
      cryptotethererc20: 'Tiền điện tử-Tether(ERC20)',
      cryptotethertrc20: 'Tiền điện tử-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'Chuyển vào tài khoản',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'TIỀN TỆ',
      LOCAL_TRANSFER: 'CHUYỂN KHOẢN',
    },
    country: { international: 'Quốc Tế', australia: 'Úc' },
    field: {
      country_region: 'Quốc Gia / Khu Vực',
      address: 'Địa Chỉ ',
      phone: 'Số điện thoại',
      nat: 'Quốc tịch',
      country: 'Quốc gia cư trú',
      save: 'Đầu tư',
      annIncome: 'Thu nhập hàng năm',
      emailAdd: 'Địa chỉ email',
      yes: 'Có',
      no: 'Không',
      enterVerificationCode: 'Nhập mã xác minh',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'GỬI',
      FROM: 'TỪ',
      UPDATE: 'CẬP NHẬT',
      TO: 'ĐẾN',
      DATE: 'NGÀY',
      pleaseSelect: 'Vui lòng chọn',
      amount: 'Số Tiền',
      rebateAccount: 'Tài Khoản Hoa Hồng',
      comingSoon: 'Sẽ sớm ra mắt.',
      tradingHistory: 'Lịch Sử Giao Dịch',
      noData: 'Không có dữ liệu',
      NAME: 'TÊN',
      EMAIL: 'EMAIL',
      ACCTYPE: 'LOẠI TÀI KHOẢN',
      PLATFORM: 'NỀN TẢNG',
      BASECURRENCY: 'TIỀN TỆ CƠ SỞ',
      DATERANGE: 'KHOẢNG THỜI GIAN',
      STATUS: 'TRẠNG THÁI',
      DEST: 'ĐIỂM ĐẾN',
      ACCNUM: 'SỐ TÀI KHOẢN',
      BALANCE: 'SỐ DƯ',
      PROFITLOST: 'LỢI NHUẬN',
      MARGINLVL: 'CẤP MARGIN',
      ACCEQUITY: 'VỐN TÀI KHOẢN',
      CREDIT: 'TÍN DỤNG',
      NONE: 'None',
      ALL: 'Tất Cả',
      actualAmonut: 'số tiền thực tế',
      currency: 'TIỀN TỆ',
      method: 'PHƯƠNG THỨC',
    },
    dateRange: {
      today: 'Hôm Nay',
      yesterday: 'Hôm Qua',
      lastSevenDays: '7 ngày trước',
      lastThirtyDays: '30 ngày trước',
      weekToDate: 'Tuần này',
      monthToDate: 'Tháng này',
      quarterToDate: 'Quý này',
      previousWeek: 'Tuần Trước',
      previousMonth: 'Tháng Trước',
      previousQuarter: 'Qúy Trước',
    },
    products: { forex: 'NGOẠI HỐI', commodities: 'HÀNG HÓA', indices: 'CHỈ SỐ', crypto: 'TIỀN ĐIỆN TỬ' },
    formValidation: {
      dynamicReq: '{dynamic} là bắt buộc',
      verCodeReq: 'Mã xác minh bắt buộc',
      alphanumericReq: 'Vui lòng nhập chữ và số',
    },
  },
  report: {
    title: { rebateReport: 'Báo Cáo Hoa Hồng' },
    rebate: {
      column: {
        name: 'TÊN',
        accountNumber: 'SỐ TÀI KHOẢN',
        accountType: 'LOẠI TÀI KHOẢN',
        volume: 'Khối Lượng',
        rebate: 'Hoa Hồng',
        totalRebate: 'TỔNG HOA HỒNG',
        date: 'NGÀY',
        instrument: 'SẢN PHẨM GIAO DỊCH',
      },
      dropdown: { allInstruments: 'Tất cả sản phẩm', all: 'Tất Cả', instruments: 'SẢN PHẨM GIAO DỊCH' },
      others: {
        rebate: 'HOA HỒNG',
        instrumentsTraded: 'SẢN PHẨM ĐÃ GIAO DỊCH',
        lots: 'Lô',
        totalRebate: 'TỔNG HOA HỒNG',
        rebates: 'Hoa Hồng',
      },
    },
  },
  ibReport: {
    header: 'Báo Cáo IB',
    netFunding: 'TIỀN NẠP RÒNG',
    deposits: 'Nạp Tiền',
    withdraw: 'Rút Tiền',
    openedAccs: 'TÀI KHOẢN ĐÃ MỞ',
    funding: 'VỐN',
    withdrawals: 'RÚT TIỀN',
    appliedAmount: 'số tiền áp dụng',
    depositAmount: 'Số tiền đặt cọc',
    withdrawalAmount: 'Số tiền rút',
  },
  lead: { header: 'LEADS', ibAccount: 'Tài Khoản IB', demoAccounts: 'TÀI KHOẢN DEMO' },
  pendingAccounts: {
    header: 'TÀI KHOẢN TẠM NGƯNG',
    errorDoc:
      'Những người nộp đơn sau đây đã điền vào mẫu đơn trực tuyến nhưng chưa cung cấp bằng chứng nhận dạng (xác nhận ID) để hoàn tất tài khoản của họ.',
    acceptedDocType: 'Các hình thức ID được chấp nhận bao gồm:',
    primaryDoc: 'Chứng Minh Nhân Dân',
    passport: '1 bản copy hộ chiếu (Trang có chữ ký)',
    id: '1 bản copy thẻ căn cước',
    dl: '1 bản copy giấy phép lái xe vẫn còn hạn sử dụng',
    address: 'ID thứ hai',
    addressList: [
      'Bản sao Hóa đơn Tiện ích gần đây (không quá 6 tháng)',
      'Bản sao sao kê thẻ tín dụng (không quá 6 tháng)',
      'Bản sao bảng sao kê ngân hàng (không quá 6 tháng)',
    ],
    tip:
      'Vui lòng khuyến khích người nộp đơn cung cấp bản sao của MỘT trong số các tài liệu ID chính và phụ được chấp nhận cho',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'LỊCH SỬ GIAO DỊCH',
    rebateHistory: 'LỊCH SỬ HOA HỒNG',
    withdrawHistory: 'LỊCH SỬ RÚT TIỀN',
    transferHistory: 'LỊCH SỬ CHUYỂN TIỀN',
    cancelWithdraw: `Bạn có muốn Hủy Yêu cầu Rút tiền không?`,
    cancelError: `Việc rút tiền của bạn đang được xử lý vào lúc này. Vì vậy, chúng tôi không thể chấp nhận yêu cầu hủy của bạn. Để biết thêm thông tin, bạn có thể liên hệ với chúng tôi bằng cách sử dụng tính năng trò chuyện trực tiếp.`,
  },
  fileUploader: {
    supportedFormat: 'Các loại tệp được hỗ trợ: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Kích thước tệp tải lên tối đa: {maxSize} MB',
    maxFileNum: 'Số tệp tối đa: {maxFileNum}',
    selectFile: 'Chọn tập tin',
  },
  transferHistory: {
    payStatus: { processing: 'Đang Xử Lý', paid: 'Đã thanh toán', unpaid: 'Chưa thanh toán', rejected: 'Từ Chối' },
  },
  referralLinks: {
    header: 'LINKS GIỚI THIỆU',
    ibAcc: 'Tài Khoản IB',
    campLang: 'Ngôn ngữ chiến dịch',
    cpy: 'SAO CHÉP',
    alreadyCPY: 'ĐÃ SAO CHÉP',
    liveAcc: 'Tài Khoản Live',
    demoAcc: 'Tài Khoản Demo',
    homePage: 'Trang Chủ',
    Share: 'Cổ phiếu',
  },
  settings: { ibProfile: 'HỒ SƠ IB', info: 'THÔNG TIN', reg: 'ĐÃ ĐĂNG KÝ TRÊN' },
  myProfile: {
    changeDetails: 'Nếu bạn muốn cập nhật chi tiết hồ sơ hiện tại của mình, đừng ngần ngại liên hệ với {mailTo}',
  },
  unfundedAcc: {
    header: 'TÀI KHOẢN CHƯA NẠP TIỀN',
    allActivationAccs: 'TẤT CẢ TÀI KHOẢN KÍCH HOẠT',
    neverFundedAccs: 'TÀI KHOẢN CHƯA TỪNG NẠP TIỀN',
    prevFundedZero: 'SỐ DƯ LÀ 0',
  },
  multiib: {
    level: 'CẤP ĐỘ {level}',
    noib: 'Không có IB cấp dưới của tài khoản này',
  },
  header: { bckToCP: 'TRỞ LẠI CỔNG KHÁCH HÀNG', logout: 'ĐĂNG XUẤT' },
  menu: {
    dashboard: 'Bảng Điều Khiển',
    ibReport: 'Báo Cáo IB',
    rebateReport: 'Báo Cáo Hoa Hồng',
    accManagement: 'TÀI KHOẢN',
    iblevel: 'ĐA CẤP ĐỘ',
    ibAccs: 'TÀI KHOẢN ĐÃ KÍCH HOẠT',
    leads: 'LEADS',
    pendingAccs: 'TÀI KHOẢN TẠM NGƯNG',
    unfundedAccs: 'TÀI KHOẢN CHƯA NẠP TIỀN',
    payHistory: 'LỊCH SỬ GIAO DỊCH',
    ibProfile: 'HỒ SƠ IB',
    refLinks: 'LINKS GIỚI THIỆU',
    contactUs: 'Liên Hệ Với Chúng Tôi',
    switchCP: 'ĐỔI SANG CỔNG KHÁCH HÀNG',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'Cảnh báo Rủi ro: Giao dịch Hợp đồng Chênh lệch (CFD) mang lại mức độ rủi ro cao cho số vốn của bạn và có thể dẫn đến thua lỗ, bạn chỉ nên giao dịch với số tiền mà bạn có thể chịu được. Giao dịch CFD có thể không phù hợp với tất cả các nhà đầu tư, vui lòng đảm bảo rằng bạn hiểu đầy đủ các rủi ro liên quan và thực hiện các biện pháp thích hợp để quản lý nó. Vui lòng đọc kỹ tài liệu Tiết lộ Rủi ro có liên quan.',
  },
  responseMsg: {
    410: 'Xác thực thông số không thành công',
    411: 'Mật khẩu không đúng',
    420: 'Người dùng không tồn tại',
    421: 'Đặt lại mật khẩu bị từ chối',
    490: 'Vui lòng không gửi thông tin thẻ trùng lặp',
    500: 'Một lỗi đã xảy ra.',
    501: 'Đăng ký thất bại. Vui lòng thử lại sau.',
    505: 'Tải lên tệp không thành công',
    520: 'Đăng nhập thất bại. Vui lòng thử lại.',
    521: 'Một người dùng đã đăng nhập vào thiết bị này!',
    522: 'Xin vui lòng đăng nhập lại',
    523: 'Xin vui lòng đăng nhập lại',
    524: 'Thật không may, địa chỉ email của bạn không tìm thấy.',
    525: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    527: 'Người dùng không phải là một IB',
    528: 'Người dùng không tồn tại',
    529: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    540: 'Không thể tìm thấy tài khoản giao dịch của bạn ',
    541: 'Không thể tìm thấy tài khoản nhận hoa hồng',
    542: 'Bạn không được phép mở thêm tài khoản',
    544: 'Bạn không được phép đăng ký thêm tài khoản cho đến khi Bằng chứng ID của bạn được xác minh',
    550: 'Số tài khoản không chính xác',
    551: 'Số dư của bạn không đủ',
    553: 'Bạn không thể rút tiền do khoản tín dụng trong tài khoản của bạn. Vui lòng liên hệ với {email} để được trợ giúp thêm',
    554: 'Số lượng yêu cầu là 0 hoặc trống',
    564: 'Quốc gia không khớp với kênh thanh toán',
    565: 'Mã ngân hàng không chính xác',
    566: 'Không tìm thấy phương thức thanh toán',
    581: 'Bạn đã chọn tham gia chương trình khuyến mãi thành công. Xin lưu ý rằng bạn phải đặt cọc để rút tiền.',
    582: 'Bạn đang tham gia sự kiện và có các chức vụ nên không thể gửi đơn rút tiền.',
    584: 'Bạn không có bất kỳ khoản hoa hồng nào',
    590: 'Không thể tìm thấy tài khoản chuyển khoản đi',
    591: 'Không thể tìm thấy tài khoản chuyển khoản vào',
    593: 'Chúng tôi không thể xử lý yêu cầu chuyển tiền của bạn từ tài khoản đã chọn do tài khoản của bạn đang chứa tín dụng.',
    594: 'Chuyển tiền từ tài khoản này không được cho phép',
    595: 'Vì bạn đang tham gia vào các hoạt động của chúng tôi, nên không thể xử lý yêu cầu chuyển tiền của bạn vào lúc này.',
    647: 'Mã xác minh không đúng',
    session_timeout: 'Qúa thời gian chờ, chuyển hướng đến trang đăng nhập.',
  },
}
