import Vue from 'vue'
import Drawer from './elementUI/Drawer'
import Dialog from './elementUI/Dialog'
import Pagination from './elementUI/Pagination'
import PageWrapper from './page/PageWrapper'
import PagePanel from './page/PagePanel'
import SvgIcon from './customUI/SvgIcon'
import StatusMessage from './customUI/StatusMessage.vue'
import AccountSelect from './customUI/AccountSelect.vue'
import Empty from './customUI/Empty.vue'
import PageSearchArea from './customUI/PageSearchArea.vue'

Vue.component(Drawer.name, Drawer)
Vue.component(Dialog.name, Dialog)
Vue.component(Pagination.name, Pagination)
Vue.component(SvgIcon.name, SvgIcon)
Vue.component(PageWrapper.name, PageWrapper)
Vue.component(PagePanel.name, PagePanel)
Vue.component(StatusMessage.name, StatusMessage)
Vue.component(AccountSelect.name, AccountSelect)
Vue.component(Empty.name, Empty)
Vue.component(PageSearchArea.name, PageSearchArea)
