export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  home: {
    NewPortal: 'Nouveau Portail',
    SecureIBPortal: `Portail IB sécurisé`,
    DASHBOARD: 'TABLEAU DE BORD',
    MARKETING_TOOLS: 'OUTILS MARKETING',
    IB_REPORT: `RAPPORT DE IB`,
    REBATE_REPORT: 'RAPPORT DE BONUS',
    IB_ACCOUNTS: `COMPTES IB`,
    PAYMENT_HISTORY: 'HISTORIQUE DES TRANSACTIONS',
    IB_FAQ: `Fréquemment Posées DE IB`,
    RebateAccountNumber: 'Numéro de bonus',
    totalBalance: 'solde totale',
    totalCommission: 'bonus totale',
    APPLY_FOR_A_REBATE: 'DEMANDER',
    availableBalance: 'solde disponible',
    WITHDRAW_REBATE: 'Se désister',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFÉRER',
    MonthtoDate: 'Le mois courant',
    UPDATE: 'MISE À JOUR',
    REBATE: 'Bonus',
    TOTAL_VOLUME: 'Volume TOTALE',
    NET_FUNDING: 'PAIEMENT NET',
    DEPOSITS: 'DÉPÔTS',
    WITHDRAW: 'RETRAIT',
    OPEND_ACCOUNT: 'COMPTE OUVERT',
    PERFORMANCE: 'PERFORMANCE',
    OpendAccount: 'Compte ouvert',
    select_ib: 'VEUILLEZ SÉLECTIONNER LE COMPTE IB',
    MOST_TRADED_INSTRUMENTS: 'LES INSTRUMENTS LES PLUS ÉCHANGÉS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'LES COMPTES LES PLUS PERFORMANTS DE CE MOIS',
    RECENTLY_OPENED_ACCOUNTS: 'COMPTES RÉCEMMENT OUVERTS',
    ACCOUNT_MANAGER: 'SERVICE CLIENT DÉDIÉ',
    Account: 'Compte',
    Name: 'Nom',
    Volume: 'Volume',
    Rebate: 'Bonus',
    Balance: 'Équilibre',
    ContactClient: 'Contacter',
    YouCannotAskForCommission: 'Vous ne pouvez pas demander de bonus',
    applicationFailedPleaseTryAgain: `L'application a échoué, veuillez réessayer plus tard`,
    applyRebateSuccessTip: `
    Félicitations, nous avons reçu votre demande de remise. | {montant} sera transféré sur le compte de remise {compte} dans un délai d'un jour ouvrable. | Vous pouvez retirer ou transférer des fonds une fois votre remise traitée.`,
    HOVER: 'Les remises de la veille sont calculées et traitées quotidiennement entre 9:00 et 11:00 AEST.',
  },
  liveChat: {
    header: 'Chat en direct',
  },
  contactUs: {
    header: 'NOUS CONTACTER',
    followUs: 'Suivez nous',
    tip: `L'équipe multilingue mondiale de {platform} est là pour vous aider et vous offrir une expérience de trading inégalée.`,
  },
  ibAccounts: {
    header: `COMPTES IB`,
    ibAccount: `COMPTE IB`,
    subIbs: `Compte de IB secondaire`,
    tradinglist: `Liste d'échange`,
    openlist: 'Ouvrir la liste',
    closedlist: 'Liste fermée',
    tradinglistColumn: {
      ticketid: 'ID de billets',
      opentime: `Heure d'ouverture`,
      type: 'Taper',
      volume: 'Le volume',
      item: 'Article',
      openprice: 'Prix Ouvert',
      closeprice: 'Fermer le prix',
      closetime: 'La période de fermeture',
      commission: 'Commission',
      profit: 'Profit',
      buy: 'Acheter',
      sell: 'Vendre',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Sélectionner une carte de crédit',
      selectBA: 'Sélectionner un compte en banque',
      anotherCC: 'Saisissez manuellement la carte de crédit',
      anotherBA: 'Ajouter un compte en banque',
      remember: 'Se souvenir de mon compte',
      verificationCode:
        'Le code de vérification a été envoyé à {email}. Contactez-nous l’adresse à {supportEmail} si vous avez besoin d’une assistance supplémentaire',
    },
    alert:
      'En raison des lois AML, la méthode que vous utilisez pour retirer doit être la même que la méthode utilisée pour votre dépôt. Si le montant du retrait est inférieur à 100 unités de la devise de base de votre compte, des frais de traitement vous seront facturés 20 unités par la banque.',
    NetellerChargeAlert:
      "Les retraits Neteller sont limités à 10 000 $ par transaction et des frais de 2 % (plafonnés à 30 $) s'appliquent.",
    FasaPayChargeAlert: 'FasaPay facture des frais de transaction de 0,5% pour chaque retrait.',
    SkrillChargeAlert: 'Skrill facture des frais de transaction de 1% pour chaque retrait.',
    cryptoAlert:
      "Veuillez noter que nous ne sommes pas en mesure d'effectuer des dépôts ou des retraits via BUSDT. Assurez-vous que l'adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons, sinon vous pourriez perdre les fonds.",
    REBATE_WITHDRAW_REQUEST: 'DEMANDE DE RETRAIT DE BONUS',
    PleaseSelectDesiredWithdrawMethod: 'Veuillez sélectionner la méthode de retrait souhaitée',
    BankName: 'Nom de banque',
    BankAccountsName: 'Nom des comptes bancaires',
    FasaPayAccountName: 'Nom du compte FasaPay',
    AustralianBankName: 'Nom de la banque australienne',
    BankAccountNumber: 'Numéro de compte bank',
    FasaPayAccountNumber: 'Numéro de compte FasaPay',
    BankAccountBeneficiary: 'Bénéficiaire du compte bancaire',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill',
    NetellerEmail: 'Email Neteller',
    PerfectMoneyEmail: 'Email Perfect Money',
    SticpayEmail: 'Email SticPay',
    BitwalletEmail: 'Email Bitwallet',
    BitcoinEmail: 'Adresse du portefeuille Bitcoin',
    USDTEmail: 'Adresse du portefeuille Tether',
    BankBranch: 'Agence Bancaire',
    Province: 'Province',
    City: 'Ville',
    BankAddress: 'Adresse de la banque',
    bankCity: 'Ville de la banque',
    bankProvince: 'Province de la banque',
    AccountNumberIBAN: 'Numéro de compte/IBAN',
    BankBeneficiaryName: 'Nom du bénéficiaire de la banque',
    BankAccountBeneficiaryName: 'Nom du bénéficiaire du compte bancaire',
    AccountHoldersAddress: 'Adresse du titulaire du compte',
    Swift: 'Swift',
    SwiftAud: 'Swift(Comptes non AUD)',
    ABA_SortCodeABA: 'ABA/Code de Sort',
    ImportantNotes: 'Notes IMPORTANTES',
    YourPaymentGreaterThanAvailableBalance: 'Votre paiement est supérieur au solde disponible',
    CannotUseMoreThanTwoDecimalValues:
      'Vous ne pouvez pas utiliser des nombres et des nombres négatifs avec plus de deux décimales',
    YourPaymentIsBeingProcessed: 'Votre demande de retrait a été soumise',
    Itcannotbe0: 'Ça ne peut pas être 0',
    amtLarger: 'Le montant ne peut pas être nul et doit être supérieur ou égal à {minLimit} {currency}',
    blackList: `Malheureusement, vous n'êtes actuellement pas en mesure de soumettre une demande de retrait. Contactez-nous à {supportEmail} pour plus d'informations`,
    status: {
      withdrawalSubmitted: 'Soumis',
      withdrawalCanceled: 'Annulé',
      withdrawalAuditing: 'En cours',
      withdrawalRejected: 'Rejeté',
      withdrawalAccepted: 'En cours',
      withdrawalPayFailed: 'Échec',
      withdrawalSuccess: 'Traité',
      withdrawalFailed: 'Échec',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Échec',
      transferSubmitted: 'En cours',
      transferSuccess: 'Traité',
      transferRejected: 'Rejeté',
      transferFailed: 'Échec',
      transferProcessing: 'En cours',
    },
    formErrorMsg: {
      bankName: 'Le nom de la banque est requis',
      bsb: 'BSB est requis',
      beneName: 'Le nom du bénéficiaire de la banque est requis',
      accNum: 'Le numéro de compte bancaire est requis',
      swift: 'Swift est requis',
      bankAddress: `L'adresse bancaire est obligatoire`,
      holderAddress: `L'adresse du titulaire est obligatoire`,
      amt: 'Un montant est requis',
      firstSixDigits: 'Veuillez entrer les 6 premiers chiffres',
      lastFourDigits: 'Veuillez entrer les 4 premiers chiffres',
      branchName: 'Le nom de la succursale est obligatoire',
      email: `L'email est requis`,
      accName: 'Le nom du compte est obligatoire',
      province: 'La province de la banque est obligatoire',
      city: 'Banque Ville est requis',
      emailFormat: 'Veuillez entrer une adresse e-mail correcte',
      amt0: 'Le montant ne peut pas être égal à 0',
      bitcoin: `L'adresse du portefeuille Bitcoin est requise`,
      addressLength: "L'adresse du portefeuille en Bitcoin doit comporter entre 26 et 42 caractères",
      usdt: `L'adresse du portefeuille USDT est requise`,
    },
    transfer: {
      info:
        '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      label: {
        upload: `Veuillez télécharger une capture d'écran, une photo ou une copie numérisée de votre relevé bancaire (couvrant les 3 derniers mois)`,
      },
      formValidation: {
        upload: 'Veuillez télécharger un relevé bancaire',
      },
    },
    transitNumber: 'Numéro de transit',
    institutionNumber: `Numéro de l'établissement`,
  },
  rebate: {
    status: {
      processed: 'Traité',
      processing: 'En traitement',
      rejected: 'Rejeté',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'DEMANDE DE TRANSFERT DE BONUS',
    transferAmountIsGreaterThanAvailableBalance: 'Le montant de votre transfert est supérieur au solde disponible',
    yourRebateTransferHasBeenProcessed: 'votre transfert de remise a été soumis',
    PleaseEnterTransferInformation: 'Veuillez saisir des informations de transfert précises',
    tradingAccount: 'Un compte de commerce',
    rebateAccount: 'Compte de bonus',
    account: 'Compte',
    blackList: `Ce compte ne comporte pas d'autorisation pour effectuer un transfert entre comptes. Contactez-nous à {supportEmail}`,
  },
  common: {
    liveChat: { desc: `Besoin d'aide ? Chat en direct` },
    lang: {
      english: 'Anglais',
      chinese: 'Chinois',
      french: 'Français',
      thai: 'Thaïlandais',
      german: 'Allemand',
      spanish: 'Espagnol',
      malay: 'Malais',
      viet: 'Vietnamien',
      indo: 'Indonésien',
      arabic: 'Arabe',
    },
    button: {
      sendCode: 'Envoyer le code',
      resendCode: 'Renvoyer le code',
    },
    withdrawChannel: {
      banktransfer: 'Virement',
      aus: 'Virement bancaire - Australie',
      int: 'Virement bancaire - International',
      chn: 'Virement bancaire - Chine',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'Virement UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Carte de crédit',
      bpay: 'Bpay',
      creditdebitcard: 'Carte de crédit/débit',
      bankwiretransferinternational: 'Virement bancaire (International)',
      banktransferaustralia: 'Virement bancaire (Australie)',
      banktransferinternational: 'Virement bancaire (International)',
      banktransferchina: 'Virement bancaire (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Virement de courtier à courtier',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banque virtuelle (Thaïlande)',
      internetbankingnigeria: 'Banque virtuelle (Nigeria)',
      internetbankingvietnam: 'Banque virtuelle (Vietnam)',
      internetbankingmalaysia: 'Banque virtuelle (Malaisie)',
      internetbankingindonesia: 'Banque virtuelle (Indonésie)',
      internetbankingkorea: 'Banque virtuelle (Corée du Sud)',
      internetbankingindia: 'Banque virtuelle (Inde)',
      internetbankingphilippine: 'Banque virtuelle (Philippines)',
      internetbankingsouthafrica: 'Banque virtuelle (Afrique du Sud)',
      internetbankinguganda: 'Banque virtuelle (Ouganda)',
      internetbankingrwanda: 'Banque virtuelle (Rwanda)',
      internetbankingzambia: 'Banque virtuelle (Zambie)',
      internetbankingcongo: 'Banque virtuelle (Congolais)',
      internetbankingcameroon: 'Banque virtuelle (Cameroun)',
      internetbankingburundi: 'Banque virtuelle (Burundi)',
      internetbankingkenya: 'Banque virtuelle (Kenya)',
      internetbankingghana: 'Banque virtuelle (Ghana)',
      internetbankingtanzania: 'Banque virtuelle (Tanzanie)',
      internetbankingcanada: 'Banque virtuelle (Canada)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Virement bancaire Vietnam',
      nigeriabanktransfer: 'Virement bancaire Nigeria',
      polipayment: 'Paiement POLi',
      bankwiretransferaustralia: 'Virement bancaire (Australie)',
      thailandinstantbankwiretransfer: 'Virement bancaire instantané en Thaïlande',
      malaysiainstantbankwiretransfer: 'Virement bancaire instantané en Malaisie',
      banktransferbpaypolipay: 'Virement/BPay/PoliPay',
      cryptocurrencybitcoin: 'Crypto-monnaie-Bitcoin',
      cryptocurrencyusdt: 'Crypto-monnaie-USDT',
      nigeriainstantbankwiretransfer: 'Virement bancaire express Nigérian',
      vietnaminstantbankwiretransfer: 'Virement bancaire express vietnamien',
      indonesiainstantbankwiretransfer: 'Virement bancaire express Indonesia',
      philippinesinstantbankwiretransfer: 'Virement bancaire express Philippines',
      southafricainstantbankwiretransfer: 'Virement bancaire express Afrique du Sud',
      paygindiainstantbanktransferate: 'Virement bancaire express Inde',
      southkoreabanktransfer: 'Virement bancaire instantané en Corée du Sud',
      ugandabanktransfer: 'Virement bancaire local en Ouganda',
      rwandabanktransfer: 'Virement bancaire local au Rwanda',
      zambiabanktransfer: 'Virement bancaire local en Zambie',
      congobanktransfer: 'Virement bancaire local au Congo',
      cameroonbanktransfer: 'Virement bancaire local au Cameroun',
      burundibanktransfer: 'Virement bancaire local au Burundi',
      kenyabanktransfer: 'Virement bancaire local au Kenya',
      ghanabanktransfer: 'Virement bancaire local au Ghana',
      tanzaniabanktransfer: 'Virement bancaire local en Tanzanie',
      banktransferbpaypolipayaustralia: 'Virement bancaire/BPay/POLiPay (Australie)',
      banktransferbpaypolipayinternational: 'Virement bancaire/BPay/POLiPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Virement bancaire local canadien',
      cryptobitcoin: 'Crypto-monnaie-Bitcoin',
      cryptotetheromni: 'Crypto-monnaie-Tether(OMNI)',
      cryptotethererc20: 'Crypto-monnaie-Tether(ERC20)',
      cryptotethertrc20: 'Crypto-monnaie-Tether(TRC20)',
      cryptocurrencybitcoin: 'Crypto-monnaie-Bitcoin',
      cryptocurrencyusdt: 'Crypto-monnaie-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'Transfert aux comptes',
      E_WALLET: 'PORTEFEUILLE ÉLECTRONIQUE',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'TRANSFERT LOCAL',
    },
    country: { international: 'International', australia: 'Australie' },
    field: {
      country_region: 'Pays/Région',
      address: 'Adresse',
      phone: 'Numéro de téléphone',
      nat: 'Nationalité',
      country: 'Pays de résidence',
      save: 'Investissement',
      annIncome: 'Revenu annuel',
      emailAdd: 'Adresse e-mail',
      yes: 'Oui',
      no: 'Non',
      enterVerificationCode: 'Saisissez le code de vérification',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'SOUMETTRE',
      FROM: 'DE',
      UPDATE: 'MISE À JOUR',
      TO: 'À',
      DATE: 'DATE',
      pleaseSelect: 'Veuillez sélectionner',
      amount: 'Montant',
      rebateAccount: 'Compte de bonus',
      comingSoon: 'bientôt disponible',
      tradingHistory: 'HISTOIRE DU COMMERCE',
      noData: 'Pas de données',
      NAME: 'NOM',
      EMAIL: 'EMAIL',
      ACCTYPE: 'TYPE DE COMPTE',
      PLATFORM: 'PLATE-FORME',
      BASECURRENCY: 'DEVISE',
      DATERANGE: 'PLAGE DE DATES',
      STATUS: 'STATUT',
      DEST: 'DESTINATION',
      ACCNUM: 'NUMÉRO DE COMPTE',
      BALANCE: 'ÉQUILIBRE',
      PROFITLOST: 'PROFIT',
      MARGINLVL: 'NIVEAU DE MARGE',
      ACCEQUITY: 'CAPITAUX PROPRES',
      CREDIT: 'CRÉDIT',
      NONE: 'Aucun',
      ALL: 'Tout',
      actualAmonut: 'montant actuel',
      currency: 'DEVISE',
      method: 'MÉTHODE',
    },
    dateRange: {
      today: `Aujourd'hui`,
      yesterday: 'Hier',
      lastSevenDays: 'Les 7 derniers jours',
      lastThirtyDays: 'Les 30 derniers jours',
      weekToDate: 'À ce jour',
      monthToDate: 'Le mois courant',
      quarterToDate: 'Trimestre à ce jour',
      previousWeek: 'Semaine précédente',
      previousMonth: 'Le mois précédent',
      previousQuarter: 'Trimestre précédent',
    },
    products: {
      forex: 'FOREX',
      commodities: 'MATIÈRES PREMIÈRES',
      indices: 'INDICES',
      crypto: 'CRYPTO',
    },
    formValidation: {
      dynamicReq: '{dynamic} est requis',
      verCodeReq: 'Le code de vérification est obligatoire',
      alphanumericReq: 'Veuillez saisir alphanumérique',
    },
  },
  report: {
    title: {
      rebateReport: 'RAPPORT DE BONUS',
    },
    rebate: {
      column: {
        name: 'NOM',
        accountNumber: 'NUMÉRO DU COMPTE',
        accountType: 'TYPE DE COMPTE',
        volume: 'Volume',
        rebate: 'Bonus',
        totalRebate: 'BONUS TOTALE',
        date: 'DATE',
        instrument: 'INSTRUMENT',
      },
      dropdown: {
        allInstruments: 'Tous les instruments',
        all: 'Tous',
        instruments: 'Instruments',
      },
      others: {
        rebate: 'Bonus',
        instrumentsTraded: 'INSTRUMENTS TRADÉS',
        lots: 'Lots',
        totalRebate: 'BONUS TOTALE',
        rebates: 'Bonus',
      },
    },
  },
  ibReport: {
    header: `RAPPORT IB`,
    netFunding: 'PAIEMENT NET',
    deposits: 'DÉPÔTS',
    withdraw: 'RETRAIT',
    openedAccs: 'COMPTES OUVERTS',
    funding: 'PAIEMENT',
    withdrawals: 'RETRAIT',
    appliedAmount: 'montant appliqué',
    depositAmount: 'Montant du dépôt',
    withdrawalAmount: 'Montant de retrait',
  },
  lead: {
    header: 'OPPORTUNITES',
    ibAccount: `COMPTE IB`,
    demoAccounts: 'COMPTES DÉMO',
  },
  pendingAccounts: {
    header: 'COMPTES EN ATTENTE',
    errorDoc: `Les demandeurs suivants ont rempli le formulaire de demande en ligne, mais doivent encore fournir un justificatif d'identité afin de finaliser la création de leur compte.`,
    acceptedDocType: `Les pièces d'identité acceptées comprennent:`,
    primaryDoc: `Pièce d'identité principale`,
    passport: 'Une copie de votre passeport international valide (page de signature)',
    id: `Une copie de votre carte d'identité nationale délivrée par l'administration`,
    dl: 'Une copie de votre permis de conduire valide',
    address: `Autre pièce d'identité`,
    addressList: [
      `Copie d'une facture de services publics récente (datant de moins de 6 mois)`,
      `Copie d'un relevé de carte de crédit (pas plus de 6 mois)`,
      `Copie d'un relevé bancaire (pas plus de 6 mois)`,
    ],
    tip: `Veuillez inviter les demandeurs à fournir une copie d'UN des justificatifs d'identité principaux et autres pour `,
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'HISTORIQUE DES TRANSACTIONS',
    rebateHistory: 'BONUS',
    transferHistory: 'TRANSFERTS',
    withdrawHistory: 'RETRAITS',
    cancelWithdraw: `Souhaitez-vous annuler la demande de retrait ?`,
    cancelError: `Votre retrait est en cours de traitement en ce moment. Par conséquent, nous ne pouvons pas accepter votre demande d'annulation. Pour plus d'informations, vous pouvez nous joindre en utilisant la fonction de chat en direct.`,
  },
  fileUploader: {
    supportedFormat: 'Types de fichier pris en charge : bmp, jpg, gif, jpeg, png, tiff, doc, pdf',
    maxSize: 'Taille maximale du fichier de téléchargement : {maxSize} Mo',
    maxFileNum: 'Nombre maximum de fichiers : {maxFileNum}',
    selectFile: 'Sélectionner un fichier',
  },
  transferHistory: {
    payStatus: {
      processing: 'En cours',
      paid: 'Payé',
      unpaid: 'Non payé',
      rejected: 'Rejeté',
    },
  },
  referralLinks: {
    header: 'LIENS DE RÉFÉRENCE',
    ibAcc: `Compte de l'apporteur d'affaires`,
    campLang: 'Langue de la campagne',
    cpy: 'COPIER',
    alreadyCPY: 'COPIÉ !',
    liveAcc: 'Compte en direct',
    demoAcc: 'Compte démo',
    homePage: `Page d'accueil`,
    Share: 'Partager',
  },
  settings: {
    ibProfile: `PROFIL IB`,
    info: 'INFOS',
    reg: `DATE D'INSCRIPTION`,
  },
  myProfile: {
    changeDetails: `Si vous souhaitez mettre à jour les détails de votre profil existant, n'hésitez pas à contacter {mailTo}`,
  },
  unfundedAcc: {
    header: 'COMPTES NON APPROVISIONNÉS',
    allActivationAccs: `TOUS LES COMPTES D'ACTIVATION`,
    neverFundedAccs: 'COMPTES JAMAIS APPROVISIONNÉS',
    prevFundedZero: 'SOLDE NUL',
  },
  multiib: {
    level: 'NIVEAU {level}',
    noib: 'Aucun sous-CR dans ce compte.',
  },
  header: {
    bckToCP: 'RETOUR AU PORTAIL CLIENT',
    logout: 'DÉCONNEXION',
  },
  menu: {
    dashboard: 'TABLEAU DE BORD',
    ibReport: `RAPPORT IB`,
    rebateReport: 'RAPPORT DE BONUS',
    accManagement: 'GESTION DES COMPTES',
    iblevel: 'CR MULTI-NIVEAU',
    ibAccs: `COMPTES ACTIVÉS`,
    leads: 'NOUVEAUX CLIENTS',
    pendingAccs: 'COMPTES EN ATTENTE',
    unfundedAccs: 'COMPTES NON APPROVISIONNÉS',
    payHistory: 'HISTORIQUE DES TRANSACTIONS',
    ibProfile: `PROFIL IB`,
    refLinks: 'LIENS DE RÉFÉRENCE',
    contactUs: 'NOUS CONTACTER',
    switchCP: 'BASCULER VERS LE PORTAIL CLIENT',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn: `Avertissement sur les risques: le trading de contrats sur différence (CFD) comporte un niveau de risque élevé pour votre capital et peut entraîner des pertes, vous ne devriez négocier qu'avec de l'argent que vous pouvez vous permettre de perdre. Le trading de CFD peut ne pas convenir à tous les investisseurs, veuillez vous assurer de bien comprendre les risques encourus et de prendre les mesures appropriées pour les gérer. Veuillez lire attentivement le document de divulgation des risques pertinent.`,
  },
  responseMsg: {
    //4xx warning
    410: 'Échec de la validation du paramètre',
    411: 'Mot de passe incorrect',
    420: `L'utilisateur n'existe pas`,
    421: 'Réinitialisation du mot de passe refusée',
    490: 'Ne soumettez pas les informations de votre carte en double',
    //5xx error
    500: `Une erreur s'est produite.`,
    501: 'Échec de la demande. Veuillez réessayer plus tard.',
    505: 'Échec du téléchargement du fichier',
    520: 'Échec de connexion. Veuillez réessayer.',
    521: 'Un utilisateur est déjà connecté sur cet appareil !',
    522: 'Veuillez vous reconnecter',
    523: 'Veuillez vous reconnecter',
    524: `Malheureusement votre adresse e-mail n'a pas été reconnue`,
    525: `Nom d'utilisateur ou mot de passe incorrect`,
    527: `L'utilisateur n'est pas un apporteur d'affaires`,
    528: `L'utilisateur n'existe pas`,
    529: `Nom d'utilisateur ou mot de passe incorrect`,
    540: `Votre compte de trading est introuvable`,
    541: 'Compte de bonus introuvable',
    542: `Vous n'êtes pas autorisé à ouvrir un compte supplémentaire`,
    544: `Vous n'êtes pas autorisé à demander un compte supplémentaire tant que votre pièce d'identité n'a pas été vérifiée`,
    550: 'Numéro de compte incorrect',
    551: 'Votre solde est insuffisant',
    553: `Vous ne pouvez pas retirer / transférer en raison du crédit sur votre compte. Veuillez contacter {email} pour obtenir de l'aide`,
    554: 'La somme demandée est égale à zéro ou est nulle',
    564: `Le pays ne correspond pas au canal de paiement`,
    565: 'Code de banque incorrect',
    566: 'Méthode de paiement introuvable',
    581: 'Vous avez bien choisi la promotion. Veuillez noter que vous devez effectuer un dépôt pour retirer des fonds.',
    582: "Vous participez à l'événement et avez des postes, vous ne pouvez donc pas soumettre de demande de retrait.",
    584: `Vous n'avez aucune bonus applicable`,
    590: 'Compte du transfert sortant introuvable',
    591: 'Compte du transfert entrant introuvable',
    593: `Nous ne sommes pas en mesure de traiter votre transfert de fonds depuis le compte sélectionné car votre compte contient du crédit.`,
    594: `Le transfert de fonds depuis ce compte n'est pas autorisé`,
    595: 'Comme vous participez à nos activités, votre demande de transfert ne peut pas être traitée pour le moment.',
    647: 'Le code de vérification est incorrect',
    session_timeout: 'Expiration de la session. Redirection vers la page de connexion.',
  },
}
