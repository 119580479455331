export default {
  CUID: 0,
  lang: 'en_US',
  loginStatus: '',
  userName: '',
  email: '',
  phone: '',
  createTime: '',
  address: '',
  cpUrl: '',
  base64AccessToken: '',
  countryCode: '',
  regulator: null,
  autoNotifyStatus: true,
  emailMasked: '',
}
