export default {
  en: 'Bahasa Inggris',
  home: {
    NewPortal: 'Portal Baru',
    SecureIBPortal: 'Portal IB Aman',
    DASHBOARD: 'DASBOR / PAPAN INSTRUMEN ',
    MARKETING_TOOLS: 'ALAT PEMASARAN',
    IB_REPORT: 'LAPORAN IB',
    REBATE_REPORT: 'LAPORAN RABAT',
    IB_ACCOUNTS: 'AKUN IB',
    PAYMENT_HISTORY: 'RIWAYAT TRANSAKSI',
    IB_FAQ: 'TANYA JAWAB IB',
    RebateAccountNumber: 'Nomor akun rabat',
    totalBalance: 'saldo total',
    totalCommission: 'komisi total',
    APPLY_FOR_A_REBATE: 'TERAPKAN',
    availableBalance: 'saldo tersedia',
    WITHDRAW_REBATE: 'PENARIKAN',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFER',
    MonthtoDate: 'Bulan sampai saat ini',
    UPDATE: 'MEMPERBARUI',
    REBATE: 'RABAT',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    NET_FUNDING: 'PENDANAAN BERSIH',
    DEPOSITS: 'DEPOSIT / SETORAN',
    WITHDRAW: 'PENARIKAN',
    OPEND_ACCOUNT: 'PEMBUKAAN AKUN',
    PERFORMANCE: 'KINERJA',
    OpendAccount: 'AKUN DIBUKA',
    select_ib: 'Silahkan pilih akun IB',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMEN YANG PALING SERING DIPERDAGANGKAN',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'AKUN DENGAN KINERJA TERATAS BULAN INI',
    RECENTLY_OPENED_ACCOUNTS: 'AKUN YANG BARU DIBUKA',
    ACCOUNT_MANAGER: 'LAYANAN PELANGGAN',
    Account: 'Akun',
    Name: 'Nama',
    Volume: 'Volume',
    Rebate: 'Rabat',
    Balance: 'Saldo',
    ContactClient: 'Hubungi Klien',
    YouCannotAskForCommission: 'Anda tidak dapat meminta komisi',
    applicationFailedPleaseTryAgain: 'Permohonan gagal, silahkan coba lagi',
    applyRebateSuccessTip:
      'Selamat, kami telah menerima permintaan rabat Anda. | {amount} akan segera ditransfer ke Akun rabat {account} . | Setelah diproses, Anda akan bisa menarik atau mentransfer dana Anda.',
    HOVER:
      'Rabat hari sebelumnya akan dihitung dan diproses antara jam 09:00-10.00 AEST setiap hari (06:00-08:00 GMT+7-Jakarta Time)',
  },
  liveChat: { header: 'Berbicara langsung ' },
  contactUs: {
    click: 'Klik',
    assist: 'untuk bantuan',
    header: 'HUBUNGI KAMI',
    followUs: 'IKUTI KAMI',
    tip:
      'Tim dukungan pelanggan multibahasa profesional {platform} siap untuk mendampingi dan membantu anda dalam menyediakan pengalaman perdagangan yang tidak tertandingi.',
  },
  ibAccounts: {
    header: 'AKUN IB',
    ibAccount: 'Akun IB',
    subIbs: 'Akun Sub IB',
    tradinglist: 'Daftar Perdagangan',
    openlist: 'Daftar Terbuka',
    closedlist: 'Daftar Tertutup',
    tradinglistColumn: {
      ticketid: 'ID Tiket',
      opentime: 'Waktu Buka',
      type: 'Jenis',
      volume: 'Volume',
      item: 'Barang',
      openprice: 'Buka Harga',
      closeprice: 'Tutup Harga',
      closetime: 'Tutup Waktu',
      commission: 'Komisi',
      profit: 'Laba',
      buy: 'Membeli',
      sell: 'Menjual',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Pilih Kartu Kredit',
      selectBA: 'Pilih Akun Bank',
      anotherCC: 'Masukan kartu kredit secara manual',
      anotherBA: 'Tambahkan akun bank',
      remember: 'Ingat akun saya',
      verificationCode:
        'Kode verifikasi telah dikirimkan ke {email}. Harap hubungi{supportEmail} bila Anda membutuhkan bantuan lebih lanjut',
    },
    alert:
      'Berdasarkan peraturan AML, metode yang anda gunakan untuk melakukan penarikan dana harus sama dengan metode yang anda gunakan untuk melakukan penyetoran. Jika jumlah penarikan anda kurang dari 100 unit dari mata uang basis akun anda, maka anda akan dikenai potongan sebesar 20 unit sebagai biaya penanganan dari bank',
    NetellerChargeAlert:
      'Penarikan Neteller dibatasi hingga $10.000 per transaksi, dan biaya 2% (dibatasi pada $30) berlaku.',
    FasaPayChargeAlert: 'FasaPay mengenakan biaya transaksi 0,5% untuk setiap penarikan.',
    SkrillChargeAlert: 'Skrill membebankan biaya transaksi 1% untuk setiap penarikan.',
    cryptoAlert:
      'Harap dicatat bahwa kami tidak dapat melakukan deposit atau penarikan melalui BUSDT, pastikan alamat dan Mata Uang Kripto Anda sesuai dengan alamat rantai dan mata uang yang kami terima atau Anda bisa kehilangan dana Anda.',
    REBATE_WITHDRAW_REQUEST: 'PERMINTAAN PENARIKAN RABAT',
    PleaseSelectDesiredWithdrawMethod: 'Silahkan pilih metode penarikan yang diinginkan',
    BankName: 'Nama Bank',
    BankAccountsName: 'Nama Akun Bank',
    FasaPayAccountName: 'Nama Akun Fasapay',
    AustralianBankName: 'Nama Bank Australia',
    BankAccountNumber: 'Nomor Akun Bank',
    FasaPayAccountNumber: 'Nomor Akun Fasapay',
    BankAccountBeneficiary: 'Akun Bank Penerima',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill ',
    SticpayEmail: 'Email SticPay',
    NetellerEmail: 'Email Neteller',
    PerfectMoneyEmail: 'Email Perfect Money',
    BitwalletEmail: 'Email Bitwallet',
    BitcoinEmail: 'Alamat Dompet Bitcoin',
    USDTEmail: 'Alamat Dompet Tether',
    BankBranch: 'Cabang Bank',
    Province: 'Provinsi',
    City: 'Kota',
    BankAddress: 'Alamat Bank',
    bankCity: 'Kota Bank',
    bankProvince: 'Provinsi Bank',
    AccountNumberIBAN: 'Nomor Akun / IBAN',
    BankBeneficiaryName: 'Nama Bank Penerima',
    BankAccountBeneficiaryName: 'Nama Akun Bank Penerima',
    AccountHoldersAddress: 'Alamat Pemilik Akun',
    SelectWithdrawCard: 'Pilih Kartu Penarikan Anda',
    Swift: 'Swift',
    ifscCode: 'Kode IFSC',
    SwiftAud: 'Swift (Akun Non AUD)',
    ABA_SortCodeABA: 'Kode ABA/Sort',
    ImportantNotes: 'Catatan Penting',
    YourPaymentGreaterThanAvailableBalance: 'Pembayaran anda melebihi saldo yang tersedia',
    CannotUseMoreThanTwoDecimalValues:
      'Anda tidak dapat menggunakan bilangan dan bilangan negatif yang melebihi dua angka desimal',
    YourPaymentIsBeingProcessed: 'Permohonan penarikan anda telah diajukan',
    Itcannotbe0: 'Tidak boleh 0',
    noCardWarn:
      'Anda tidak memiliki kartu yang tersedia untuk melakukan penarikan, silahkan tautkan kartu anda di dalam Detail Penarikan terlebih dahulu',
    amtLarger: 'Jumlah tidak boleh nol dan harus lebih besar dari atau sama dengan {minLimit} {currency}',
    blackList: `Sayangnya saat ini Anda tidak dapat mengirimkan permintaan penarikan. Harap hubungi {supportEmail} untuk informasi lebih lanjut`,
    status: {
      withdrawalSubmitted: 'Diajukan / Dikirimkan',
      withdrawalCanceled: 'Dibatalkan',
      withdrawalAuditing: 'Sedang diproses',
      withdrawalRejected: 'Ditolak',
      withdrawalAccepted: 'Sedang diproses',
      withdrawalPayFailed: 'Gagal',
      withdrawalSuccess: 'Diproses',
      withdrawalFailed: 'Gagal',
      withdrawalPending: 'Tertunda',
      withdrawalPartialFailed: 'Gagal',
      transferSubmitted: 'Sedang diproses',
      transferSuccess: 'Diproses',
      transferRejected: 'Ditolak',
      transferFailed: 'Gagal',
      transferProcessing: 'Sedang diproses',
    },
    formErrorMsg: {
      bankName: 'Nama Bank harus diisi',
      bsb: 'BSB harus diisi',
      beneName: 'Nama Bank Penerima harus diisi',
      accNum: 'Nomor Akun Bank harus diisi',
      swift: 'Swift harus diisi',
      bankAddress: 'Alamat Bank harus diisi',
      holderAddress: 'Alamat Penerima harus diisi',
      amt: 'Jumlah harus diisi',
      firstSixDigits: 'Silahkan isi 6 digit pertama',
      lastFourDigits: 'Silahkan isi 4 digit pertama',
      branchName: 'Nama cabang harus diisi',
      email: 'Email harus diisi',
      accName: 'Nama akun harus diisi',
      province: 'Provinsi Bank harus diisi',
      city: 'Kota Bank harus diisi',
      bankCard: 'Silahkan pilih kartu',
      emailFormat: 'Silahkan isikan alamat email yang tepat',
      amt0: 'Jumlah tidak boleh 0',
      ifscCode: 'Kode IFSC harus diisi',
      bitcoin: 'Alamat Dompet Bitcoin diperlukan',
      addressLength: 'Alamat wallet bitcoin harus di antara 26 dan 42 karakter',
      usdt: 'Alamat USDT Wallet diperlukan',
    },
    transfer: {
      info:
        '{platform} akan mengganti biaya bank yang dikenakan untuk <u> SATU </u> penarikan per bulan. Penarikan tambahan dalam sebulan akan dikenakan biaya bank sebesar 20 unit mata uang penarikan anda.',
      label: { upload: 'Harap unggah salinan rekening koran anda (terkini hingga 3 bulan terakhir)' },
      formValidation: { upload: 'Harap unggah rekening koran' },
    },
    transitNumber: 'Nomor Transit',
    institutionNumber: 'Nomor Institusi',
  },
  rebate: { status: { processed: 'Diproses', processing: 'Sedang diproses', rejected: 'Ditolak' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PERMOHONAN TRANSFER RABAT',
    transferAmountIsGreaterThanAvailableBalance: 'Jumlah transfer anda melebihi jumlah saldo yang tersedia',
    yourRebateTransferHasBeenProcessed: 'Transfer rabat anda sudah diajukan',
    PleaseEnterTransferInformation: 'Silahkan isi dengan informasi transfer yang tepat',
    tradingAccount: 'Akun Perdagangan',
    rebateAccount: 'Akun Rabat',
    account: 'Akun',
    blackList: 'Akun ini telah dibatasi untuk melakukan transfer antar akun, harap hubungi {supportEmail}',
  },
  common: {
    liveChat: { desc: 'Butuh Bantuan? Berbicara Sekarang' },
    lang: {
      english: 'Bahasa Inggris',
      chinese: 'Bahasa Mandarin',
      french: 'Bahasa Perancis',
      thai: 'Bahasa Thailand',
      german: 'Bahasa Jerman',
      spanish: 'Bahasa Spanyol',
      malay: 'Bahasa Melayu',
      viet: 'Bahasa Vietnam',
      indo: 'Bahasa Indonesia',
      arabic: 'Bahasa Arab',
    },
    button: { confirm: 'KONFIRMASI', cancel: 'BATAL', sendCode: 'Kirim Kode', resendCode: 'Kirim Ulang Kode' },
    withdrawChannel: {
      banktransfer: 'Transfer Bank',
      aus: 'Transfer Bank - Australia',
      int: 'Transfer Bank - Internasional',
      chn: 'Transfer Bank - China',
      skrill: 'Skrill ',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'Fasapay',
      creditcard: 'Kartu Kredit',
      bpay: 'Bpay',
      creditdebitcard: 'Kartu Kredit/Debit',
      bankwiretransferinternational: 'Wire Transfer Bank (Internasional)',
      banktransferaustralia: 'Transfer Bank (Australia)',
      banktransferinternational: 'Transfer Bank (Internasional)',
      banktransferchina: 'Transfer Bank (Internasional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transfer Antar Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Perbankan Elektronik (Thailand)',
      internetbankingnigeria: 'Perbankan Elektronik (Nigeria)',
      internetbankingvietnam: 'Perbankan Elektronik (Vietnam)',
      internetbankingmalaysia: 'Perbankan Elektronik (Malaysia)',
      internetbankingindonesia: 'Perbankan Elektronik (Indonesia)',
      internetbankingkorea: 'Perbankan Elektronik (Korea)',
      internetbankingindia: 'Perbankan Elektronik (India)',
      internetbankingphilippine: 'Perbankan Elektronik (Filipina)',
      internetbankingsouthafrica: 'Perbankan Elektronik (Afrika Selatan)',
      internetbankinguganda: 'Perbankan Elektronik (Uganda)',
      internetbankingrwanda: 'Perbankan Elektronik (Rwanda)',
      internetbankingzambia: 'Perbankan Elektronik (Zambia)',
      internetbankingcongo: 'Perbankan Elektronik (Kongo)',
      internetbankingcameroon: 'Perbankan Elektronik (Kamerun)',
      internetbankingburundi: 'Perbankan Elektronik (Burundi)',
      internetbankingkenya: 'Perbankan Elektronik (Kenya)',
      internetbankingghana: 'Perbankan Elektronik (Ghana)',
      internetbankingtanzania: 'Perbankan Elektronik (Tanzania)',
      internetbankingcanada: 'Perbankan Elektronik (Kanada)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transfer Bank Vietnam',
      nigeriabanktransfer: 'Transfer Bank Nigeria',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Wire Transfer Bank (Australia)',
      thailandinstantbankwiretransfer: 'Wire Transfer Bank Thailand Instan',
      malaysiainstantbankwiretransfer: 'Transfer Bank Malaysia Instan',
      banktransferbpaypolipay: 'Transfer Bank/Bpay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      nigeriainstantbankwiretransfer: 'Wire Transfer Bank Nigeria Instan',
      vietnaminstantbankwiretransfer: 'Wire Transfer Bank Vietnam Instan',
      indonesiainstantbankwiretransfer: 'Transfer Bank Indonesia Instan',
      philippinesinstantbankwiretransfer: 'Transfer Bank Instan Filipina',
      southafricainstantbankwiretransfer: 'Wire Transfer Bank Instan Afrika Selatan',
      indiainstantbanktransfer: 'Wire Transfer Bank Instan India',
      southkoreabanktransfer: 'Transfer Bank Lokal Korea Selatan',
      ugandabanktransfer: 'Transfer Bank Lokal Uganda',
      rwandabanktransfer: 'Transfer Bank Lokal Rwanda',
      zambiabanktransfer: 'Transfer Bank Lokal Zambia',
      congobanktransfer: 'Transfer Bank Lokal Kongo',
      cameroonbanktransfer: 'Transfer Bank Lokal Kamerun',
      burundibanktransfer: 'Transfer Bank Lokal Burundi',
      kenyabanktransfer: 'Transfer Bank Lokal Kenya',
      ghanabanktransfer: 'Transfer Bank Lokal Ghana',
      tanzaniabanktransfer: 'Transfer Bank Lokal Tanzania',
      banktransferbpaypolipayaustralia: 'Transfer Bank /Bpay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transfer Bank/Bpay/PoliPay (Internasional)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Transfer Bank Lokal Kanada',
      cryptobitcoin: 'Cyrpto-Bitcoin',
      cryptotetheromni: 'Crypto-Tether(OMNI)',
      cryptotethererc20: 'Cyrpto-Tether(ERC20)',
      astropay: 'Astropay',
      vload: 'Vload',
      sticpay: 'SticPay',
      transfer: 'Transfer ke akun',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'MATA UANG KRIPTO',
      LOCAL_TRANSFER: 'TRANSFER LOKAL',
    },
    country: { international: 'Internasional', australia: 'Australia' },
    field: {
      country_region: 'Negara/Wilayah',
      address: 'Alamat',
      phone: 'Nomor Telepon',
      nat: 'Kewarganegaraan',
      country: 'Negara tempat tinggal',
      save: 'Investasi',
      annIncome: 'Pendapatan Tahunan',
      emailAdd: 'Alamat email',
      yes: 'Ya',
      no: 'Tidak',
      enterVerificationCode: 'Masukkan Kode Verifikasi',
      infoDetails: '',
      noBind: '',
      noDataNow: '',
      confirmClose: '',
      ibAccount: '',
    },
    keys: {
      SUBMIT: 'KIRIMKAN',
      FROM: 'DARI',
      UPDATE: 'MEMPERBARUI',
      TO: 'KE',
      DATE: 'TANGGAL',
      pleaseSelect: 'Silahkan Pilih',
      amount: 'Jumlah',
      rebateAccount: 'Akun Rabat',
      comingSoon: 'Segera hadir',
      tradingHistory: 'RIWAYAT PERDAGANGAN',
      noData: 'Data tidak tersedia',
      NAME: 'NAMA',
      EMAIL: 'EMAIL',
      ACCTYPE: 'TIPE AKUN',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASIS MATA UANG',
      DATERANGE: 'RENTANG TANGGAL',
      STATUS: 'STATUS',
      DEST: 'TUJUAN',
      ACCNUM: 'NOMOR AKUN',
      BALANCE: 'SALDO',
      PROFITLOST: 'LABA',
      MARGINLVL: 'TINGKAT MARJIN',
      ACCEQUITY: 'EKUITAS AKUN',
      CREDIT: 'KREDIT',
      NONE: 'Tidak ada',
      ALL: 'Semua',
      actualAmonut: 'Jumlah Aktual',
      currency: 'MATA UANG',
      method: 'METODE',
    },
    dateRange: {
      today: 'Hari ini',
      yesterday: 'Kemarin',
      lastSevenDays: '7 hari terakhir',
      lastThirtyDays: '30 hari terakhir',
      weekToDate: 'Minggu sampai saat ini',
      monthToDate: 'Bulan Sampai Saat Ini',
      quarterToDate: 'Kuartal sampai saat ini',
      previousWeek: 'Minggu lalu',
      previousMonth: 'Bulan lalu',
      previousQuarter: 'Kuartal lalu',
    },
    products: { forex: 'FOREX', commodities: 'KOMODITAS', indices: 'INDEKS', crypto: 'KRIPTO' },
    formValidation: {
      dynamicReq: '{dynamic} diperlukan',
      verCodeReq: 'Dibutuhkan kode verifikasi',
      alphanumericReq: 'Silaihkan isi dengan alfanumerik',
    },
  },
  report: {
    title: { rebateReport: 'LAPORAN RABAT' },
    rebate: {
      column: {
        name: 'NAMA',
        accountNumber: 'NOMOR AKUN',
        accountType: 'TIPE AKUN',
        volume: 'Volume',
        rebate: 'RABAT',
        totalRebate: 'RABAT TOTAL',
        date: 'TANGGAL',
        instrument: 'INSTRUMEN',
      },
      dropdown: { allInstruments: 'Semua Instrumen', all: 'Semua', instruments: 'Instrumen' },
      others: {
        rebate: 'RABAT',
        instrumentsTraded: 'INSTRUMEN YANG DIPERDAGANGKAN',
        lots: 'Lot',
        totalRebate: 'TOTAL RABAT',
        rebates: 'Rabat',
      },
    },
  },
  ibReport: {
    header: 'LAPORAN IB',
    netFunding: 'PENDANAAN BERSIH',
    deposits: 'DEPOSIT / SETORAN',
    withdraw: 'PENARIKAN',
    openedAccs: 'AKUN DIBUKA',
    funding: 'PENDANAAN',
    withdrawals: 'PENARIKAN',
    appliedAmount: 'jumlah yang diterapkan',
    depositAmount: 'Jumlah Deposit',
    withdrawalAmount: 'Jumlah penarikan',
  },
  lead: { header: 'LEAD', ibAccount: 'Akun IB', demoAccounts: 'AKUN DEMO' },
  pendingAccounts: {
    header: 'AKUN DITUNDA',
    errorDoc:
      'Pemohon berikut telah mengisi formulir aplikasi online namun belum memberikan bukti identitas (ID) untuk melengkapi akun mereka.',
    acceptedDocType: 'Bentuk tanda pengenal yang bisa diterima meliputi:',
    primaryDoc: 'Tanda Pengenal Utama',
    passport: 'Salinan paspor internasional yang masih berlaku (halaman bertanda tangan)',
    id: 'Salinan Kartu Tanda Penduduk (KTP) yang dikeluarkan oleh pemerintah',
    dl: 'Salinan Surat Izin Mengemudi (SIM) yang masih berlaku',
    address: 'Tanda Pengenal Tambahan',
    addressList: [
      'Salinan dari tagihan rumah tangga terkini (tidak boleh lebih dari 6 bulan)',
      'Salinan dari tagihan kartu kredit (tidak boleh lebih dari 6 bulan)',
      'Salinan dari rekening koran bank ( tidak boleh lebih dari 6 bulan)',
    ],
    tip:
      'Dimohon untuk mengingatkan pemohon untuk menyediakan salinan dari SALAH SATU dokumen tanda pengenal utama yang diterima dan tanda pengenal tambahan ke',
    addMaterial: '',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'RIWAYAT TRANSAKSI',
    rebateHistory: 'Rabat',
    withdrawHistory: 'PENARIKAN',
    transferHistory: 'TRANSFER',
    cancelWithdraw: `Apakah Anda ingin Membatalkan Permintaan Penarikan?`,
    cancelError: `Penarikan Anda sedang diproses saat ini. Oleh karena itu, kami tidak dapat menerima permintaan pembatalan Anda. Untuk informasi lebih lanjut, Anda dapat menghubungi kami menggunakan fitur obrolan langsung.`,
  },
  fileUploader: {
    supportedFormat: 'Jenis file yang didukung: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Ukuran file unggahan maksimum: {maxSize} MB',
    maxFileNum: 'Nomor file maksimum: {maxFileNum}',
    selectFile: 'Pilih File',
  },
  transferHistory: {
    payStatus: { processing: 'Sedang diproses', paid: 'Terbayar', unpaid: 'Belum terbayar', rejected: 'Ditolak' },
  },
  referralLinks: {
    header: 'TAUTAN REFERAL',
    ibAcc: 'Akun IB',
    campLang: 'Bahasa Kampanye',
    cpy: 'SALIN',
    alreadyCPY: 'TERSALIN!',
    liveAcc: 'Akun Live',
    demoAcc: 'Akun Demo',
    homePage: 'Beranda',
    Share: 'Bagikan',
  },
  settings: { ibProfile: 'PROFIL IB', info: 'INFO', reg: 'TERDAFTAR PADA' },
  myProfile: {
    changeDetails:
      'Jika anda menginginkan perubahan pada detail profil yang ada, jangan ragu untuk menghubungi {mailTo}',
  },
  unfundedAcc: {
    header: 'AKUN BELUM DIDANAI',
    allActivationAccs: 'SEMUA AKUN AKTIVASI',
    neverFundedAccs: 'TIDAK PERNAH DIDANAI',
    prevFundedZero: 'SALDO NOL',
  },
  multiib: { level: 'LEVEL {level}', noib: 'Tidak ada sub-IB di bawah akun ini' },
  header: { bckToCP: 'KEMBALI KE PORTAL KLIEN', logout: 'KELUAR' },
  menu: {
    dashboard: 'DASBOR / PAPAN INSTRUMEN ',
    ibReport: 'LAPORAN IB',
    rebateReport: 'LAPORAN RABAT',
    accManagement: 'AKUN',
    iblevel: 'MULTI-LEVEL',
    ibAccs: 'AKUN DIAKTIFKAN',
    leads: 'LEAD',
    pendingAccs: 'AKUN DITUNDA',
    unfundedAccs: 'AKUN BELUM DIDANAI',
    payHistory: 'RIWAYAT TRANSAKSI',
    ibProfile: 'PROFIL IB',
    refLinks: 'TAUTAN REFERAL',
    contactUs: 'HUBUNGI KAMI',
    switchCP: 'GANTI KE PORTAL KLIEN',
    rebateTransfer: '',
    rebateWithdraw: '',
  },
  footer: {
    riskWarn:
      'Peringatan Risiko: Perdagangan Contracts for Difference (CFD) melibatkan risiko tinggi terhadap modal anda, dan dapat berakhir dengan kerugian, anda sebaiknya hanya berdagang dengan menggunakan uang yang anda siap untuk hilang. Perdagangan CFD mungkin tidak cocok untuk semua investor, mohon pastikan bahwa anda memahami sepenuhnya risiko yang ada dan melakukan persiapan untuk mengelolanya. Mohon membaca dokumen Pengungkapan Risiko terkait dengan cermat.',
  },
  responseMsg: {
    410: 'Validasi parameter gagal',
    411: 'Kata kunci Salah',
    420: 'Pengguna tidak ditemukan',
    421: 'Setel Ulang Kata Sandi ditolak',
    490: 'Harap jangan mengirimkan informasi kartu secara berganda',
    500: 'Terjadi kesalahan.',
    501: 'Permohonan gagal. Silakan coba lagi nanti.',
    505: 'Gagal mengunggah berkas',
    520: 'Gagal masuk. Silahkan coba lagi.',
    521: 'Seorang pengguna sudah masuk di perangkat ini!',
    522: 'Silakan login lagi',
    523: 'Silakan login lagi',
    524: 'Mohon maaf, alamat email anda tidak dikenali',
    525: 'Username atau password salah',
    527: 'Pengguna bukan seorang IB',
    528: 'Pengguna tidak ditemukan',
    529: 'Username atau password salah',
    540: 'Akun trading anda tidak dapat ditemukan',
    541: 'Akun Rabat tidak dapat ditemukan',
    542: 'Anda tidak diizinkan untuk membuka akun tambahan',
    544: 'Anda tidak diperbolehkan mendaftarkan akun tambahan hingga Bukti Identitas Anda diverifikasi',
    550: 'Nomor rekening salah',
    551: 'Saldo anda tidak mencukupi',
    553: 'anda tidak dapat menarik / mentransfer karena kredit di akun anda. Harap hubungi {email} untuk bantuan lebih lanjut',
    554: 'Jumlah yang diminta nol atau null',
    562: 'Kami tidak dapat memproses pembayaran anda, coba lagi. Jika tetap terjadi masalah, silakan hubungi kami melalui obrolan langsung, atau email {email}',
    564: 'Negara tidak sesuai dengan portal pembayaran',
    565: 'Kode Bank Salah',
    566: 'Metode pembayaran tidak ditemukan',
    581: 'anda telah berhasil mengikuti promosi. Harap dicatat bahwa anda harus melakukan deposit untuk menarik dana.',
    582: 'Karena Anda telah mengikuti program promosi dan memiliki posisi terbuka, kami tidak dapat memproses permintaan penarikan Anda',
    584: 'Anda tidak memiliki rabat yang berlaku',
    590: 'Akun transfer keluar tidak dapat ditemukan',
    591: 'Akun transfer masuk tidak dapat ditemukan',
    593: 'Kami tidak dapat memproses transfer dana Anda dari akun yang dipilih karena akun Anda mengandung kredit.',
    594: 'Transfer dana dari akun ini tidak diizinkan',
    595: 'Karena Anda telah mengikuti program promosi kami, kami tidak dapat memproses permintaan transfer Anda',
    647: 'Kode verifikasi tidak benar',
    session_timeout: 'Sesi berakhir, mengarahkan ke laman login',
  },
}
